import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table } from 'antd';
import { useAtom } from 'jotai';
import type { AlignType } from 'rc-table/es/interface';
import { useEffect } from 'react';

import { deleteCampaignAtom, fetchCampaignsOfferAtom } from '@/atoms/campaigns';
import { ICampaign, IRouteParams } from '@/types';
import { columnSearch, commonFields, enableSorting, SortOrder } from '@/utils/table';

import Loading from '../../Loading';

const OfferCampaign = ({ id, offer }: IRouteParams) => {
  const [, deleteCampaign] = useAtom(deleteCampaignAtom);
  const [campaigns, fetchCampaignsOffer] = useAtom(fetchCampaignsOfferAtom);

  useEffect(() => {
    fetchCampaignsOffer(id);
  }, [fetchCampaignsOffer, id, offer]);

  const columns = [
    {
      title: 'Campaign ID',
      ...commonFields('campaign_id'),
      ...enableSorting('campaign_id', SortOrder.DESCEND),
      ...columnSearch('campaign_id'),
    },
    {
      title: 'Campaign name',
      ...commonFields('name'),
      ...columnSearch('name'),
    },
    {
      title: 'Country',
      ...commonFields('country'),
      ...columnSearch('country'),
    },
    {
      title: 'Payment Method',
      ...commonFields('payment_method'),
      ...columnSearch('payment_method'),
    },
    {
      title: 'Shipping Id',
      ...commonFields('shipping_id'),
      ...columnSearch('shipping_id'),
    },
    {
      title: 'Shipping Price',
      ...commonFields('shipping_name'),
      ...columnSearch('shipping_name'),
    },
    {
      title: 'Scrub',
      ...commonFields('is_scrub'),
      ...columnSearch('is_scrub'),
      render: (s: any) => (s ? 'YES' : 'NO'),
    },
    {
      title: 'Action',
      ...commonFields('action'),
      align: 'center' as AlignType,
      render: (_: any, campaign: ICampaign) => (
        <Popconfirm
          title="Are you sure you wont to remove campaign?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => deleteCampaign(campaign.ID)}
        >
          <Button shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  return campaigns.isLoading ? (
    <Loading />
  ) : (
    <Table style={{ width: '100%' }} dataSource={campaigns.data} rowKey="ID" columns={columns} pagination={false} />
  );
};

export default OfferCampaign;
