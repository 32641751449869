import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { useEffect } from 'react';

import { countries } from '@/constants';

import { IFormProps } from '../../types';
import { IBlockers } from '../../types';

export const BlockUserEditForm = ({ formData, onFormReady }: IFormProps<IBlockers>) => {
  const [form] = Form.useForm();
  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <Row gutter={24}>
        <Col span={9}>
          <Form.Item name="user_ip" label="User IP Address : ">
            <Input placeholder="xxx.xxx.xxx.xxx" pattern="^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name="user_country" label={'Users Country'}>
            <Select showSearch placeholder="Select Country">
              {countries.map(({ name, code }) => (
                <Select.Option key={name} value={code}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="order_decline_limit" label={'Decline Limit'}>
            <InputNumber placeholder="Order Decline Limit" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item name="user_email" label={'Users Email'}>
            <Input placeholder="User Email Address " />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="user_phone" label={'Users Phone Number'}>
            <Input addonBefore="+" placeholder="User Phone Number " />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
