import {
  ApiOutlined,
  DollarCircleOutlined,
  FundProjectionScreenOutlined,
  FunnelPlotOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  PieChartOutlined,
  SketchOutlined,
} from '@ant-design/icons';
import { DownOutlined, DropboxOutlined, ProfileOutlined, ProjectOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Col, Dropdown, Layout, Menu, MenuProps, Row, Space } from 'antd';
import { useAtomValue } from 'jotai';
import { MenuClickEventHandler, MenuInfo } from 'rc-menu/lib/interface';
import { Suspense, useState } from 'react';
import { Routes, useLocation, useNavigate } from 'react-router-dom';
import { Route } from 'react-router-dom';

import HydraLogo from '@/assets/hydra-logo.svg';
import { errorAtom } from '@/atoms/error';
import FormModal from '@/components/common/FormModal';
import ProgressModal from '@/components/common/ProgressModal';
import { ROUTES } from '@/routes';

import Loading from '../Loading';
import { ErrorComponent } from '../UI/ErrorComponent';

const { Header, Footer, Sider } = Layout;

const MainLayout = () => {
  const error = useAtomValue(errorAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const { user, logout }: any = useAuth0();

  const onMenuClick: MenuClickEventHandler = (e: MenuInfo) => {
    if (!e.key) return;
    switch (e.key) {
      case 'logout':
        logout({
          returnTo: window.location.origin,
        });
        break;
      default:
        navigate(e.key);
        break;
    }
  };

  const userMenuItems: MenuProps['items'] = [
    {
      label: 'Profile',
      key: 'profile',
      onClick: () => navigate('profile'),
    },
    {
      label: 'Status',
      key: 'status',
      onClick: () => navigate('status'),
    },
    {
      label: 'Logout',
      key: 'logout',
      onClick: () => {
        logout({
          returnTo: window.location.origin,
        });
      },
    },
  ];

  const matchKeys = location.pathname
    .replace(/^\/|\/$/g, '')
    .split('/')
    .map((key: string, index: number, array: string[]) => {
      return ['', ...array.slice(0, index), key].join('/');
    });

  var menuItems = [
    {
      label: 'Dashboard',
      key: '/',
      icon: <PieChartOutlined />,
    },
    {
      label: 'Funnels',
      key: '/funnels',
      icon: <FunnelPlotOutlined />,
    },
    {
      label: 'Domains',
      key: '/domains',
      icon: <GlobalOutlined />,
    },
    {
      label: 'Templates',
      key: '/templates',
      icon: <ProjectOutlined />,
    },
    {
      label: 'Categories',
      key: '/categories',
      icon: <ProfileOutlined />,
    },
    {
      label: 'Offers',
      key: '/offers',
      icon: <DollarCircleOutlined />,
    },
    {
      label: 'Orders',
      key: '/orders',
      icon: <FundProjectionScreenOutlined />,
    },
    {
      label: 'Shipping',
      key: '/shipping',
      icon: <DropboxOutlined />,
    },
    {
      label: 'Status',
      key: '/status',
      icon: <InfoCircleOutlined />,
    },
    {
      label: 'Users',
      key: '/users',
      icon: <UserOutlined />,
    },
  ];
  // Logic for user max@nueralabs to see only Sticky component
  if (user?.email === 'max@nueralabs.com') {
    menuItems = [
      {
        label: 'Sticky',
        key: '/sticky',
        icon: <SketchOutlined />,
      },
    ];
  }

  return (
    <Layout style={{ height: '100vh' }}>
      {!location.pathname.match('/template-editor/') && (
        <Sider collapsible collapsed={collapsed} onCollapse={(collapsed) => setCollapsed(collapsed)}>
          <div title="Hydra CMS" style={{ margin: '16px', textAlign: 'center' }}>
            <img src={HydraLogo} alt="logo" style={{ maxWidth: '100%' }} />
          </div>
          <Menu theme="dark" items={menuItems} onClick={onMenuClick} selectedKeys={matchKeys} mode="inline" />
        </Sider>
      )}
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0, paddingRight: 30 }}>
          <Row justify="end" style={{ height: '100%' }}>
            <Col>
              <Dropdown className="user_dropdown" trigger={['click']} menu={{ items: userMenuItems }}>
                <Space>
                  {user?.name} <Avatar size="small" icon={<UserOutlined />} />
                  <DownOutlined />
                </Space>
              </Dropdown>
            </Col>
          </Row>
        </Header>
        {!!error && <ErrorComponent />}
        <Routes>
          {ROUTES.map((route) => (
            <Route
              path={route.path}
              element={
                <Suspense fallback={<Loading />}>
                  <route.component />
                </Suspense>
              }
              key={route.path}
            />
          ))}
        </Routes>
        <Footer style={{ textAlign: 'center', padding: '12px', height: '48px' }}>
          Hydra CMS © 2022 - {new Date().getFullYear()}{' '}
          <a href="/api/v1/docs" style={{ position: 'absolute', right: '1rem' }}>
            <ApiOutlined /> API Docs
          </a>
        </Footer>
      </Layout>
      <FormModal />
      <ProgressModal />
    </Layout>
  );
};

export default MainLayout;
