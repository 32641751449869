import { atom } from 'jotai';

import { ITemplate } from '../types';
import { $http } from '../utils/http';
import { progressAtom } from './progress';

const dataAtom = atom<ITemplate[]>([]);
const layout = atom<any[]>([]);
const isLoading = atom<boolean>(false);
const isSyncing = atom<boolean>(false);


export interface TemplatesState {
  data: ITemplate[];
  layout: any[];
  isLoading: boolean;
  isSyncing: boolean;
}

export const templatesAtom = atom<TemplatesState>((get) => ({
  data: get(dataAtom),
  layout: get(layout),
  isLoading: get(isLoading),
  isSyncing: get(isSyncing),
}));

export const fetchTemplatesAtom = atom(
  (get) => get(templatesAtom),
  async (_, set) => {
    set(isLoading, true);
    $http
      .get('/api/v1/templates')
      .json<ITemplate[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const fetchTemplateLayoutsAtom = atom(
  (get) => get(templatesAtom),
  async (_, set) => {
    set(isLoading, true);
    $http
      .get('/api/v1/templates/layouts')
      .json<any[]>()
      .then((response) => set(layout, response))
      .finally(() => set(isLoading, false));
  },
);

export const createTemplateAtom = atom(null, async (get, set, body: ITemplate) => {
  set(isLoading, true);
  $http
    .post('/api/v1/templates', { body })
    .json<ITemplate>()
    .then((response) => set(dataAtom, [...get(dataAtom), response]))
    .finally(() => set(isLoading, false));
});

export const updateTemplateAtom = atom(null, async (get, set, id: number, body: Partial<ITemplate>) => {
  const templates = get(dataAtom);
  set(isLoading, true);
  $http
    .put(`/api/v1/templates/${id}`, { body })
    .json<ITemplate>()
    .then((template) => set(dataAtom, templates.updateValueBy('ID', template)))
    .finally(() => set(isLoading, false));
});

export const deleteTemplateAtom = atom(null, async (get, set, id: number) => {
  set(isLoading, true);
  $http
    .delete(`/api/v1/templates/${id}`)
    .json<ITemplate>()
    .then(() =>
      set(
        dataAtom,
        get(dataAtom).filter(({ ID }) => ID !== id),
      ),
    )
    .finally(() => set(isLoading, false));
});

export const syncTemplatesAtom = atom(null, async (_, set) => {
  set(isSyncing, true);
  $http
    .patch('/api/v1/templates')
    .jsonStream<{ progress: number; message: string }>((data) => {
      set(progressAtom, {
        percent: data.progress,
        message: data.message,
      });
    })
    .finally(() => set(isSyncing, false));
});
