import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Image, Modal } from 'antd';
import { useAtom, useSetAtom } from 'jotai';
import { useState } from 'react';

import { updateFunnelAtom } from '../../../../atoms/funnels';
import { funnel } from '../../../../atoms/quickBuild';
import { IFunnel } from '../../../../types';
import { ManualAddForm } from './ManualAddForm';
import { ManualEditForm } from './ManualEditForm';

enum ActionType {
  Add,
  Delete,
  Edit,
}
interface PendingAction {
  type: ActionType;
  item: any;
}

const EditManual = ({ formData }: any) => {
  const updateFunnel = useSetAtom(updateFunnelAtom);
  const [funnelAtom, setFunnelAtom] = useAtom(funnel);
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);
  let savedManual = !!formData && !!formData.details && !!formData.details.manual ? formData.details.manual : [];
  let quickSaved = !!funnelAtom && !!funnelAtom.details && !!funnelAtom.details.manual ? funnelAtom.details.manual : [];

  const onAdd = (incommingManual: any) => {
    if (incommingManual.manual_img) {
      if (formData) {
        updateFunnel(formData.ID, { details: { manual: [...savedManual, incommingManual] }, ID: formData.ID });
      } else {
        let buildedDetails = !!funnelAtom?.details ? funnelAtom.details : {};
        let datasend = { details: { ...buildedDetails, manual: [...quickSaved, incommingManual] } };
        setFunnelAtom({ ...funnelAtom, ...datasend } as IFunnel);
      }

      setPendingAction(null);
    }
  };

  const onEdit = (item: any) => {
    if (item) {
      let newList = !formData ? [...quickSaved] : [...savedManual];
      const index = newList.findIndex((v: any) => v.manual_id === item.manual_id);
      newList[index] = item;
      if (formData) {
        updateFunnel(formData.ID, { details: { manual: newList }, ID: formData.ID });
      } else {
        let buildedDetails = !!funnelAtom?.details ? funnelAtom.details : {};
        let datasend = { details: { ...buildedDetails, manual: newList } };
        setFunnelAtom({ ...funnelAtom, ...datasend } as IFunnel);
      }
    }
    setPendingAction(null);
  };

  const onDelete = (itemDeleted: any) => {
    if (itemDeleted) {
      if (formData) {
        updateFunnel(formData.ID, {
          details: { manual: savedManual.filter((v) => v.manual_id !== itemDeleted.manual_id) },
          ID: formData.ID,
        });
      } else {
        let buildedDetails = !!funnelAtom?.details ? funnelAtom.details : {};
        let datasend = {
          details: { ...buildedDetails, manual: quickSaved.filter((v) => v.manual_id !== itemDeleted.manual_id) },
        };
        setFunnelAtom({ ...funnelAtom, ...datasend } as IFunnel);
      }
    }
    setPendingAction(null);
  };

  let items = !!formData ? savedManual : quickSaved;

  return (
    <div>
      <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 18 }}>Added Manuals</p>
      <ManualAddForm
        onAdd={(item: any) => onAdd(item)}
        onCancel={() => setPendingAction(null)}
        funnelId={!!formData && !!formData.ID ? formData.ID : !!funnelAtom && funnelAtom.ID}
      />
      {items.length ? (
        items.map((item: any, index: any) => {
          return (
            <Card className="card-faqs" size="small" key={String(index)}>
              {!!pendingAction?.item && pendingAction?.item.manual_id === item.manual_id ? (
                <ManualEditForm
                  onEdit={(item: any) => onEdit(item)}
                  item={pendingAction?.item}
                  onCancel={() => setPendingAction(null)}
                  funnelId={!!formData && !!formData.ID ? formData.ID : !!funnelAtom && funnelAtom.ID}
                />
              ) : (
                <div style={{ overflow: 'hidden', width: '50%' }}>
                  <Image src={item.manual_img} style={{ width: '200px' }} />
                </div>
              )}
              <div>
                <Button
                  type="primary"
                  className="removeBTN"
                  danger
                  shape="circle"
                  onClick={() => {
                    Modal.confirm({
                      title: `Delete manual`,
                      content: 'Are you sure you want to delete this manual?',
                      onOk: () => onDelete(item),
                    });
                  }}
                >
                  <DeleteOutlined />
                </Button>
                <Button
                  type="primary"
                  className="editBTN"
                  shape="circle"
                  onClick={() => setPendingAction({ type: ActionType.Edit, item })}
                >
                  <EditOutlined />
                </Button>
              </div>
            </Card>
          );
        })
      ) : (
        <p style={{ textAlign: 'center', color: 'gray' }}>There is no manual</p>
      )}
    </div>
  );
};

export default EditManual;
