import { CheckCircleTwoTone, ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { message,Modal, Tooltip, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { deleteImageAtom, fetchImagesAtom, uploadCompleteAtom } from '../../atoms/images';
import { $http } from '../../utils/http';

export const ImageGallery = ({ value, funnelId, disabled, onSelected }: any) => {
  const [images, fetchImages] = useAtom(fetchImagesAtom);
  const [, uploadComplete] = useAtom(uploadCompleteAtom);
  const [, deleteImage] = useAtom(deleteImageAtom);
  const [uploading, setUploading] = useState<UploadFile | null>(null);
  const [preview, setPreview] = useState<UploadFile | null>(null);
  const [selected, setSelected] = useState(value);
  const { confirm } = Modal;
  
  useEffect(() => {
    fetchImages( funnelId );
  }, [funnelId, fetchImages]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  function handleUrlSchema(url: string) {
    const isUrl = new RegExp(/^https?:\/\//i);
    if (url.match(isUrl)) return url;
    return !!import.meta.env.VITE_PROXY ? `${import.meta.env.VITE_PROXY}${url}` : url;
  }

  const handlePreview = async (file: UploadFile) => {
    if (!file.url) return;
    setPreview(file);
  };

  const handleSelectImage = (file: UploadFile) => {
    if (!onSelected) return;
    setSelected(file.url || '');
    onSelected(file.url || '');
  };

  const getImages = (): UploadFile[] => {
    return uploading?.status === 'uploading' ? [...(images.data ?? []),uploading] : images.data;
  };

  const filePreview = () => {
    const type = preview?.type?.split('/')[0];
    switch (type) {
      case 'application':
        return (
          <object data={preview?.url} type={preview?.type} width="100%" height="300px" aria-label="pdf viewer"></object>
        );
      case 'video':
        return (
          <video controls width="100%">
            <source src={preview?.url} type={preview?.type} />
          </video>
        );
    }
    return <img alt="preview_image" style={{ width: '100%' }} src={preview?.url} />;
  };

  const props: UploadProps = {
    action: handleUrlSchema(`/api/v1/images/${funnelId}`),
    headers: { Authorization: `Bearer ${$http.getToken()}` },
    listType: 'picture-card',
    fileList: getImages(),
    accept: '.jpg,.jpeg,.png,.svg,.webp,.gif,.pdf,.mp4,.webm',
    multiple: true,
    disabled,
    itemRender: (originNode, file) => (
      <Tooltip title={file.name}>
        <div
          className="ant-upload-list-item ant-upload-list-item-list-type-picture-card"
          key={file.url}
          onClick={() => handleSelectImage(file)}
        >
          {originNode.props.children}
          {selected === file.url && (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{
                fontSize: '20px',
                position: 'absolute',
                zIndex: 1,
                left: '5px',
                top: '5px',
                pointerEvents: 'none',
              }}
            />
          )}
        </div>
      </Tooltip>
    ),
    beforeUpload: (file: any) => {
      if (file.size / 1024 / 1024 > 10) {
        message.error('Image must be smaller than 10MB!');
        return false;
      }
    },
    onRemove: (file) => {
      confirm({
        title: 'Are you sure you want to delete this image?',
        icon: <ExclamationCircleOutlined />,
        content: 'Before deleting image, please confirm if it is not used in page.',
        onOk() {
          deleteImage(funnelId, file.name );
        },
      });
    },
    onPreview: handlePreview,
    onChange: ({ file }) => {
      setUploading(file);
      if (file.status === 'done') {
        uploadComplete(file.response);
        message.success(`${file.name} file uploaded successfully.`);
      } else if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <Upload {...props}>
        <div>
          <UploadOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      </Upload>
      <Modal
        open={!!preview}
        title={preview?.name}
        footer={'Image file size: ' + preview?.size}
        onCancel={() => setPreview(null)}
      >
        {filePreview()}
      </Modal>
    </>
  );
};
