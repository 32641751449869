import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { useAtom, useSetAtom } from 'jotai';
import { Fragment, useEffect } from 'react';

import { deleteBlockerAtom, fetchBlockersAtom, updateBlockerAtom } from '@/atoms/blockers';
import { openModalAtom } from '@/atoms/modal';
import { IBlockers, IRouteParams } from '@/types';
import { columnSearch, commonFields, enableSorting } from '@/utils/table';

import { BlockUserEditForm } from '../../Forms';
import Loading from '../../Loading';

const Blockers = ({ id, funnel }: IRouteParams) => {
  const openModal = useSetAtom(openModalAtom);
  const updateBlocker = useSetAtom(updateBlockerAtom);
  const deleteBlocker = useSetAtom(deleteBlockerAtom);
  const [blockers, fetchBlockers] = useAtom(fetchBlockersAtom);

  useEffect(() => {
    fetchBlockers(id);
  }, [fetchBlockers, id]);

  const columns = [
    {
      title: 'ID',
      ...commonFields('ID'),
      ...columnSearch('ID'),
      ...enableSorting('ID'),
      width: '5%',
    },
    {
      title: 'Users IP Address',
      ...commonFields('user_ip'),
      ...columnSearch('user_ip'),
      ...enableSorting('user_ip'),
    },
    {
      title: 'User Country',
      ...commonFields('user_country'),
      ...commonFields('user_country'),
      ...columnSearch('user_country'),
      ...enableSorting('user_country'),
    },
    {
      title: 'User Email Address',
      ...commonFields('user_email'),
      ...commonFields('user_email'),
      ...columnSearch('user_email'),
      ...enableSorting('user_email'),
    },
    {
      title: 'User Phone Number',
      ...commonFields('user_phone'),
      ...commonFields('user_phone'),
      ...columnSearch('user_phone'),
      ...enableSorting('user_phone'),
    },
    {
      title: 'Order Decline limitation',
      ...commonFields('order_decline_limit'),
      ...commonFields('order_decline_limit'),
      ...columnSearch('order_decline_limit'),
      ...enableSorting('order_decline_limit'),
    },
    {
      title: 'Updated At',
      ...commonFields('UpdatedAt'),
      ...commonFields('UpdatedAt'),
      ...columnSearch('UpdatedAt'),
      ...enableSorting('UpdatedAt'),
    },
    {
      title: 'Action',
      ...commonFields('action'),
      render: (_: any, blockUser: IBlockers) => (
        <div key={blockUser.ID}>
          <Tooltip placement="topLeft" title="Block User Edit">
            <Button
              shape="circle"
              icon={<EditFilled />}
              disabled={funnel?.locked}
              onClick={() => {
                openModal({
                  component: BlockUserEditForm,
                  data: blockUser,
                  funnelId: blockUser.funnel_id,
                  onSave: (values: IBlockers) =>
                    updateBlocker(blockUser.ID, {
                      ...values,
                      ID: blockUser.ID,
                      funnel_id: funnel.ID,
                    }),
                });
              }}
              key={blockUser.ID}
            />
          </Tooltip>
          <Popconfirm
            title="Are you sure you wont to remove?"
            okText="Yes"
            cancelText="No"
            onConfirm={(e: any) => deleteBlocker(blockUser.ID)}
          >
            <Button shape="circle" icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
    },
  ];
  return blockers.isLoading ? (
    <Loading />
  ) : (
    <Fragment>
      <Table style={{ width: '100%' }} dataSource={blockers.data} rowKey="ID" columns={columns} pagination={false} />
    </Fragment>
  );
};
export default Blockers;
