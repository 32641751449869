import { Button, Form, Modal } from 'antd';
import { useAtom } from 'jotai';

import { updateMetaAtom } from '../../../../../atoms/pages';
import { ImagePicker } from '../../../../common/ImagePicker';
import { DragAndDrop } from '../../../../UI/DragAndDrop';

export const ListAddForm = ({ active, onAdd, onCancel, pageData, onOk }: any) => {
  const [form] = Form.useForm();
  const [, updateMeta] = useAtom(updateMetaAtom);

  const randstr = (prefix: any) => {
    return Math.random()
      .toString(36)
      .replace('0.', prefix || '');
  };

  const onFinish = (e: any) => {
    onAdd({
      id: randstr('For_Now:'),
      boldText: e.boldText ? e.boldText : '',
      listText: e.listText,
      img: e.img,
    });
    form.resetFields();
  };

  return (
    <Modal open={active} onCancel={onCancel} onOk={onOk}>
      <Form form={form} className="listForm" layout="vertical" onFinish={onFinish}>
        <Form.Item name="boldText" label="Bolded Text:">
          <textarea id="" cols={30} rows={2}></textarea>
        </Form.Item>
        <Form.Item
          name="listText"
          label="Description Text:"
          rules={[{ required: true, message: 'Please input list description' }]}
        >
          <textarea id="" cols={30} rows={2}></textarea>
        </Form.Item>
        <Form.Item name="img" label="Image:">
          <ImagePicker funnelId={pageData.funnel_id} />
        </Form.Item>
        <Button style={{ width: '100%' }} type="primary" htmlType="submit">
          Add
        </Button>
      </Form>
      <p className="alreadyAdd">Already Added:</p>
      {!!pageData && !!pageData.meta && !!pageData.meta.list && !!pageData.meta.list.length ? (
        <>
          <DragAndDrop
            listChange={(item) => updateMeta(item)}
            meta={!!pageData && !!pageData.meta && !!pageData.meta.list && pageData.meta.list}
            type="list"
          />
        </>
      ) : (
        <p className="noAdded">No added lists</p>
      )}
    </Modal>
  );
};
