import './Loading.css';

const Loading = () => {
  return (
    <svg id="loading" width="140" height="140" version="1.1" viewBox="0 0 37.042 37.042">
      <path
        d="m26.725 16.65-15.327-15.327h-10.074v10.074l5.2524 5.253"
        fill="none"
        stroke="#000"
        strokeWidth="2.6459"
      />
      <path
        d="m10.318 20.392 15.327 15.327h10.074v-10.074l-5.2524-5.253"
        fill="none"
        stroke="#000"
        strokeWidth="2.6459"
      />
      <path d="m16.65 10.318-15.327 15.327v10.074h10.074l5.253-5.2524" fill="none" stroke="#f60" strokeWidth="2.6459" />
      <path
        d="m20.392 26.725 15.327-15.327v-10.074h-10.074l-5.253 5.2524"
        fill="none"
        stroke="#f60"
        strokeWidth="2.6459"
      />
    </svg>
  );
};

export default Loading;
