import { Checkbox, Form, Input, InputNumber } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useEffect, useState } from 'react';

import { IFormProps, IScrub } from '@/types';

export const ScrubEditForm = ({ formData, onFormReady }: IFormProps<IScrub>) => {
  const [checked, setChecked] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  useEffect(() => {
    if (!formData?.affiliate_id) {
      setChecked(true);
    }
  }, [formData]);

  const onCheck = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      form.setFieldValue('affiliate_id', '');
    }
  };

  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <Checkbox className="global_checkbox" checked={checked} onChange={onCheck}>
        Global Scrub
      </Checkbox>
      <Form.Item
        style={{ width: '100%', display: checked ? 'none' : 'block' }}
        name="affiliate_id"
        rules={[
          { required: !checked, message: 'Missing Affiliate ID' },
          { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
        ]}
        label="Affiliate Id"
      >
        <Input disabled={checked} style={{ width: '100%' }} maxLength={4} placeholder="Affiliate ID" />
      </Form.Item>
      <Form.Item
        style={{ width: '100%' }}
        name="scrub_pct"
        rules={[{ required: true, message: 'Missing Scrub' }]}
        label={formData ? 'Update Scrub (%)' : 'Scrub (%)'}
      >
        <InputNumber style={{ width: '100%' }} min={0} maxLength={3} placeholder="Scrub" />
      </Form.Item>
    </Form>
  );
};
