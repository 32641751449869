import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Table, TablePaginationConfig } from 'antd';
import { Content } from 'antd/es/layout/layout';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import type { AlignType } from 'rc-table/es/interface';
import { useEffect, useMemo } from 'react';

import { fetchOrdersAtom } from '@/atoms/orders';
import { paginationAtom, setPaginationAtom } from '@/atoms/pagination';
import { IOrder } from '@/types';
import { columnSearch, commonFields, enableSorting, SortOrder } from '@/utils/table';

import PageHeader from '../PageHeader';

const Orders = () => {
  const setPagination = useSetAtom(setPaginationAtom);
  const [oders, fetchOrders] = useAtom(fetchOrdersAtom);
  const pagination = useAtomValue(paginationAtom);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const columns = useMemo(() => {
    return [
      {
        title: '#',
        width: '50px',
        ...commonFields('ID'),
      },
      {
        title: 'Time',
        ...commonFields('CreatedAt'),
        ...columnSearch('CreatedAt'),
        ...enableSorting('CreatedAt', SortOrder.DESCEND),
        render: (_: any, order: IOrder) => {
          const date = new Date(Date.parse(order.CreatedAt));
          return date.toLocaleString('en-US');
        },
      },
      {
        title: 'Payment method',
        ...commonFields('payment_method'),
        ...enableSorting('payment_method'),
        ...columnSearch('payment_method'),
      },
      {
        title: 'Product ID',
        ...commonFields('product_id'),
        ...enableSorting('product_id'),
        ...columnSearch('product_id'),
      },
      {
        title: 'Transaction ID',
        ...commonFields('transaction_id'),
        ...enableSorting('transaction_id'),
        ...columnSearch('transaction_id'),
      },
      {
        title: 'IP',
        ...commonFields('ip'),
        ...enableSorting('ip'),
        ...columnSearch('ip'),
      },
      {
        title: 'Affiliate ID',
        ...commonFields('aff_id'),
        ...enableSorting('aff_id'),
        ...columnSearch('aff_id'),
      },
      {
        title: 'Campaign ID',
        ...commonFields('campaign_id'),
        ...enableSorting('campaign_id'),
        ...columnSearch('campaign_id'),
      },
      {
        title: 'Customer ID',
        ...commonFields('customer_id'),
        ...enableSorting('customer_id'),
        ...columnSearch('customer_id'),
      },
      {
        title: 'Is scrub',
        align: 'center' as AlignType,
        width: '100px',
        ...commonFields('is_scrub'),
        ...enableSorting('is_scrub'),
        ...columnSearch('is_scrub'),
        render: (_: any, order: IOrder) => !!order.is_scrub && <CheckCircleTwoTone twoToneColor="#52c41a" />,
      },
      {
        title: 'Completed',
        align: 'center' as AlignType,
        width: '100px',
        ...commonFields('is_done'),
        ...enableSorting('is_done'),
        ...columnSearch('is_done'),
        render: (_: any, order: IOrder) =>
          !!order.is_done && order.is_done ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#FF0000" />
          ),
      },
    ];
  }, []);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IOrder> | SorterResult<IOrder>[],
  ) => {
    setPagination(pagination, filters, sorter);
    fetchOrders();
  };

  return (
    <>
      <PageHeader title="Orders" />
      <Content>
        <Table
          columns={columns as any}
          rowKey="ID"
          dataSource={oders.data}
          pagination={pagination}
          loading={oders.isLoading}
          onChange={handleTableChange}
        />
      </Content>
    </>
  );
};

export default Orders;
