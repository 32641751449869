import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useEffect, useState } from 'react';

const FreeShippingEdit = ({ onChange, pendingProduct }: any) => {
  const [form] = Form.useForm();
  const [checkedShip, setCheckedShip] = useState(pendingProduct.free_shipping_text === '' ? false : true);
  const [freeShipText, setFreeShipText] = useState(pendingProduct.free_shipping_text);
  const [freeShipImg, setfreeShipImg] = useState(pendingProduct.free_ship);

  const onFreeShipTextChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFreeShipText(e.target.value);
  };

  const onFreeShipIMGChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setfreeShipImg(e.target.value);
  };
  const onCheckShip = (e: any) => {
    setCheckedShip(!checkedShip);
  };
  useEffect(() => {
    if (!checkedShip) {
      setFreeShipText('');
      setfreeShipImg('');
      form.resetFields();
    }
    onChange({ free_shipping_text: freeShipText, free_ship: freeShipImg });
  }, [checkedShip, form, freeShipImg, freeShipText, onChange]);

  return (
    <Form form={form} layout="vertical">
      <div className="productFree">
        {checkedShip ? (
          <div
            className="freeshipping_disable"
            onClick={() => {
              setCheckedShip(false);
              setFreeShipText('');
              setfreeShipImg('');
            }}
          >
            <MinusOutlined /> Disable free shipping
          </div>
        ) : (
          <div
            className="freeshipping_disable"
            onClick={() => {
              setCheckedShip(true);
              setFreeShipText('');
              setfreeShipImg('');
            }}
          >
            <PlusOutlined /> Enable free shipping
          </div>
        )}
        <Checkbox style={{ visibility: 'hidden' }} checked={checkedShip} onChange={onCheckShip}>
          Free Shipping
        </Checkbox>
        {checkedShip && (
          <div className="productRowFree">
            <FormItem
              style={{ width: '30%', marginRight: 2 }}
              initialValue={freeShipText}
              name="free_shipping_text"
              label="Free Ship Text"
            >
              <Input onChange={onFreeShipTextChangeHandler} />
            </FormItem>
            <FormItem style={{ width: '30%' }} initialValue={freeShipImg} name="free_ship" label="Free Ship Image">
              <Input onChange={onFreeShipIMGChangeHandler} />
            </FormItem>
          </div>
        )}
      </div>
    </Form>
  );
};

export default FreeShippingEdit;
