import { Form } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import FormItem from 'antd/es/form/FormItem';
import { useState } from 'react';

export const DefaultSelectedEdit = ({ pendingProduct, onChange }: any) => {
  const [form] = Form.useForm();
  const [defaultSelected, setDefaultSelected] = useState(pendingProduct.preselected);

  const onSelectedChangeHandler = (e: CheckboxChangeEvent) => {
    setDefaultSelected(e.target.checked);
    onChange({ preselected: e.target.checked });
  };

  return (
    <div>
      <Form form={form} layout="vertical">
        <div className="productRow">
          <FormItem style={{ width: '100%' }} name="preselected" className="preselectedCheckbox">
            <Checkbox style={{ fontSize: '15px' }} onChange={onSelectedChangeHandler} checked={defaultSelected}>
              Default Selected Product
            </Checkbox>
          </FormItem>
        </div>
      </Form>
    </div>
  );
};
