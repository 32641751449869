import { EditFilled } from '@ant-design/icons';
import { Button, Form, Input, message, Tooltip } from 'antd';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { fetchDomainsAtom } from '@/atoms/domains';
import { domain } from '@/atoms/quickBuild';
import { IDomain } from '@/types';

import { EditDomain } from './EditDomain';

export interface PendingAction {
  item: any;
}

export const Domains = () => {
  const [domains, fetchDomains] = useAtom(fetchDomainsAtom);
  const [form] = Form.useForm();
  const [domainAtom, setDomainAtom] = useAtom(domain);
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);

  useEffect(() => {
    fetchDomains();
  }, [fetchDomains]);

  const onCreate = (values: IDomain) => {
    let haveIt = domains.data.find((domain) => domain.name === values.name);
    if (haveIt) {
      message.info(`Domain [${values.name}] already added`);
      return;
    }
    setDomainAtom({
      name: values.name?.trim(),
    });
    form.resetFields();
  };

  const onUpdate = (values: IDomain) => {
    setDomainAtom({
      name: values.name?.trim(),
    });
    setPendingAction(null);
  };

  return (
    <>
      <div className="funnelCreated">
        <div className="currentFunnel">
          {!!domainAtom ? (
            <>
              <div className="currentFunneldiv">
                {pendingAction?.item.name === domainAtom?.name ? (
                  <EditDomain
                    domain={domainAtom}
                    onUpdate={(item: IDomain) => onUpdate(item)}
                    onClose={() => setPendingAction(null)}
                  />
                ) : (
                  <div className="flexBetween">
                    <p className="domainname">{domainAtom.name}</p>
                    <div>
                      <Tooltip title="Edit Domain">
                        <EditFilled
                          onClick={() => {
                            setPendingAction({ item: domainAtom });
                            form.getFieldInstance('name').focus();
                          }}
                          className="editBtn"
                        />
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <h4 className="noThxAdd">No Domains added</h4>
          )}
        </div>
      </div>
      <Form form={form} onFinish={onCreate} layout="vertical" className="funnelQuickForm">
        <Form.Item
          name="name"
          label={`Insert Domain Name`}
          rules={[
            {
              required: true,
              type: 'string',
              message: 'Please Domain URL',
            },
            {
              pattern: new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/),
              message: 'Please enter a valid domain',
            },
          ]}
        >
          <Input placeholder="Input Domain URL" addonBefore="https://www." />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
