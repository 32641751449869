import { Form, Input, Select } from 'antd';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';

import { rolesAtom } from '../../atoms/roles';
import { IFormProps } from '../../types';
import { IUser } from '../../types';

export const UsersEditForm = ({ formData, onFormReady }: IFormProps<IUser>) => {
  const roles = useAtomValue(rolesAtom);
  const [form] = Form.useForm();

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <Form.Item
        name="name"
        label={'Insert User Name'}
        rules={[
          {
            required: true,
            type: 'string',
          },
        ]}
      >
        <Input placeholder="Input User Name" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Insert User Email"
        rules={[
          {
            pattern: RegExp(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            ),
            required: true,
            message: 'Please input Insert User Email',
          },
        ]}
      >
        <Input placeholder="Input User Email" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Insert Password"
        rules={[
          {
            pattern: RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!-_%*?&]{8,}$/),
            message: 'User Password must be at least 8 characters long 1 capital letter and at least 1 special sign',
          },
        ]}
      >
        <Input.Password placeholder="Password must be 8 characters long 1 capital letter and at least 1 special sign" />
      </Form.Item>
      <Form.Item
        name="nickname"
        label="Insert User Nickname"
        rules={[{ message: 'Please input Insert User Nickname' }]}
      >
        <Input placeholder="Input User Nickname" />
      </Form.Item>
      <Form.Item
        label="Set Role for User"
        style={{ width: '100%' }}
        name="role_id"
        rules={[{ required: false, message: 'Missing Role for User' }]}
      >
        <Select>
          {roles.data.map((role) => (
            <Select.Option value={role.id} key={role.id}>
              {role.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        hidden
        name="connection"
        label="Insert User Connection"
        rules={[{ message: 'Please input Insert User Connection' }]}
        initialValue={'Username-Password-Authentication'}
      >
        <Input placeholder="Input User Connection" />
      </Form.Item>
    </Form>
  );
};
