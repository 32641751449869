import { EyeOutlined, ForkOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormListFieldData, Input, Select, Space } from 'antd';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import { pagesAtom } from '@/atoms/pages';
import { fetchTemplatesAtom } from '@/atoms/templates';
import { IFormProps, IPage, ITemplate } from '@/types';

export enum PageTypes {
  PRESELL = 'presell',
  INTERSTITIAL = 'interstitial',
  CHECKOUT = 'checkout',
  UPSELL1 = 'upsell1',
  UPSELL2 = 'upsell2',
  THANKYOU = 'thankyou',
  THANKSAGAIN = 'thanksagain',
  WARRANTY = 'warranty',
  SPLIT = 'split',
}

enum SplitTypes {
  PERCENT = 'percent',
  MATCH = 'match',
}

export const PageEditForm = ({ formData, funnelId, onFormReady }: IFormProps<IPage>) => {
  const [templates, fetchTemplates] = useAtom(fetchTemplatesAtom);
  const [form] = Form.useForm();
  const pages = useAtomValue(pagesAtom);
  const pageType = Form.useWatch('page_type', form);

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <Form.Item name="page_type" rules={[{ required: true, message: 'Missing Page Type' }]} label="Select Page Type">
        <Select placeholder="Page Type">
          <Select.Option key={PageTypes.SPLIT}>
            <Space>
              <ForkOutlined />
              Page split
            </Space>
          </Select.Option>
          {Object.values(PageTypes)
            .filter((type) => type !== PageTypes.SPLIT)
            .map((type) => (
              <Select.Option key={type}>{type}</Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="Page Name" name="name" rules={[{ required: true, message: 'Missing Name' }]}>
        <Input placeholder="Name" />
      </Form.Item>
      {pageType !== PageTypes.SPLIT ? (
        <>
          <Form.Item
            name="page_path"
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Please input valid page path, it must start and end with slash',
                pattern: RegExp(/^\/([A-z0-9-_+]+\/)*$/),
              },
            ]}
            label="Page path"
          >
            <Input placeholder="Path: (exp: /offer/1/checkout)" />
          </Form.Item>
          <Form.Item
            name="template_id"
            label="Template Type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Select Page Template">
              <Select.Option value={''}>--Select Template--</Select.Option>
              {templates.data
                .filter((template: ITemplate) => !template.archived && pageType === template.page_type)
                .map((v) => {
                  return (
                    <Select.Option key={v.ID} value={v.ID}>
                      <span>{v.name}</span>
                      <a className="viewTemplate" target={'_blank'} href={v.template_host_url} rel="noreferrer">
                        <EyeOutlined />
                      </a>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </>
      ) : (
        <Form.List name="split">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <SplitCondition field={field} remove={remove} />
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add condition
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )}
      {formData && (
        <>
          <Form.Item name="page_id" label="Next Page (action)" tooltip="Redirect page on main action" preserve={true}>
            <Select
              placeholder="Select Next page"
              options={[
                { value: null, label: '-- None --' },
                ...pages.data
                  .filter(({ ID }) => formData.ID !== ID)
                  .map(({ ID, name, page_path }) => ({ value: ID, label: `${name} - ${page_path}` })),
              ]}
            ></Select>
          </Form.Item>
          {[PageTypes.SPLIT, PageTypes.UPSELL1, PageTypes.UPSELL2].includes(pageType) && (
            <Form.Item name="page_alt_id" label="Next Page (skip)" tooltip="Redirect page on no thanks action">
              <Select
                placeholder="Select next page"
                options={[
                  { value: null, label: '-- None --' },
                  ...pages.data
                    .filter(({ ID }) => formData.ID !== ID)
                    .map(({ ID, name, page_path }) => ({ value: ID, label: `${name} - ${page_path}` })),
                ]}
              ></Select>
            </Form.Item>
          )}
        </>
      )}
    </Form>
  );
};

const SplitCondition = ({ field, remove }: { field: FormListFieldData; remove: (name: number) => void }) => {
  const [type, setType] = useState<SplitTypes | undefined>(undefined);
  return (
    <Space key={field.key} align="center">
      <Form.Item name={[field.name, 'type']} label="Type">
        <Select
          placeholder="Select type"
          style={{ width: 100 }}
          options={[
            { value: SplitTypes.PERCENT, label: 'Percent' },
            { value: SplitTypes.MATCH, label: 'Match' },
          ]}
          onChange={setType}
        ></Select>
      </Form.Item>
      {type === SplitTypes.MATCH && (
        <Form.Item name={[field.name, 'param']} label="GET param">
          <Input placeholder="GET param" />
        </Form.Item>
      )}
      {!!type && (
        <Form.Item name={[field.name, 'value']} label="Value">
          <Input placeholder="Value" />
        </Form.Item>
      )}
      <MinusCircleOutlined onClick={() => remove(field.name)} />
    </Space>
  );
};
