import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { fetchPagesAtom } from '../../atoms/pages';
import { IFormProps, IPixel } from '../../types';

export const PixelEditForm = ({ formData, funnelId, onFormReady }: IFormProps<IPixel>) => {
  const [form] = Form.useForm();
  const [pages, fetchPages] = useAtom(fetchPagesAtom);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  useEffect(() => {
    fetchPages(funnelId);
    if (checked) {
      form.setFieldsValue({ aff_id: null, page_id: [], funnelId: funnelId });
    }
  }, [fetchPages, funnelId, checked, form]);

  const onChange = (checked: boolean) => {
    setChecked(checked);
  };

  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <Row gutter={24}>
        <Col span={20}>
          <Form.Item name="name" label="Pixel name : " rules={[{ required: true, message: 'Input pixel NAME' }]}>
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Global">
            <Switch disabled={!!formData?.ID} value={checked} onChange={onChange} />
          </Form.Item>
        </Col>
      </Row>
      {!formData?.ID ? (
        <Form.Item
          style={{ width: '100%' }}
          name="pageIds"
          label="Add to pages"
          rules={[{ required: true, message: 'Chose page for pixel' }]}
        >
          <Select
            mode="multiple"
            placeholder="Select pages to apply pixel to"
            options={pages.data.map((page) => ({
              value: page.ID,
              label: page.page_path,
            }))}
          />
        </Form.Item>
      ) : (
        <Form.Item name="page_id" hidden>
          <Input />
        </Form.Item>
      )}
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item name="aff_id" label={'AFFID'} >
            <InputNumber disabled={checked} addonBefore="AFFID : " />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="location"
            label="Location"
            rules={[{ required: true, message: 'Input pixel placment on page' }]}
          >
            <Select placeholder="Pixel Placement : ">
              <Select.Option value="head">{'HTML <head>'}</Select.Option>
              <Select.Option value="body">{'HTML <body>'}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="code" label="Code:" rules={[{ required: true, message: 'Code is required' }]}>
        <TextArea style={{ width: '100%', height: 400 }} />
      </Form.Item>
    </Form>
  );
};
