import { Tabs, TabsProps } from 'antd';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';

import { funnelsAtom } from '../../../atoms/funnels';
import { fetchOffersAtom } from '../../../atoms/offers';
import { IFormProps } from '../../../types';
import { IFunnel } from '../../../types';
import EditFaq from './EditFaqs/Index';
import EditManual from './EditManual/Index';
import { Footer } from './Footer';
import { General } from './General';

export const FunnelDetailsEditForm = ({ formData, isQuick }: IFormProps<IFunnel>) => {
  const funnels = useAtomValue(funnelsAtom);
  const [, fetchOffers] = useAtom(fetchOffersAtom);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'General',
      children: <General formData={funnels.funnel} />,
    },
    {
      key: '2',
      label: 'Footer',
      children: <Footer />,
    },
    {
      key: '3',
      label: 'Faq',
      children: <EditFaq formData={funnels.funnel} />,
    },
    {
      key: '4',
      label: 'Manual',
      children: <EditManual formData={funnels.funnel} />,
    },
  ];

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  return <Tabs className="tab_details" type="editable-card" defaultActiveKey="1" items={items} />;
};
