import { Button } from 'antd';
import { useAtom } from 'jotai';

import { updateMetaAtom } from '../../../../../../atoms/pages';

export const ProductSelect = ({ isActive }) => {
  const [, updateMeta] = useAtom(updateMetaAtom);

  const onActive = () => {
    updateMeta({ productSelect: !isActive });
  };

  return (
    <div className="script_container">
      <p>
        This Product Select script will select the product on this page depending on the <b>quantity</b> that was
        purchased on the <b>checkout page</b>
      </p>
      <Button
        onClick={onActive}
        className="activeBtn"
        style={{ backgroundColor: isActive ? 'rgb(177, 29, 10)' : 'rgb(10, 177, 10)' }}
      >
        {isActive ? 'Deactivate' : 'Activate'}
      </Button>
    </div>
  );
};
