import { Form, InputNumber } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useState } from 'react';

export const SavePrice = ({ pendingProduct, onChange, overridePrice }: any) => {
  const [form] = Form.useForm();
  const [bundlePrice, setBundlePrice] = useState(pendingProduct.product_data_price);
  const [savePrice, setSavePrice] = useState(pendingProduct.product_data_save);
  const [orgPrice, setOrg] = useState(pendingProduct.product_data_regPrice);
  const [eachPrice, setEachPrice] = useState(pendingProduct.product_data_unitPrice);

  const onSavePriceChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSavePrice(e);
    onChange({ product_data_save: e });
  };

  const onOrgPriceChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrg(e);
    onChange({ product_data_regPrice: e });
  };

  const onBundlePriceChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBundlePrice(e);
    onChange({ product_data_price: e });
  };

  const onEachPriceChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEachPrice(e);
    onChange({ product_data_unitPrice: e });
  };

  return (
    <div>
      <Form form={form} layout="vertical">
        <div className="productRow">
          <FormItem
            style={{ width: '100%', marginRight: 2 }}
            initialValue={bundlePrice}
            name="bundlePrice"
            label="Bundle Price"
          >
            {!overridePrice && (
              <p style={{ margin: 0 }}>*If you want to override the price you need to enable it first</p>
            )}
            <InputNumber style={{ width: '100%' }} disabled={!overridePrice} value={bundlePrice} onChange={onBundlePriceChangeHandler} />
          </FormItem>
          <FormItem
            style={{ width: '100%', marginRight: 2 }}
            initialValue={eachPrice}
            name="eachPrice"
            label="Each Price"
          >
            <InputNumber style={{ width: '100%' }} value={eachPrice} onChange={onEachPriceChangeHandler} />
          </FormItem>
          <FormItem
            style={{ width: '100%', marginRight: 2 }}
            initialValue={orgPrice}
            name="orgPrice"
            label="Original Price"
          >
            <InputNumber style={{ width: '100%' }} value={orgPrice} onChange={onOrgPriceChangeHandler} />
          </FormItem>
          <FormItem style={{ width: '100%' }} initialValue={savePrice} name="savePrice" label="Save Price">
            <InputNumber style={{ width: '100%' }} value={savePrice} onChange={onSavePriceChangeHandler} />
          </FormItem>
        </div>
      </Form>
    </div>
  );
};
