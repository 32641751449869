import { TablePaginationConfig } from 'antd';
import type { FilterValue, SorterResult, SortOrder } from 'antd/es/table/interface';
import { atom } from 'jotai';

export const paramsAtom = atom<string>('');
export const paginationAtom = atom<TablePaginationConfig>({ current: 1, pageSize: 10, total: 10 });
export const totalAtom = atom<number>(0);

export const setPaginationAtom = atom(
  (get) => get(paginationAtom),
  (
    _,
    set,
    pagination: TablePaginationConfig,
    filters?: Record<string, FilterValue | null>,
    sorter?: SorterResult<any> | SorterResult<any>[],
  ) => {
    const data: {
      limit?: number;
      page?: number;
      sort?: string;
      order?: SortOrder;
      filters?: Record<string, FilterValue | null>;
    } = {
      limit: pagination.pageSize,
      page: pagination.current,
      sort: '',
      order: null,
      filters: {},
    };
    set(paginationAtom, pagination);
    // TODO: Implement filters
    if (filters) data.filters = filters;
    const sorting = Array.isArray(sorter) ? sorter[0] : sorter;
    if (sorting?.column && sorting?.order) {
      data.sort = sorting.column && (sorting.field as string);
      data.order = sorting.order;
    }
    set(
      paramsAtom,
      Object.keys(data)
        .map((key) => {
          const value = data[key as keyof typeof data];
          return !!value && `${key}=${value}`;
        })
        .filter(Boolean)
        .join('&'),
    );
  },
);
