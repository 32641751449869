import { atom } from 'jotai';

import { IImage } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<IImage[]>([]);
const isLoading = atom<boolean>(false);

const byteNumberFormatter = Intl.NumberFormat('en', {
  notation: 'compact',
  style: 'unit',
  unit: 'byte',
  unitDisplay: 'narrow',
});

export interface ImagesState {
  data: IImage[];
  isLoading: boolean;
}

export const imagesAtom = atom<ImagesState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
}));

export const fetchImagesAtom = atom(
  (get) => get(imagesAtom),
  async (_, set, funnelId: number) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/images/${funnelId}`)
      .json<IImage[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const uploadCompleteAtom = atom(null, (get, set, payload: IImage) => {
  const images = get(dataAtom) ?? [];
  const image: IImage = {
    size: byteNumberFormatter.format(payload.size),
    name: payload.name,
    uid: payload.name,
    url: payload.url,
    type: payload.type,
  } as any;
  const index = images.findIndex((image) => image.name === payload.name);
  if (index >= 0) {
    images[index] = image;
    set(dataAtom, images);
  } else {
    set(dataAtom, [...images, image]);
  }
});

export const deleteImageAtom = atom(null, async (get, set, funnelId: number, name: string) => {
  if (!funnelId) return;
  set(isLoading, true);
  $http
    .delete(`/api/v1/images/${funnelId}/${name}`)
    .json<IImage>()
    .then(() =>
      set(
        dataAtom,
        get(dataAtom).filter((images: IImage) => images.name !== name),
      ),
    )
    .finally(() => set(isLoading, false));
});
