import { atom } from 'jotai';

import { IStore } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<IStore[]>([]);
const isLoading = atom<boolean>(false);

export interface RolesState {
  data: IStore[];
  isLoading: boolean;
}

export const storesAtom = atom<RolesState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
}));

export const fetchStoresAtom = atom(
  (get) => get(storesAtom),
  async (_, set) => {
    set(isLoading, true);
    $http
      .get('/api/v1/stores')
      .json<IStore[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);
