import { Button, Form, Input, Modal } from 'antd';
import { useAtom } from 'jotai';

import { updateMetaAtom } from '../../../../../atoms/pages';
import { DragAndDrop } from '../../../../UI/DragAndDrop';

export const AddSize = ({ onAdd, active, onCancel, onOk, pageData }) => {
  const [form] = Form.useForm();
  const [, updateMeta] = useAtom(updateMetaAtom);

  const onFinish = (e) => {
    onAdd(e.size);
  };

  return (
    <Modal open={active} onCancel={onCancel} onOk={onOk}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="size" label="Insert Product Size">
          <Input placeholder="XL,L,M,S or Blue,Red,Green" />
        </Form.Item>
        <Button style={{ width: '100%' }} type="primary" htmlType="submit">
          Add
        </Button>
      </Form>
      <p className="alreadyAdd">Already Added:</p>
      {!!pageData && !!pageData.meta && !!pageData.meta.productSizes && !!pageData.meta.productSizes.length ? (
        <>
          <DragAndDrop
            listChange={(item) => updateMeta(item)}
            meta={!!pageData && !!pageData.meta && !!pageData.meta.productSizes && pageData.meta.productSizes}
            type="productSizes"
          />
        </>
      ) : (
        <p>No added lists</p>
      )}
    </Modal>
  );
};
