import { message, notification } from 'antd';

import { IRequestOptions } from '../types';

enum HTTPMethod {
  GET = 'GET',
  HEAD = 'HEAD',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  CONNECT = 'CONNECT',
  OPTIONS = 'OPTIONS',
  TRACE = 'TRACE',
  PATCH = 'PATCH',
}

class FetchContent {
  response: Promise<Response>;
  url: string;
  constructor(url: string, options: any) {
    this.url = url;
    this.response = fetch(url, options);
  }
  async text() {
    return await this.response
      .then((resolvedResponse) => resolvedResponse.text())
      .catch((error) => Promise.reject({ error: error.message }));
  }
  async json<T>(): Promise<T> {
    try {
      return this.response.then((response) => {
        if (response.ok) {
          return response.json() as T;
        } else {
          try {
            response.json().then((err: { success: boolean; message: string }) => {
              err.success
                ? message.success(err.message)
                : notification.error({
                    message: 'Error',
                    // Capitalize first letter
                    description: err.message.charAt(0).toUpperCase() + err.message.slice(1),
                    placement: 'top',
                  });
            });
          } catch (error) {
            console.error(error);
          }
          return Promise.reject();
        }
      });
    } catch (error: any) {
      message.error(error.message);
      return Promise.reject();
    }
  }
  async jsonStream<T>(cb: (data: T) => void) {
    const response = await this.response;
    const reader = response.body?.getReader();
    if (reader) {
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const decodedValue = new TextDecoder().decode(value);
        try {
          const data = JSON.parse(decodedValue);
          cb(data);
        } catch (error) {
          console.error(error);
          break;
        }
      }
    }
  }
}

class HTTPBase {
  static token: string = '';

  constructor() {
    Object.freeze(this);
  }

  setToken(token: string) {
    HTTPBase.token = token;
  }

  getToken() {
    return HTTPBase.token;
  }

  get(url: string, skipOptions = false, options?: IRequestOptions) {
    return this.request(url, { ...options, method: HTTPMethod.GET }, skipOptions);
  }

  head(url: string, options?: IRequestOptions) {
    return this.request(url, { ...options, method: HTTPMethod.HEAD });
  }

  post(url: string, options?: IRequestOptions) {
    return this.request(url, { ...options, method: HTTPMethod.POST });
  }

  put(url: string, options?: IRequestOptions) {
    return this.request(url, { ...options, method: HTTPMethod.PUT });
  }

  delete(url: string, options?: IRequestOptions) {
    return this.request(url, { ...options, method: HTTPMethod.DELETE });
  }

  connect(url: string, options?: IRequestOptions) {
    return this.request(url, { ...options, method: HTTPMethod.CONNECT });
  }

  options(url: string, options?: IRequestOptions) {
    return this.request(url, { ...options, method: HTTPMethod.OPTIONS });
  }

  trace(url: string, options?: IRequestOptions) {
    return this.request(url, { ...options, method: HTTPMethod.TRACE });
  }

  patch(url: string, options?: IRequestOptions) {
    return this.request(url, { ...options, method: HTTPMethod.PATCH });
  }

  private request(url: string, options: IRequestOptions, skipOptions = false) {
    return new FetchContent(this.handleUrlSchema(url), skipOptions ? options : this.processOptions(options));
  }

  private handleUrlSchema(url: string) {
    const isUrl = new RegExp(/^https?:\/\//i);
    if (url.match(isUrl)) return url;
    return url;
  }

  private processOptions(options: IRequestOptions) {
    return {
      ...options,
      body: JSON.stringify(options.body),
      headers: { ...options.headers, 'Content-Type': 'application/json', Authorization: `Bearer ${HTTPBase.token}` },
    };
  }
}

export const $http = new HTTPBase();
