import { atom } from 'jotai';

export interface IProgress {
  percent: number;
  message: string;
}

export const progressAtom = atom<IProgress>({
  percent: 0,
  message: '',
});
