import 'react-quill/dist/quill.snow.css';

import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';
import { useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';

Quill.register('modules/imageActions', ImageActions);
Quill.register('modules/imageFormats', ImageFormats);

export const RichTextEditor = ({ onValueChange, value }) => {
  const quill: any = useRef();

  const imageHandler = () => {
    const quillEditor = quill.current.getEditor();
    const range = quillEditor.getSelection(true);
    const value = prompt('please copy paste image url here');
    if (value) {
      quillEditor.insertEmbed(range.index, 'image', value, 'user');
    }
  };

  const formats = [
    'align',
    'bold',
    'code-block',
    'color',
    'float',
    'height',
    'image',
    'italic',
    'link',
    'list',
    'placeholder',
    'calltoaction',
    'size',
    'underline',
    'width',
    'video',
    'indent'
  ];

  const modules = useMemo(
    () => ({
      imageActions: {},
      imageFormats: {},
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'blockquote'],
          [{ color: [] }, { background: [] }],
          ['align', { list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', 'image', 'video'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    [],
  );

  return (
    <ReactQuill
      ref={(el) => (quill.current = el)}
      className="richtext"
      modules={modules}
      formats={formats}
      theme="snow"
      value={value}
      onChange={onValueChange}
    />
  );
};
