import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Card, Modal } from 'antd';
import { useAtom } from 'jotai';
import { useState } from 'react';

import { updateMetaAtom } from '../../../../../atoms/pages';
import { ActionType, PendingAction } from '../../../../../types';
import { IProduct } from '../../../../../types';
import { DragAndDrop } from '../../../../UI/DragAndDrop';
import { AddModal } from './AddModal';
import EditModalIndex from './EditProducts';

interface ParentProps {
  pageData: any;
  pendingSaveData: any;
  listChange: Function;
  iframeDocument: any;
}

export const Products = ({ pageData, listChange, iframeDocument, pendingSaveData }: ParentProps) => {
  const [, updateMeta] = useAtom(updateMetaAtom);
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);

  const onAddProductHandler = (incomingProduct: IProduct[]) => {
    listChange({ products: incomingProduct });
  };

  const onEditProductHandler = (item: any) => {
    if (item && !!pendingSaveData && !!pendingSaveData.meta) {
      let newList = [...pendingSaveData.meta.products];
      const index = newList.findIndex((v: any) => v.product_id === item.product_id);
      newList[index] = item;
      listChange({ products: newList });
    }
    setPendingAction(null);
  };

  const onDelete = (itemDeleted: any) => {
    if (itemDeleted && !!pageData && !!pageData.meta) {
      let productFiltered = pageData.meta.products.filter((v) => v.product_id !== itemDeleted.product_id);
      listChange({ products: productFiltered });
    }
    setPendingAction(null);
  };

  const onDeleteAllProducts = () => {
    listChange({ products: [] });
  };

  return (
    <>
      <p className="info_text">
        Main Group of Products:{' '}
        {!!pageData && !!pageData.meta && !!pageData.meta.products && !!pageData.meta.products.length && (
          <Button
            style={{ backgroundColor: 'red', fontWeight: 'bold', color: 'white' }}
            onClick={() =>
              Modal.confirm({
                title: `Delete`,
                content: 'Are you sure you want to delete all products?',
                onOk: () => onDeleteAllProducts(),
              })
            }
          >
            remove all
          </Button>
        )}
      </p>
      <Modal
        open={pendingAction?.type === ActionType.Add}
        title="Add Products"
        onOk={() => setPendingAction(null)}
        onCancel={() => setPendingAction(null)}
      >
        <AddModal pageData={pageData} onAdd={(product: any) => onAddProductHandler(product)} />
        <p className="alreadyAdd">Already Added:</p>
        {!!pageData && !!pageData.meta && !!pageData.meta.products && !!pageData.meta.products.length ? (
          <>
            <DragAndDrop
              listChange={(item) => updateMeta(item)}
              meta={!!pageData && !!pageData.meta && !!pageData.meta.products && pageData.meta.products}
              type="products"
            />
          </>
        ) : (
          <p className="noAdded">No added Products</p>
        )}
      </Modal>
      {!!pageData && !!pageData.meta && !!pageData.meta.products && !!pageData.meta.products.length ? (
        pageData.meta.products.map((product) => {
          return (
            <Card
              onClick={() => setPendingAction({ type: ActionType.Edit, item: product })}
              className="card-products"
              size="small"
              key={product.product_id}
            >
              <div>
                ({product.product_id}){product.product_data_name}
              </div>
            </Card>
          );
        })
      ) : (
        <p style={{ textAlign: 'center' }}>No Products added</p>
      )}
      <PlusCircleOutlined
        onClick={() => setPendingAction({ item: null, type: ActionType.Add })}
        className={`text-center addicon`}
      />
      {pendingAction?.item && (
        <EditModalIndex
          onFinished={(item: any) => onEditProductHandler(item)}
          localmeta={!!localStorage.getItem('features') ? JSON.parse(localStorage.getItem('features') as any) : []}
          pendingProduct={pendingAction?.item}
          active={pendingAction?.type === ActionType.Edit}
          overridePrice={!!pageData && !!pageData.meta && pageData.meta.product_override_price}
          pageData={pageData}
          onDelete={(itemDeleted: any) => onDelete(itemDeleted)}
        />
      )}
    </>
  );
};
