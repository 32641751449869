import {
  BranchesOutlined,
  CloudOutlined,
  DeploymentUnitOutlined,
  GoogleOutlined,
  MailOutlined,
  PhoneOutlined,
  SafetyOutlined,
} from '@ant-design/icons';
import { Select } from 'antd';
import { useSetAtom } from 'jotai';

import { updateFunnelAtom } from '../../../atoms/funnels';
import { IRouteParams } from '../../../types';

const Details = ({ funnel }: IRouteParams) => {
  return (
    <div className="customer_header">
      <div className="detail_div">
        <p>
          <DeploymentUnitOutlined style={{ color: 'black' }} /> EF ID:
        </p>
        <span>{funnel?.offer?.ef_offer_id}</span>
      </div>
      <div className="detail_div">
        <p>
          <DeploymentUnitOutlined style={{ color: 'black' }} /> OFFER ID:
        </p>
        <span>
          {funnel?.offer?.name} ({funnel?.offer?.offer_id})
        </span>
      </div>
      <div className="row">
        <div className="detail_div col">
          <p>
            <MailOutlined style={{ color: 'blue' }} /> CUSTOMER SUPPORT E-MAIL:
          </p>
          <span>{funnel?.cs_email}</span>
        </div>
        <div className="detail_div">
          <p>
            <PhoneOutlined style={{ color: 'green' }} /> CUSTOMER SUPPORT NUMBER:
          </p>
          <span>{funnel?.cs_number}</span>
        </div>
        <div className="detail_div">
          <p>
            <BranchesOutlined style={{ color: 'red' }} /> ROOT(INDEX) PAGE:
          </p>
          <span>{funnel?.index_page?.page_path}</span>{' '}
        </div>
      </div>
      <div>
        <div className="detail_div">
          <p>
            <GoogleOutlined style={{ color: 'black' }} /> GOOGLE TAG MANAGER(GTM):
          </p>
          <span>GTM-{funnel?.details?.GTM}</span>
        </div>
        <div className="detail_div">
          <p>
            <GoogleOutlined style={{ color: 'black' }} /> GOOGLE OPTIMIZE(OPT):
          </p>
          <span>OPT-{funnel?.details?.OPT}</span>
        </div>
        <div className="detail_div">
          <p>
            <GoogleOutlined style={{ color: 'black' }} /> GOOGLE ANALYTICS(UA):
          </p>
          <span>UA-{funnel?.details?.UA}</span>
        </div>
        <div className="detail_div">
          <p>
            <SafetyOutlined style={{ color: 'black' }} /> Guarantee:
          </p>
          <span>{funnel?.details?.guarantee}</span>
        </div>
        <div className="detail_div">
          <p>
            <CloudOutlined style={{ color: 'black' }} /> CLOUDFLARE SITE KEY:
          </p>
          <span>ID-{funnel?.details?.cf_site_key}</span>
        </div>
        <div className="detail_div">
          <p>
            <CloudOutlined style={{ color: 'black' }} /> CLOUDFLARE SECRET KEY:
          </p>
          <span>ID-{funnel?.details?.cf_secret_key}</span>
        </div>
      </div>
    </div>
  );
};

export default Details;
