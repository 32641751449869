import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Modal } from 'antd';
import parse from 'html-react-parser';
import { useAtom, useSetAtom } from 'jotai';
import { useState } from 'react';

import { updateFunnelAtom } from '../../../../atoms/funnels';
import { funnel } from '../../../../atoms/quickBuild';
import { IFunnel } from '../../../../types';
import { FaqAddForm } from './FaqAddForm';
import { FaqEditForm } from './FaqEditForm';

enum ActionType {
  Add,
  Delete,
  Edit,
}
interface PendingAction {
  type: ActionType;
  item: any;
}

const EditFaq = ({ formData }: any) => {
  const updateFunnel = useSetAtom(updateFunnelAtom);
  const [funnelAtom, setFunnelAtom] = useAtom(funnel);
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);
  let saved = !!formData && !!formData.details && !!formData.details.faq ? formData.details.faq : [];
  let quickSaved = !!funnelAtom && !!funnelAtom.details && !!funnelAtom.details.faq ? funnelAtom.details.faq : [];

  const onAdd = (incommingFaq: any) => {
    if (incommingFaq.faq_question) {
      if (formData) {
        updateFunnel(formData.ID, { details: { faq: [...saved, incommingFaq] }, ID: formData.ID });
      } else {
        let buildedDetails = !!funnelAtom?.details ? funnelAtom.details : {};
        let datasend = { details: { ...buildedDetails, faq: [...quickSaved, incommingFaq] } };
        setFunnelAtom({ ...funnelAtom, ...datasend } as IFunnel);
      }

      setPendingAction(null);
    }
  };

  const onEdit = (item: any) => {
    if (item) {
      let newList = !formData ? [...quickSaved] : [...saved];
      const index = newList.findIndex((v: any) => v.faq_id === item.faq_id);
      newList[index] = item;
      if (formData) {
        updateFunnel(formData.ID, { details: { faq: newList }, ID: formData.ID });
      } else {
        let buildedDetails = !!funnelAtom?.details ? funnelAtom.details : {};
        let datasend = { details: { ...buildedDetails, faq: newList } };
        setFunnelAtom({ ...funnelAtom, ...datasend } as IFunnel);
      }
    }
    setPendingAction(null);
  };

  const onDelete = (itemDeleted: any) => {
    if (itemDeleted) {
      if (formData) {
        updateFunnel(formData.ID, {
          details: { faq: saved.filter((v) => v.faq_id !== itemDeleted.faq_id) },
          ID: formData.ID,
        });
      } else {
        let buildedDetails = !!funnelAtom?.details ? funnelAtom.details : {};
        let datasend = {
          details: { ...buildedDetails, faq: quickSaved.filter((v) => v.faq_id !== itemDeleted.faq_id) },
        };
        setFunnelAtom({ ...funnelAtom, ...datasend } as IFunnel);
      }
    }
    setPendingAction(null);
  };

  let items = !!formData ? saved : quickSaved;

  return (
    <div>
      <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 18 }}>Added Faqs</p>
      {items.length ? (
        items.map((item: any, index: any) => {
          return (
            <Card className="card-faqs" size="small" key={String(index)}>
              {!!pendingAction?.item && pendingAction?.item.faq_id === item.faq_id ? (
                <FaqEditForm
                  onEdit={(item: any) => onEdit(item)}
                  item={pendingAction?.item}
                  onCancel={() => setPendingAction(null)}
                />
              ) : (
                <div style={{ overflow: 'hidden', width: '70%' }}>
                  <p className="faqQuestion">{item.faq_question}</p>
                  <div className="faqAnswer">{parse(item.faq_content[0].faq_ct_text)}</div>
                </div>
              )}
              <div>
                <Button
                  type="primary"
                  className="removeBTN"
                  danger
                  shape="circle"
                  onClick={() => {
                    Modal.confirm({
                      title: `Delete faq`,
                      content: 'Are you sure you want to delete this faq?',
                      onOk: () => onDelete(item),
                    });
                  }}
                >
                  <DeleteOutlined />
                </Button>
                <Button
                  type="primary"
                  className="editBTN"
                  shape="circle"
                  onClick={() => setPendingAction({ type: ActionType.Edit, item })}
                >
                  <EditOutlined />
                </Button>
              </div>
            </Card>
          );
        })
      ) : (
        <p style={{ textAlign: 'center', color: 'gray' }}>There is no faqs</p>
      )}
      <div
        className="addIconPlusFaqManual"
        onClick={() => {
          setPendingAction({ type: ActionType.Add, item: null });
        }}
      >
        <PlusOutlined />
      </div>
      <FaqAddForm
        active={pendingAction?.type === ActionType.Add}
        onAdd={(item: any) => onAdd(item)}
        onCancel={() => setPendingAction(null)}
      />
    </div>
  );
};

export default EditFaq;
