import { Modal } from 'antd';

export const ListDeleteForm = ({ active, onFinished, pendingProduct, onCancel }: any) => {
  const deleteItem = () => {
    onFinished(pendingProduct);
  };
  return (
    <Modal open={active} onCancel={() => onFinished(null)} onOk={() => deleteItem()}>
      <div>Remove:</div>
      <span style={{ color: 'red' }}>{pendingProduct?.listText}</span>
    </Modal>
  );
};
