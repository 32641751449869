import { Tooltip } from 'antd';

interface TooltipProps {
  text:string
  icon:any
}

export const CustomTooltip = ({ text,icon }: TooltipProps) => {
  return (
    <Tooltip placement="topRight" color="black" title={text}>
      {icon}
    </Tooltip>
  );
};
