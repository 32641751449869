import { Form, InputNumber, Select } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { fetchShippingAtom } from '@/atoms/shipping';
import { IFormProps, IProduct } from '@/types';

export const ProductEditForm = ({ formData, onFormReady }: IFormProps<IProduct>) => {
  const [form] = Form.useForm();
  const [shipping, fetchShipping] = useAtom(fetchShippingAtom);

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  useEffect(() => {
    fetchShipping();
  }, [fetchShipping]);

  useEffect(() => {
    if (!formData) {
      form.resetFields();
    } else {
      form.setFieldsValue(formData);
    }
  }, [form, formData]);

  const billingModels = [
    {
      id: 2,
      name: 'One Time Purchase',
    },
    {
      id: 3,
      name: '30 Day Subscription',
    },
    {
      id: 4,
      name: '60 Day Subscription',
    },
    {
      id: 5,
      name: 'One Day Subscription',
    }
  ];

  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <Form.Item style={{ width: '100%' }} name="shipping_id" label="Shipping method">
        <Select placeholder="Select shipping">
          {shipping.data
            .filter(({ ID }) => formData?.ID !== ID)
            .map(({ ID, name, price }) => (
              <Select.Option key={ID} value={ID}>
                {ID} {name} (${price})
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item style={{ width: '100%' }} name="billing_model" label="Billing model">
        <Select placeholder="Billing model">
          {billingModels.map(({ id, name }) => (
            <Select.Option key={id} value={id}>
              {id} - {name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>{' '}
      <Form.Item name="product_price" label="Product Price">
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
    </Form>
  );
};
