import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { Button, Popconfirm, Space, Table } from 'antd';
import { useAtom } from 'jotai';
import type { AlignType } from 'rc-table/es/interface';
import { useEffect } from 'react';

import { deleteProductAtom, fetchProductsByOfferAtom, updateProductAtom } from '@/atoms/products';
import { IRouteParams } from '@/types';
import { IProduct } from '@/types';
import { columnSearch, commonFields, enableSorting } from '@/utils/table';

import { ProductEditForm } from '../../Forms';
import Loading from '../../Loading';
import { EditButton } from '../../UI/EditButton';

export const OfferProducts = ({ id }: IRouteParams) => {
  const [product, fetchProductsByOffer] = useAtom(fetchProductsByOfferAtom);
  const [, deleteProduct] = useAtom(deleteProductAtom);
  const [, updateProduct] = useAtom(updateProductAtom);

  useEffect(() => {
    fetchProductsByOffer(id);
  }, [fetchProductsByOffer, id]);

  const columns = [
    {
      title: 'ID',
      ...commonFields('product_id'),
      ...enableSorting('product_id'),
    },
    {
      title: 'Name',
      ...commonFields('name'),
      ...enableSorting('name'),
      ...columnSearch('name'),
    },
    {
      title: 'SKU',
      ...commonFields('sku'),
      ...enableSorting('sku'),
      ...columnSearch('sku'),
    },
    {
      title: 'Price',
      ...commonFields('product_price'),
      ...enableSorting('product_price'),
      ...columnSearch('product_price'),
    },
    {
      title: 'Category',
      ...commonFields('product_category'),
      ...enableSorting('product_category'),
      ...columnSearch('product_category'),
    },
    {
      title: 'Billing model',
      ...commonFields('billing_model'),
      ...columnSearch('billing_model'),
    },
    {
      title: 'Shipping',
      ...commonFields('shipping_id'),
      ...enableSorting('shipping_id'),
      ...columnSearch('shipping_id'),
    },
    {
      title: 'Action',
      ...commonFields('action'),
      align: 'center' as AlignType,
      render: (_: any, product: IProduct) => (
        <Space>
          <Popconfirm
            title="Are you sure you wont to remove product?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteProduct(product.ID)}
          >
            <Button shape="circle" icon={<DeleteOutlined />} />
          </Popconfirm>
          <EditButton
            data={product}
            component={ProductEditForm}
            title="Edit product"
            shape="circle"
            icon={<EditFilled />}
            onSave={(values: IProduct) => updateProduct(product.ID, values)}
          />{' '}
        </Space>
      ),
    },
  ];
  return product.isLoading ? (
    <Loading />
  ) : (
    <Table dataSource={product.data} rowKey="ID" columns={columns} pagination={false} scroll={{ y: 300 }} />
  );
};
