import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';

import { pages } from '@/atoms/quickBuild';
import { IPage } from '@/types';

export const EditPage = ({ page, onUpdate, onClose }) => {
  const [form] = Form.useForm();
  const pageAtom = useAtomValue(pages);

  useEffect(() => {
    form.setFieldsValue({ ...page });
  }, [form, page]);

  const onFinish = (values: IPage) => {
    onUpdate({ ...values, ID: page.ID });
  };

  return (
    <Form form={form} onFinish={onFinish} className="currentPageEdit">
      <Form.Item label="Page Name" name="name" rules={[{ required: true, message: 'Missing Name' }]}>
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item
        name="page_path"
        rules={[
          {
            required: true,
            type: 'string',
            message: 'Please input valid page path, it must start and end with slash',
            pattern: RegExp(/^\/([A-z0-9-_+]+\/)*$/),
          },
        ]}
        label="Page path"
      >
        <Input placeholder="Path: (exp: /offer/1/checkout)" />
      </Form.Item>
      <Form.Item name="page_id" label="Next Page (action)" tooltip="Redirect page on main action">
        <Select placeholder="Select Next page">
          {pageAtom
            ?.filter(({ ID }) => ID !== page.ID)
            .map(({ ID, name, page_path }) => (
              <Select.Option key={ID} value={ID}>
                {name} - {page_path}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item name="page_alt_id" label="Next Page (skip)" tooltip="Redirect page on no thanks action">
        <Select placeholder="Select index page">
          {pageAtom
            ?.filter(({ ID }) => ID !== page.ID)
            .map(({ ID, name, page_path }) => (
              <Select.Option key={ID} value={ID}>
                {name} - {page_path}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <div className="formbtns">
        <Form.Item>
          <Button size="small" type="primary" htmlType="submit">
            <SaveOutlined />
          </Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={onClose} size="small" danger type="primary" htmlType="button">
            <CloseOutlined />
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};
