import { useAtomValue } from 'jotai';

import { productsAtom } from '../../../../atoms/products';
import { ProductAddons } from './ProductAddons/Index';
import { Products } from './Products/Index';
import { Size } from './Size/Index';
import { Warranty } from './Warranty';

interface CheckoutListMenuProps {
  template: any;
  editorProps: any;
  iframeDocument: any;
  product: any;
  pageData: any;
  pendingSaveData: any;
  listChange: any;
}
export interface ITemplateImageEdit {
  funnelId: number;
  pendingSaveData: any;
  onChange: (value: any) => void;
  editableImages: Array<{ key: string; el: HTMLElement }>;
}

interface ICheckoutProducts {
  key: string;
  component: any;
  meta?: {};
}

const checkoutProducts: ICheckoutProducts[] = [
  {
    key: 'Warranty',
    component: Warranty,
  },
  {
    key: 'Size',
    component: Size,
  },
  {
    key: 'Products',
    component: Products,
  },
  {
    key: 'ProductAddons',
    component: ProductAddons,
  },
];

const CheckoutListMenu = ({ iframeDocument, pageData, pendingSaveData, listChange }: CheckoutListMenuProps) => {
  const products = useAtomValue(productsAtom);

  if (!products.data) {
    return (
      <div style={{ textAlign: 'center' }}>
        <p>Something went wrong when fetching porducts</p>
        <p>Please insert offer in Funnel Details</p>
      </div>
    );
  }
  return (
    <>
      {checkoutProducts
        .map((prods) => {
          return {
            ...prods,
            meta: { iframeDocument, pageData, pendingSaveData, listChange },
          };
        })
        .flatMap((prodsTrans: ICheckoutProducts,i) => {
          return (
            <div key={i}>
              <prodsTrans.component {...prodsTrans.meta} key={prodsTrans.key} />
              <hr className="hrTab" />
            </div>
          );
        })}
    </>
  );
};

export default CheckoutListMenu;
