import { Button, Spin } from 'antd';
import { useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';

import { createQuickFunnel, isBuilding } from '@/atoms/quickBuild';

export const Build = () => {
  const quickBuild = useSetAtom(createQuickFunnel);
  const isBuildingAtom = useAtomValue(isBuilding);
  const [btnDisabled, setBtnDisabled] = useState(false);

  if (isBuildingAtom) {
    return <Spin size="large" className="buildSpiner" tip="Please wait, Building in progress" />;
  }

  return (
    <>
      <Button
        disabled={btnDisabled}
        style={{ margin: '50px auto' }}
        type="primary"
        onClick={() => {
          quickBuild();
          setBtnDisabled(true);
        }}
      >
        Build a Funnel
      </Button>
    </>
  );
};
