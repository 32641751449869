import { atom } from 'jotai';

import { IOffer } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<IOffer[]>([]);
const offerAtom = atom<IOffer | undefined>(undefined);
const isLoading = atom<boolean>(false);

export interface OffersState {
  data: IOffer[];
  offer: IOffer | undefined;
  isLoading: boolean;
}

export const offersAtom = atom<OffersState>((get) => ({
  data: get(dataAtom),
  offer: get(offerAtom),
  isLoading: get(isLoading),
}));

export const fetchOffersAtom = atom(
  (get) => get(offersAtom),
  async (_, set) => {
    set(isLoading, true);
    $http
      .get('/api/v1/offers')
      .json<IOffer[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const fetchOfferAtom = atom(
  (get) => get(offerAtom),
  async (_, set, offerId: number) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/offers/${offerId}`)
      .json<IOffer>()
      .then((response) => set(offerAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const createOfferAtom = atom(
  (get) => get(offerAtom),
  async (get, set, body?: IOffer) => {
    if (!body) return;
    set(isLoading, true);
    $http
      .post(`/api/v1/offers/create`, { body })
      .json<IOffer>()
      .then((response) => set(dataAtom, [...get(dataAtom), response]))
      .finally(() => set(isLoading, false));
  },
);

export const updateOfferAtom = atom(null, async (get, set, offerId: number, body: IOffer[]) => {
  if (!offerId) return;
  const offers = get(offersAtom);
  set(isLoading, true);
  $http
    .put(`/api/v1/offers/${offerId}`, { body })
    .json<IOffer>()
    .then((offer) => set(dataAtom, offers.data.updateValueBy('ID', offer)))
    .finally(() => set(isLoading, false));
});

export const deleteOfferAtom = atom(null, async (get, set, offerId?: number) => {
  if (!offerId) return;
  set(isLoading, true);
  $http
    .delete(`/api/v1/offers/${offerId}`)
    .json<IOffer>()
    .then(() =>
      set(
        dataAtom,
        get(dataAtom).filter(({ ID }) => ID !== offerId),
      ),
    )
    .finally(() => set(isLoading, false));
});

export const importOfferAtom = atom(
  (get) => get(offerAtom),
  async (get, set, body: IOffer): Promise<IOffer> => {
    set(isLoading, true);
    return $http
      .post(`/api/v1/offers`, { body })
      .json<IOffer>()
      .then((response: any) => {
        set(dataAtom, [...get(dataAtom), response]);
        return response;
      })
      .finally(() => set(isLoading, false));
  },
);
