import { useAtomValue } from 'jotai';

import { proofAtom } from '@/atoms/pages';

export const Proof = () => {
  const proof = useAtomValue(proofAtom);

  return (
    <>
      <p className="proof_page">
        - <strong>page edited:</strong> {!!proof && proof.page_path} -
      </p>
      {!!proof &&
        !!proof.meta &&
        Object.entries(proof.meta)
          .filter(([key, value]: any) => value !== '')
          .map(([key, value]: any) => {
            return (
              <p className="proof_body" key={key}>
                {!Array.isArray(value) ? (
                  <span>
                    <strong>{key}:</strong> {value}
                  </span>
                ) : (
                  <>
                    <strong>{key}:</strong>
                    {value.map((v) => {
                      return key === 'productAddons' ? (
                        v.products.map((prods) => {
                          return <i key={v.ID}> {prods.id}; </i>;
                        })
                      ) : key === 'reviews' ? (
                        <i key={v.ID}> {v.user}; </i>
                      ) : (
                        <i key={v.ID}> {v.product_id || v.id}; </i>
                      );
                    })}
                  </>
                )}
              </p>
            );
          })}
    </>
  );
};
