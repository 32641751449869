import { DeleteOutlined } from '@ant-design/icons';
import { Form, Modal } from 'antd';
import { useEffect } from 'react';

import { ImagePicker } from '../../../../common/ImagePicker';

export const ListEditForm = ({ active, onEdit, item, onCancel, pageData, onDeleteItem }: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ ...item });
  }, [item, form]);

  const onFinish = (e: any) => {
    onEdit({ id: item.id, boldText: e.boldText, listText: e.listText, img: e.img });
    form.resetFields();
  };

  return (
    <Modal
      open={active}
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}
    >
      <p
        className="removeProduct"
        onClick={() =>
          Modal.confirm({
            title: `Delete extra product`,
            content: 'Are you sure you want to delete this extra product?',
            onOk: () => onDeleteItem(item),
          })
        }
      >
        <DeleteOutlined /> REMOVE
      </p>
      <Form form={form} className="listForm" layout="vertical" onFinish={onFinish}>
        <Form.Item name="boldText" label="Bolded Text:">
          <textarea id="" cols={30} rows={2}></textarea>
        </Form.Item>
        <Form.Item name="listText" label="Description Text:">
          <textarea id="" cols={30} rows={2}></textarea>
        </Form.Item>
        <Form.Item name="img" label="Image:">
          <ImagePicker funnelId={pageData.funnel_id} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
