import { Button, Col, Row, Table } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';

import { fetchProductsAtom } from '../../../atoms/products';
import { IProduct } from '../../../types';
import { columnSearch, commonFields } from '../../../utils/table';

const ProductsDetailsImplementation = (props: IProduct) => {
  const [product, fetchProducts] = useAtom(fetchProductsAtom);
  const dataID: any = [product.data[Number(props.ID) - 1]];

  useEffect(() => {
    fetchProducts(props.ID);
  }, [fetchProducts, props.ID]);

  const columns = [
    {
      title: 'ID',
      ...commonFields('ID'),
    },
    {
      title: 'Name',
      ...commonFields('name'),
      ...columnSearch('name'),
    },
  ];

  return (
    <div>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Table dataSource={dataID} columns={columns} rowKey="ID" />
        <Row>
          <Col span={4}>
            <Button type="primary">+ Add Product</Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const Product = () => {
  const location = useLocation();
  return !location.state ? (
    <Navigate to={'/products'} replace />
  ) : (
    <ProductsDetailsImplementation {...(location.state as IProduct)} />
  );
};

export default Product;
