import { Form, InputNumber } from 'antd';
import { useEffect } from 'react';

import { ICategory, IFormProps } from '@/types';

export const CategoryImportForm = ({ formData, onFormReady }: IFormProps<ICategory>) => {
  const [form] = Form.useForm();
  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <Form.Item
        label="Insert Category ID from Sticky.io"
        name="sticky_id"
        rules={[
          { required: true, message: 'Missing Category Sticky ID' },
          { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
        ]}
      >
        <InputNumber style={{ width: '100%' }} placeholder="Category Sticky ID" maxLength={5} />
      </Form.Item>
    </Form>
  );
};
