import { atom } from 'jotai';

import { IDashBoard } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<IDashBoard[]>([]);
const isLoading = atom<boolean>(false);

export interface DashboardState {
  data: IDashBoard[];
  isLoading: boolean;
}

export const dashboardAtom = atom<DashboardState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
}));

export const fetchDashBoardAtom = atom(
  (get) => get(dashboardAtom),
  async (_, set) => {
    set(isLoading, true);
    try {
      const response: IDashBoard[] = await $http.get('/api/v1/dashboard').json<IDashBoard[]>();
      const dataDashBoard = response.map((item: any) => {
        return {
          value: ((item.active / item.total) * 100).toFixed(2),
          name: item.name,
        };
      });
      set(dataAtom, dataDashBoard);
      set(isLoading, false);
    } catch (error) {
      set(isLoading, false);
    }
  },
);
