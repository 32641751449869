import {
  DeleteOutlined,
  FunnelPlotOutlined,
  LinkOutlined,
  PlusCircleOutlined,
  PropertySafetyOutlined,
  SafetyCertificateOutlined,
} from '@ant-design/icons';
import { Button, Space, Switch, Table, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import {
  createDomainAtom,
  deleteDomainAtom,
  fetchDomainsAtom,
  groupedDomainsAtom,
  updateBotAttackCFAtom,
} from '../../atoms/domains';
import { openModalAtom } from '../../atoms/modal';
import { IDomain } from '../../types';
import { columnSearch, commonFields, enableSorting } from '../../utils/table';
import { DomainEditForm } from '../Forms';
import PageHeader from '../PageHeader';
import { DeleteButton } from '../UI/DeleteButton';

const Domains = () => {
  const [domains, fetchDomains] = useAtom(fetchDomainsAtom);
  const groupedDomains = useAtomValue(groupedDomainsAtom);
  const [, updateBotAttackCFAPI] = useAtom(updateBotAttackCFAtom);
  const [check, setChecked] = useState(false);
  const [, openModal] = useAtom(openModalAtom);
  const [, deleteDomain] = useAtom(deleteDomainAtom);
  const [, createDomain] = useAtom(createDomainAtom);

  useEffect(() => {
    fetchDomains(true);
  }, [fetchDomains]);

  const toggleChecked = useCallback(() => {
    setChecked(!check);
  }, [check]);

  const confirm = useCallback(
    (domain: IDomain) => {
      deleteDomain(domain?.ID);
    },
    [deleteDomain],
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'Domain',
        ...commonFields('name'),
        ...enableSorting('name'),
        ...columnSearch('name'),
        width: '50%',
        render: (name: any, domain: IDomain & { children: IDomain[] }) => {
          const fullName = [domain.name, domain.domain?.name].filter(Boolean).join('.');
          return (
            <a href={`https://${fullName}`} target="_blank" rel="noreferrer">
              <LinkOutlined /> {fullName} {domain.children?.length ? `(${domain.children.length})` : ''}
            </a>
          );
        },
      },
      {
        title: 'Funnel',
        ...commonFields('funnel.name'),
        ...enableSorting('funnel.name'),
        ...columnSearch('funnel.name'),
        render: (_: any, domain: IDomain) =>
          domain.funnel && (
            <NavLink to={`/funnels/${domain.funnel.ID}/domains`}>
              <FunnelPlotOutlined /> {domain.funnel.name}
            </NavLink>
          ),
      },
      {
        title: 'Action',
        render: (_: any, domain: IDomain & { children: IDomain[] }) => (
          <>
            <Space>
              {!domain.domain_id && (
                <Tooltip placement="topLeft" key="switch" title="Toggle bot attack">
                  <Switch
                    checked={domain?.under_attack}
                    checkedChildren={<SafetyCertificateOutlined />}
                    unCheckedChildren={<PropertySafetyOutlined />}
                    onChange={toggleChecked}
                    onClick={() => {
                      updateBotAttackCFAPI(domain?.ID, { ID: domain.ID, under_attack: check });
                    }}
                  />
                </Tooltip>
              )}
              {!domain.funnel_id && !domain.children?.length && (
                <DeleteButton key="delete" onConfirm={() => confirm(domain)} shape="circle" icon={<DeleteOutlined />} />
              )}
            </Space>
          </>
        ),
      },
    ];
  }, [toggleChecked, check, confirm, updateBotAttackCFAPI]);

  return (
    <>
      <PageHeader
        title="Domains"
        extra={[
          <Button
            type="primary"
            onClick={() => {
              openModal({
                title: 'Add domain',
                component: DomainEditForm,
                onSave: (values: IDomain) => createDomain(values),
              });
            }}
            key="add"
          >
            <PlusCircleOutlined /> Add Domain
          </Button>,
        ]}
      />
      <Content>
        <Table
          dataSource={groupedDomains}
          columns={columns as any}
          rowKey="ID"
          pagination={{ showSizeChanger: true }}
          loading={domains.isLoading}
        />
      </Content>
    </>
  );
};
export default Domains;
