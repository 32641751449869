import { CloseCircleOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { updatePageAtom } from '../../atoms/pages';
import { IFormProps } from '../../types';
import { IPage } from '../../types';

export const RedirectEditForm = ({ formData, onFormReady }: IFormProps<IPage>) => {
  const [, updatePage] = useAtom(updatePageAtom);
  const [form] = Form.useForm();

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  const onRemove = () => {
    formData &&
      updatePage(formData.ID, {
        ...formData,
        meta: {
          ...formData.meta,
          redirect: {
            path: '',
            ef: '',
          },
        },
      });
  };
  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <p>
        Currently redirected to: <br />
        {!!formData && !!formData.meta && !!formData.meta.redirect && formData.meta.redirect.path !== '' && (
          <>
            <span style={{ color: 'gray' }}>{formData.meta.redirect.path}</span>{' '}
            <CloseCircleOutlined onClick={onRemove} />
          </>
        )}
      </p>
      <Form.Item name="path" label={`Path`}>
        <Input placeholder="Input Page Path" addonBefore={`URL`} />
      </Form.Item>
      <Form.Item name="ef" label={`Everflow`}>
        <InputNumber placeholder="Input Page Path" addonBefore={`?C1=XX&oid=XX`} />
      </Form.Item>
      <Form.Item
        hidden
        name="funnel_id"
        label="Insert Funnel Offer Id"
        rules={[{ required: true, message: 'Please input Insert Funnel Offer Id' }]}
      >
        <Input placeholder="Input Funnel name" />
      </Form.Item>
    </Form>
  );
};
