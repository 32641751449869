import { Form, Input, InputNumber } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { closeModalAtom } from '@/atoms/modal';
import { createPageAtom } from '@/atoms/pages';
import { IFormProps, IPage } from '@/types';

export const PageCloneForm = ({ formData, funnelId, onFormReady }: IFormProps<IPage>) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [, createPage] = useAtom(createPageAtom);
  const [form] = Form.useForm();
  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  const onCreate = (values) => {
    let dataToSend = { ...formData };
    delete dataToSend.ID;
    createPage({ ...dataToSend, ...values });
    closeModal();
    form.resetFields();
  };

  return (
    <Form form={form} onFinish={onCreate} layout="vertical">
      <Form.Item
        label="Page Name"
        name="name"
        initialValue={formData?.name + ' - Copy'}
        rules={[{ required: true, message: 'Missing Name' }]}
      >
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item name="funnel_id" initialValue={funnelId} label="Funnel ID">
        <InputNumber />
      </Form.Item>
    </Form>
  );
};
