import {
  ApiOutlined,
  DeleteOutlined,
  EditFilled,
  EyeOutlined,
  LockTwoTone,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Select, Space, Table, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAtom, useSetAtom } from 'jotai';
import type { AlignType } from 'rc-table/es/interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { createFunnelAtom, deleteFunnelAtom, fetchFunnelsAtom, updateFunnelAtom } from '@/atoms/funnels';
import { openModalAtom } from '@/atoms/modal';
import { IFunnel } from '@/types';
import { columnSearch, commonFields, enableSorting, SortOrder } from '@/utils/table';

import { FunnelCreateForm, FunnelQuickBuildForm } from '../Forms';
import Loading from '../Loading';
import PageHeader from '../PageHeader';
import { DeleteButton } from '../UI/DeleteButton';
import Details from './Funnel/Details';

const Funnels = () => {
  const navigate = useNavigate();
  const openModal = useSetAtom(openModalAtom);
  const deleteFunnel = useSetAtom(deleteFunnelAtom);
  const createFunnel = useSetAtom(createFunnelAtom);
  const [funnels, fetchFunnels] = useAtom(fetchFunnelsAtom);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [detailData, setDetailData] = useState('') as any;
  const updateFunnel = useSetAtom(updateFunnelAtom);

  useEffect(() => {
    fetchFunnels();
  }, [fetchFunnels]);

  const showDrawer = useCallback(
    (id: any) => {
      setVisibleDrawer(true);
      funnels.data.forEach((v: any) => {
        if (v.ID === id) {
          setDetailData(v);
        }
      });
    },
    [funnels],
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'ID',
        ...commonFields('ID'),
        ...enableSorting('ID', SortOrder.DESCEND),
      },
      {
        title: 'Name',
        ...commonFields('name'),
        ...columnSearch('name'),
        render: (_: any, funnel: IFunnel) => (
          <>
            {!!funnel?.locked && (
              <Tooltip title="Funnel is locked!">
                <LockTwoTone twoToneColor="#f60"></LockTwoTone>
              </Tooltip>
            )}
            {!!funnel && <Link to={`/funnels/${funnel.ID}/domains`}>{funnel.name}</Link>}
          </>
        ),
      },
      {
        title: 'Offer',
        ...commonFields('offer.name'),
        ...columnSearch('offer.name'),
        render: (_: any, funnel: IFunnel) => (!funnel.offer?.name ? funnel.offer_name : funnel.offer?.name),
      },
      {
        title: 'Platform',
        ...commonFields('offer.platform'),
        ...columnSearch('offer.platform'),
        render: (_: any, funnel: IFunnel) => funnel.offer?.platform,
      },
      {
        title: 'Everflow OID',
        ...commonFields('offer.ef_offer_id'),
        ...columnSearch('offer.ef_offer_id'),
        render: (_: any, funnel: IFunnel) => funnel.offer?.ef_offer_id,
      },
      {
        title: 'Status',
        ...commonFields('publish_status'),
        render: (_: any, funnel: IFunnel) => {
          const handleChange = (value: string) => {
            if (funnel.ID !== undefined) {
              updateFunnel(funnel.ID, { ...funnel, publish_status: value });
            }
          };
          let bgclr =
            funnel.publish_status === 'qc' ? '#FFFF00' : funnel.publish_status === 'released' ? '#00CC00' : '#FFC000';
          return (
            <Select
              value={funnel.publish_status}
              style={{ width: 120, backgroundColor: bgclr }}
              onChange={handleChange}
              options={[
                { value: 'progress', label: 'Progress' },
                { value: 'qc', label: 'QC' },
                { value: 'released', label: 'Released' },
              ]}
            ></Select>
          );
        },
      },
      {
        title: 'Action',
        align: 'center' as AlignType,
        render: (_: any, funnel: IFunnel) => (
          <Space key={funnel.ID}>
            <Tooltip placement="topLeft" title="Funnel Edit">
              <Button shape="circle" icon={<EditFilled />} onClick={() => navigate(`/funnels/${funnel.ID}/domains`)} />
            </Tooltip>
            <Tooltip placement="topLeft" title=" View">
              <Button shape="circle" onClick={() => showDrawer(funnel.ID)} icon={<EyeOutlined />} />
            </Tooltip>
            <DeleteButton
              disabled={funnel?.locked}
              onConfirm={() => deleteFunnel(funnel.ID)}
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Space>
        ),
      },
    ];
  }, [deleteFunnel, navigate, showDrawer]);

  return (
    <>
      <PageHeader
        title="Funnels"
        extra={[
          <Button
            style={{ color: '#fff', background: '#2aa423e6' }}
            onClick={() => {
              openModal({ component: FunnelQuickBuildForm, data: null });
            }}
            key="quick"
          >
            <ApiOutlined /> Add Funnel Beta
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              openModal({
                title: 'Create Funnel',
                component: FunnelCreateForm,
                data: null,
                onSave: (values: IFunnel) => createFunnel(values).then(({ ID }) => navigate(`/funnels/${ID}/domains`)),
              });
            }}
            key="add"
          >
            <PlusCircleOutlined /> Add funnel
          </Button>,
        ]}
      />
      <Content>
        {funnels.isLoading ? <Loading /> : <Table dataSource={funnels.data} columns={columns as any} rowKey="ID" />}
      </Content>
      <Drawer
        rootClassName="drawerr"
        height={200}
        title="Funnel Details:"
        placement="right"
        onClose={() => setVisibleDrawer(false)}
        open={visibleDrawer}
      >
        <Details funnel={detailData} id={undefined} />
      </Drawer>
    </>
  );
};

export default Funnels;
