import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, message, Tooltip } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { fetchOffersAtom } from '@/atoms/offers';
import { offer } from '@/atoms/quickBuild';
import { IOffer } from '@/types';

export const Offers = () => {
  const [offers, fetchOffers] = useAtom(fetchOffersAtom);
  const [offerAtom, setOfferAtom] = useAtom(offer);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  const onFinish = (values: IOffer) => {
    let haveIt = offers.data.find((offer) => offer.offer_id === values.sticky_offer_id);
    if (haveIt) {
      message.info(`Offer [${values.sticky_offer_id}] already exists`);
      return;
    }
    setOfferAtom({
      ef_offer_id: values.ef_offer_id,
      offer_id: values.sticky_offer_id,
      platform: 'sticky',
      sticky_offer_id: values.sticky_offer_id,
    } as IOffer);

    form.resetFields();
  };

  const onDelete = () => {
    setOfferAtom(undefined);
  };

  return (
    <div className="funnelCreate">
      <div className="currentFunnel">
        {!!offerAtom ? (
          <>
            <p style={{ fontWeight: 'bold' }}>ADDED OFFER</p>
            <div className="currentFunneldiv">
              <div className="flexBetween">
                <p className="domainname">
                  ID: {offerAtom.offer_id} <br />
                  Everflow: {offerAtom.ef_offer_id}
                </p>
                <div>
                  <Tooltip title="Delete Offer">
                    <DeleteOutlined className="deleteBtn" onClick={onDelete} />
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        ) : (
          <h4 className="noThxAdd">No Offer added</h4>
        )}
      </div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Sticky Offer ID"
          name="sticky_offer_id"
          rules={[{ pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers', required: true }]}
        >
          <InputNumber style={{ width: '100%' }} placeholder="Sticky Offer ID" maxLength={5} />
        </Form.Item>
        <Form.Item
          name="ef_offer_id"
          label="Everflow Offer Id"
          rules={[
            { required: true, message: 'Please input Everflow Offer Id' },
            { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
          ]}
        >
          <InputNumber style={{ width: '100%' }} maxLength={5} placeholder="Everflow Offer ID" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
