import { Button } from 'antd';
import { useState } from 'react';

import RewardsModal from './RewardsModal';

interface ParentProps {
  listChange: any;
  pendingSaveData: any;
}

const Rewards = ({ listChange, pendingSaveData }: ParentProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleVisible = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div className="rewards">
      <Button onClick={handleVisible} className="rewardsButton">
        Special Rewards
      </Button>
      {!!pendingSaveData && !!pendingSaveData.meta && !!pendingSaveData.meta.productId_cc && (
        <>
          <p>Credit Card Id:{pendingSaveData.meta.productId_cc}</p>
          <p>Paypal Id: {pendingSaveData.meta.productId_pp}</p>
        </>
      )}
      <RewardsModal handleVisible={handleVisible} visible={isModalVisible} listChange={listChange} />
    </div>
  );
};

export default Rewards;
