import { Checkbox, Form, Input, Select, Space } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { fetchDomainsAtom } from '../../atoms/domains';
import { IFormProps } from '../../types';
import { IDomain } from '../../types';

export const DomainEditForm = ({ funnelId, formData, onFormReady }: IFormProps<IDomain>) => {
  const [form] = Form.useForm();
  const [domainsAtom, fetchDomains] = useAtom(fetchDomainsAtom);

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  useEffect(() => {
    fetchDomains();
  }, [fetchDomains]);

  const validationPattern = funnelId
    ? new RegExp(/^\w[\w.-]+\w$/i)
    : new RegExp(/^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/);

  return (
    <Form form={form} layout="vertical" initialValues={{ ...formData, funnel_id: funnelId }}>
      <Form.Item label={funnelId ? 'Subdomain name' : 'Domain name'}>
        <Space style={{ display: 'flex', width: '100%' }}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Please enter a valid Sufix for domain',
              },
              {
                pattern: validationPattern,
                message: 'Please enter a valid domain exp domain.com',
              },
            ]}
            style={{ flex: 1 }}
          >
            <Input placeholder="Input Domain URL" addonBefore="https://" />
          </Form.Item>
          {funnelId && (
            <>
              <Form.Item hidden name="funnel_id">
                <Input />
              </Form.Item>
              <Form.Item
                name="domain_id"
                rules={[
                  {
                    required: true,
                    message: 'Please select domain',
                  },
                ]}
                style={{ minWidth: '100px' }}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                  options={domainsAtom.data.map(({ ID, name }) => {
                    return { value: ID, label: name };
                  })}
                />
              </Form.Item>
            </>
          )}
        </Space>
      </Form.Item>
      {!funnelId && (
        <Form.Item
          name="sync"
          valuePropName="checked"
          help={<span style={{ color: 'red' }}>Domain must be created on namecheap.com first</span>}
        >
          <Checkbox>Create/update domain on CloudFlare</Checkbox>
        </Form.Item>
      )}
    </Form>
  );
};
