import { Table } from 'antd';
import { useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { fetchScrubsAtom } from '../../../atoms/scrub';
import { IRouteParams } from '../../../types';
import { columnSearch, commonFields } from '../../../utils/table';
import Loading from '../../Loading';

const Scrub = () => {
  const { id }: IRouteParams = useParams();
  const [scrubs, fetchScrubs] = useAtom(fetchScrubsAtom);

  useEffect(() => {
    fetchScrubs({ funnel_id: scrubs.data[0].funnel_id });
  }, [fetchScrubs, scrubs.data]);

  const scrubsById: any = [scrubs.data[Number(id) - 1]];

  const columns = useMemo(() => {
    return [
      {
        title: 'Funnel_id',
        ...commonFields('funnel_id'),
        ...columnSearch('funnel_id'),
      },
      {
        title: 'affiliate_id',
        ...commonFields('affiliate_id'),
        ...columnSearch('affiliate_id'),
      },
      {
        title: 'scrub',
        ...commonFields('scrub_pct'),
        ...columnSearch('scrub_pct'),
      },
      {
        title: 'affiliate_name',
        ...commonFields('affiliate_name'),
        ...columnSearch('affiliate_name'),
      },
    ];
  }, []);

  if (scrubs.isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Table dataSource={scrubsById} columns={columns} rowKey="ID" />
      </div>
    </div>
  );
};

export default Scrub;
