import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { fetchCategoriesAtom } from '@/atoms/categories';
import { IFormProps, IProduct } from '@/types';

export const ProductsAddForm = ({ formData, onFormReady }: IFormProps<IProduct>) => {
  const [form] = Form.useForm();
  const [categories, fetchCategories] = useAtom(fetchCategoriesAtom);

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <h3 style={{ marginBottom: 20 }}>Create Product</h3>
      <Form.Item name="category_id" label="Chose category Name" rules={[{ required: true }]}>
        <Select placeholder="Category Name">
          {categories.data.map((cat) => (
            <Select.Option key={cat.ID} value={cat.ID}>
              {cat.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.List name="products">
        {(fields, { add, remove }) => (
          <div style={{ overflowY: 'scroll', maxHeight: 400, paddingRight: 12 }}>
            {fields.map(({ key, name }) => (
              <Space
                key={key}
                style={{
                  display: 'flex',
                  marginBottom: 8,
                  borderBottomStyle: 'solid',
                  borderBottomColor: 'red',
                }}
                align="baseline"
              >
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      name={[name, 'product_name']}
                      rules={[{ required: true, message: 'Missing Product Name' }]}
                    >
                      <Input placeholder="Product Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={[name, 'product_description']}
                      rules={[{ required: true, message: 'Missing Product Description' }]}
                    >
                      <Input placeholder="Product Description" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={[name, 'product_sku']}
                      rules={[{ required: true, message: 'Missing Product SKU' }]}
                    >
                      <Input placeholder="Product Sku" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={[name, 'vertical_id']}
                      rules={[{ required: false, message: 'Missing Vertical' }]}
                      initialValue={'16'}
                    >
                      <Select placeholder="Vertical" labelInValue allowClear>
                        <Select.Option value="16">Home & Garden</Select.Option>
                        <Select.Option value="23">Skin Care</Select.Option>
                        <Select.Option value="24">Sports & Fitness</Select.Option>
                        <Select.Option value="25">Teeth Whitening</Select.Option>
                        <Select.Option value="9">Entertainment, Recreation & Leisure</Select.Option>
                        <Select.Option value="15">Hair</Select.Option>
                        <Select.Option value="10">Family</Select.Option>
                        <Select.Option value="8">Education</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={[name, 'cost_of_goods_sold']}
                      rules={[{ required: true, message: 'Missing Cost of Goods' }]}
                    >
                      <Input placeholder="Cost Of goods" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={[name, 'product_price']}
                      rules={[{ required: true, message: 'Missing Product Price' }]}
                    >
                      <Input placeholder="Product Price USD" addonAfter="$" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={[name, 'shipping_weight']}
                      rules={[{ required: true, message: 'Missing Shipping Weight' }]}
                    >
                      <Input placeholder="Shipping Weight" addonAfter=".OZ" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name={[name, 'product_max_quantity']}
                      rules={[{ required: false, message: 'Missing Max Quantity' }]}
                      initialValue={'100'}
                    >
                      <Input placeholder="Max quantity" />
                    </Form.Item>
                  </Col>
                </Row>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add Product
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
    </Form>
  );
};
