import { DeleteOutlined, EditFilled, FormOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Table, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAtom, useSetAtom } from 'jotai';
import type { AlignType } from 'rc-table/es/interface';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { openModalAtom } from '@/atoms/modal';
import { createOfferAtom, deleteOfferAtom, fetchOffersAtom, importOfferAtom, updateOfferAtom } from '@/atoms/offers';
import { IOffer } from '@/types';
import { columnSearch, commonFields, enableSorting, SortOrder } from '@/utils/table';

import { OfferEditForm, OffersAddForm, OffersImportForm } from '../Forms';
import PageHeader from '../PageHeader';

const Offers = () => {
  const openModal = useSetAtom(openModalAtom);
  const deleteOffer = useSetAtom(deleteOfferAtom);
  const createOffer = useSetAtom(createOfferAtom);
  const importOffer = useSetAtom(importOfferAtom);
  const updateOffer = useSetAtom(updateOfferAtom);
  const [offers, fetchOffers] = useAtom(fetchOffersAtom);
  const navigate = useNavigate();

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  const columns = [
    {
      title: 'ID',
      ...commonFields('ID'),
      ...enableSorting('ID', SortOrder.DESCEND),
    },
    {
      title: 'Offer ID',
      ...commonFields('offer_id'),
      ...enableSorting('offer_id'),
      ...columnSearch('offer_id'),
    },
    {
      title: 'EF ID',
      ...commonFields('ef_offer_id'),
      ...enableSorting('ef_offer_id'),
      ...columnSearch('ef_offer_id'),
    },
    {
      title: 'Name',
      ...commonFields('name'),
      ...columnSearch('name'),
    },
    {
      title: 'Platform',
      ...commonFields('platform'),
      ...columnSearch('platform'),
    },
    {
      title: 'Store Name',
      ...commonFields('store.name'),
      ...columnSearch('store.name'),
      render: (_: any, offer: IOffer) => offer.store?.name.toUpperCase(),
    },
    {
      title: 'Action',
      align: 'center' as AlignType,
      ...commonFields('action'),
      render: (_: any, offer: IOffer) => (
        <Space key={offer.ID}>
          <Tooltip placement="topLeft" title="Edit Offer">
            <Button shape="circle" icon={<EditFilled style={{ color: 'green' }}/>} onClick={() => navigate(`/offer/${offer.ID}/funnels`)} />
          </Tooltip>
          <Tooltip>
          <Button
              shape="circle"
              icon={<FormOutlined  style={{ color: 'blue' }}/>}
              onClick={() => {
                openModal({
                  component: OfferEditForm,
                  data: offer,
                  funnelId: offer.ID,
                  onSave: (values: IOffer[]) => {
                    return updateOffer(offer.ID, {
                      ...values,
                    });
                  },
                });
              }}
              key={offer.ID}
            />
          </Tooltip>
          <Popconfirm
            title="Are you sure you want to remove?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteOffer(offer.ID)}
          >
            <Button shape="circle" icon={<DeleteOutlined style={{ color: 'red' }}/>} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <>
      <PageHeader
        title="Offers"
        extra={[
          <Button
            type="primary"
            onClick={() => {
              openModal({
                title: 'Create Offer / Campaign',
                component: OffersAddForm,
                onSave: (values) =>
                  createOffer({
                    name: values.name + values.suffix,
                    ...values,
                  }),
              });
            }}
            key="create"
          >
            <PlusCircleOutlined /> Create Offer / Campaign
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              openModal({
                title: 'Import Offers',
                component: OffersImportForm,
                onSave: (values) => importOffer(values),
              });
            }}
            key="import"
          >
            <PlusCircleOutlined /> Import Offer
          </Button>,
        ]}
      />
      <Content>
        <Table dataSource={offers.data} rowKey="ID" columns={columns as any} />
      </Content>
    </>
  );
};
export default Offers;
