import {
  CodeOutlined,
  CodepenOutlined,
  DoubleLeftOutlined,
  FormatPainterOutlined,
  LeftOutlined,
  SaveOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Form, Space, Tabs, Tooltip } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Content } from 'antd/es/layout/layout';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Navigate, useNavigate } from 'react-router-dom';

import { openModalAtom } from '../../../atoms/modal';
import { fetchPageAtom, fetchPagesAtom, resetPageAtom, updateMetaAtom, updatePageAtom } from '../../../atoms/pages';
import { fetchProductsAtom } from '../../../atoms/products';
import { IRouteParams } from '../../../types';
import { ITemplate } from '../../../types';
import { CodeEditorForm } from '../../Forms';
import Loading from '../../Loading';
import PageHeader from '../../PageHeader';
import CheckoutListMenu from './CheckoutListMenu';
import { DeviceType, EditDevice } from './CheckoutListMenu/EditDevice/EditDevice';
import EditExtraReview from './CheckoutListMenu/EditExtraReviews/Index';
import { EditExtras } from './CheckoutListMenu/EditExtras/Index';
import EditFrames from './CheckoutListMenu/EditFrames';
import EditList from './CheckoutListMenu/EditLists/Index';
import EditReview from './CheckoutListMenu/EditReviews/Index';
import EditShipping from './CheckoutListMenu/EditShipping/Index';
import EditSliderImages from './CheckoutListMenu/EditSliderImages/Index';
import { TemplateImageEdit } from './CheckoutListMenu/ImageEdit';
import Rewards from './CheckoutListMenu/Rewards';
import IFrameEditor from './IframeEditor';

export const getPageType = ({ page_type }: ITemplate) => page_type.split('.').pop();

export const TemplateEditor = ({ quickPageItem }: { quickPageItem?: ITemplate }) => {
  const navigate = useNavigate();
  const { id }: IRouteParams = useParams();
  const openModal = useSetAtom(openModalAtom);
  const updateMeta = useSetAtom(updateMetaAtom);
  const updatePage = useSetAtom(updatePageAtom);
  const fetchPages = useSetAtom(fetchPagesAtom);
  const resetState = useSetAtom(resetPageAtom);
  const [product, fetchProducts] = useAtom(fetchProductsAtom);
  const [visible, setVisible] = useState(false);
  const [deviceType, setDeviceType] = useState(DeviceType.DESKTOP);
  const [iframeDocument, setIFrameDocument] = useState<Document | null>(null);
  const [editableImages, setEditableImages] = useState<Array<{ key: string; el: HTMLElement }>>([]);
  const [page, fetchPage] = useAtom(fetchPageAtom);
  const [tabItems, setTabItems] = useState<any>([]);

  useEffect(() => {
    if (id) {
      fetchPage(id);
    }
    return () => resetState();
  }, [id, fetchPage, resetState]);

  useEffect(() => {
    if (!page?.data?.funnel_id) return;
    fetchProducts(page.data.funnel_id);
    fetchPages(page.data.funnel_id);
  }, [fetchPages, fetchProducts, page.data?.funnel_id]);

  useEffect(() => {
    if (!page.data) return;

    setTabItems(
      [
        {
          key: 'Extras',
          label: 'Extras',
          children: <EditExtras pendingSaveData={page.data} listChange={(list: any) => updateMeta(list)} />,
        },
        page.data.template?.meta?.intShipping && {
          key: 'Shipping',
          label: 'Shipping',
          children: <EditShipping pendingSaveData={page.data} listChange={(list: any) => updateMeta(list)} />,
        },
        page.data.template?.meta?.productId_pp && {
          key: 'Rewards',
          label: 'Rewards',
          children: <Rewards pendingSaveData={page.data} listChange={(list: any) => updateMeta(list)} />,
        },
        (page.data.template?.meta?.products || page.data.template?.meta?.productAddons) && {
          key: 'products',
          label: 'Products',
          children: (
            <CheckoutListMenu
              editorProps={page.data}
              template={page.data.template}
              iframeDocument={iframeDocument}
              product={product.data}
              pageData={page.data}
              pendingSaveData={page.data}
              listChange={(data: any) => updateMeta(data)}
            />
          ),
        },
        {
          key: 'images',
          label: 'Images',
          children: page.data && (
            <>
              <TemplateImageEdit
                funnelId={page.data.funnel_id ?? 0}
                onChange={(field: any) => updateMeta(field)}
                pendingSaveData={page.data}
                editableImages={editableImages}
              />
              {page.data.template?.meta?.imgSlider && (
                <>
                  <p className="imageList_p">Image Slider:</p>
                  <EditSliderImages
                    listChange={(list: any) => updateMeta(list)}
                    pendingSaveData={page.data}
                    iframeDocument={iframeDocument}
                    pageData={page.data}
                  />
                </>
              )}
            </>
          ),
        },
        page.data.template?.meta?.reviews && {
          key: 'reviews',
          label: 'Customer Reviews',
          children: (
            <EditReview
              listChange={(list: any) => updateMeta(list)}
              savedFields={page.data}
              iframeDocument={iframeDocument}
              pageData={page.data}
            />
          ),
        },
        page.data.template?.meta?.extraReviews && {
          key: 'extraReviews',
          label: 'Extra Reviews',
          children: (
            <EditExtraReview
              listChange={(list: any) => updateMeta(list)}
              savedFields={page.data}
              iframeDocument={iframeDocument}
              pageData={page.data}
            />
          ),
        },
        page.data.template?.meta?.list && {
          key: 'lists',
          label: 'Lists',
          children: (
            <EditList
              listChange={(list: any) => updateMeta(list)}
              savedFields={page.data}
              iframeDocument={iframeDocument}
              pageData={page.data}
            />
          ),
        },
        {
          key: 'meta',
          label: (
            <span>
              <CodepenOutlined />
              Meta
            </span>
          ),
          children: (
            <Form
              layout="inline"
              fields={[{ name: 'meta', value: JSON.stringify(page.data.meta, null, 2) }]}
              style={{ width: '100%' }}
              onFieldsChange={(changedFields) => {
                const meta = JSON.parse(changedFields[0].value);
                updateMeta(meta);
              }}
            >
              <Form.Item
                name="meta"
                rules={[{ required: true, message: 'Meta can not be empty!' }]}
                style={{ width: '100%' }}
              >
                <TextArea rows={15} style={{ width: '100%' }} />
              </Form.Item>
            </Form>
          ),
        },
      ].filter(Boolean),
    );
  }, [editableImages, iframeDocument, page.data, product.data, updateMeta]);

  const saveData = () => {
    page.data?.ID && updatePage(page.data.ID, page.data);
  };

  if (!id && !quickPageItem) return <Navigate to={'/funnels'} replace />;

  return page.isLoading ? (
    <Loading />
  ) : (
    page.data && (
      <>
        <PageHeader
          title="Template editor"
          subTitle={page.data.page_path}
          extra={[
            !quickPageItem && (
              <Button
                onClick={() =>
                  openModal({
                    title: 'Edit custom CSS',
                    component: CodeEditorForm,
                    data: {
                      language: 'css',
                      code: (page?.data?.meta?.css || '').replace('<style>', '').replace('</style>', ''),
                    },
                    onSave: ({ code }) => {
                      updateMeta({ css: `<style>${code}</style>` });
                      return Promise.resolve();
                    },
                  })
                }
                key="css"
              >
                <FormatPainterOutlined /> CSS
              </Button>
            ),
            !quickPageItem && (
              <Button
                onClick={() =>
                  openModal({
                    title: 'Edit custom JS',
                    component: CodeEditorForm,
                    data: {
                      language: 'javascript',
                      code: (page?.data?.meta?.customJs || '').replace('<script>', '').replace('</script>', ''),
                    },
                    onSave: ({ code }) => {
                      updateMeta({ customJs: `<script>${code}</script>` });
                      return Promise.resolve();
                    },
                  })
                }
                key="js"
              >
                <CodeOutlined /> Javascript
              </Button>
            ),
            !quickPageItem && (
              <Button onClick={() => navigate(-1)} key="back">
                <LeftOutlined /> Back
              </Button>
            ),
            <Button type="primary" onClick={saveData} key="save">
              <SaveOutlined /> Save
            </Button>,
          ]}
        />
        <Content id="template-editor">
          <>
            {!quickPageItem && (
              <EditDevice
                deviceType={deviceType}
                onChange={({ device }: { device: DeviceType }) => {
                  setDeviceType(device);
                }}
              />
            )}
            {page.data?.template?.meta?.main_color && (
              <EditFrames
                listChange={(list: any) => updateMeta(list)}
                savedFields={page.data.meta}
                pageData={page.data}
              />
            )}
            <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
              {!!page.data?.template?.template_host_url && (
                <IFrameEditor
                  funnelId={page.data.funnel_id}
                  remoteUrl={page.data?.template.template_host_url ?? ''}
                  deviceType={deviceType}
                  onChange={(args: any) => updateMeta({ [args.key]: args.value })}
                  onDocumentLoaded={(doc: Document, editableImages: Array<{ key: string; el: HTMLElement }>) => {
                    setIFrameDocument(doc);
                    setEditableImages(editableImages);
                  }}
                />
              )}
            </div>
          </>
          {!visible && (
            <Tooltip placement="topLeft" title={'Edit'}>
              <DoubleLeftOutlined onClick={() => setVisible(true)} className="sidebarIcon" />
            </Tooltip>
          )}
          <Drawer
            title={
              <Space>
                <Button shape="circle" icon={<StepForwardOutlined />} onClick={() => setVisible(false)} />
                {page.data?.name}
              </Space>
            }
            placement="right"
            closable={false}
            open={visible}
            getContainer={false}
            mask={false}
          >
            <Tabs items={tabItems} />
          </Drawer>
        </Content>
      </>
    )
  );
};

export const TemplateQuickEditor = ({ quickPageItem }) => {
  const [page, fetchPage] = useAtom(fetchPageAtom);

  useEffect(() => {
    fetchPage(quickPageItem.ID);
  }, [fetchPage, quickPageItem.ID]);

  if (page.isLoading) return <Loading />;

  return <TemplateEditor quickPageItem={!!quickPageItem && quickPageItem} />;
};

export default TemplateEditor;
