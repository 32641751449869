import { LockTwoTone } from '@ant-design/icons';
import { Col, Row, Space, Tooltip } from 'antd';

const PageHeader = ({
  title,
  subTitle,
  extra,
  footer,
  locked,
}: {
  title: string;
  subTitle?: string;
  extra?: React.ReactNode[];
  footer?: React.ReactNode;
  locked?: boolean;
}) => {
  return (
    <div
      style={{ background: '#f5f5f5', paddingInline: '16px', paddingTop: '10px', paddingBottom: footer ? 0 : '10px' }}
    >
      <Row>
        <Col flex="auto">
          <Space>
            {!!locked && (
              <Tooltip title="Funnel is locked!">
                <LockTwoTone twoToneColor="#f60"></LockTwoTone>
              </Tooltip>
            )}
            <span style={{ fontWeight: '600', fontSize: '20px', lineHeight: '32px' }}>{title}</span>
            <span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: '14px', lineHeight: '32px' }} title={subTitle}>
              {subTitle}
            </span>
          </Space>
        </Col>
        <Col>
          {extra && (
            <Space>
              {extra.map((item, i) => (
                <span key={i}>{item}</span>
              ))}
            </Space>
          )}
        </Col>
      </Row>
      {footer && <div style={{ marginTop: '16px' }}>{footer}</div>}
    </div>
  );
};

export default PageHeader;
