import { Form, Input, InputNumber, Select } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { fetchCategories29NextAtom } from '../../atoms/categories';
import { fetchStoresAtom } from '../../atoms/stores';
import { IFormProps } from '../../types';
import { IOffer } from '../../types';

enum Platform {
  Sticky = 'sticky',
  Next = '29next',
}

export const OffersImportForm = ({ formData, onFormReady }: IFormProps<IOffer>) => {
  const [stores, fetchStores] = useAtom(fetchStoresAtom);
  const [categories, fetchCategories29Next] = useAtom(fetchCategories29NextAtom);
  const [form] = Form.useForm();
  const platform = Form.useWatch('platform', form);
  const platformOptions = [
    { label: 'Sticky', value: Platform.Sticky },
    { label: '29Next', value: Platform.Next },
  ];

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  useEffect(() => {
    fetchStores();
  }, [fetchStores]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item name="platform" label="Select Platform" rules={[{ required: true }]}>
        <Select placeholder="Select Platform" options={platformOptions}></Select>
      </Form.Item>
      {platform === Platform.Next && (
        <Form.Item
          name="store_id"
          label="Select Store"
          rules={[{ required: true, message: 'You have to select store' }]}
        >
          <Select
            placeholder="Select Store"
            onChange={(storeId) => {
              fetchCategories29Next(storeId);
            }}
            options={stores.data.map(({ ID, name }) => ({ value: ID, label: name }))}
          ></Select>
        </Form.Item>
      )}
      <Form.Item
        name="offer_id"
        label={platform === Platform.Next ? '29 Next Category ID' : 'Sticky Offer ID'}
        rules={[{ required: true, message: 'This field is required' }]}
      >
        {platform === Platform.Next ? (
          <Select placeholder="Select Category ID">
            {!!categories.data &&
              categories.data.map((category) => (
                <Select.Option value={category.ID} key={category.ID}>
                  {category.name} -- ({category.ID})
                </Select.Option>
              ))}
          </Select>
        ) : (
          <InputNumber
            style={{ width: '100%' }}
            placeholder="Sticky Offer ID"
            maxLength={5}
            disabled={!!formData?.ID}
          />
        )}
      </Form.Item>
      {!!formData?.ID && (
        <Form.Item label="Offer Name" name="name" rules={[{ required: true, message: 'Missing Offer Name' }]}>
          <Input style={{ width: '100%' }} maxLength={5} placeholder="Offer name" disabled />
        </Form.Item>
      )}
      <Form.Item
        name="ef_offer_id"
        label="Everflow Offer Id"
        rules={[
          { required: false, message: 'Please input Everflow Offer Id' },
          { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
        ]}
      >
        <InputNumber style={{ width: '100%' }} maxLength={5} placeholder="Everflow Offer ID" />
      </Form.Item>
    </Form>
  );
};
