import { FormInstance, Modal } from 'antd';
import { useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';

import { closeModalAtom, modalAtom } from '@/atoms/modal';

const FormModal = () => {
  const modalData = useAtomValue(modalAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const [formInstance, setFormInstance] = useState<FormInstance>();
  const [onSave, setSaving] = useState(false);

  const FormComponent = modalData.params.component;

  return (
    <Modal
      open={modalData.isOpen}
      centered
      {...(!!modalData.params.onSave ? null : { footer: null })}
      keyboard={false}
      maskClosable={false}
      confirmLoading={onSave}
      title={modalData.params.title}
      okText="Save"
      cancelText="Cancel"
      okButtonProps={{ autoFocus: true }}
      className={modalData.params.class}
      destroyOnClose
      onCancel={() => closeModal()}
      onOk={async () => {
        try {
          const values = await formInstance?.validateFields();
          if (modalData.params.onSave) {
            setSaving(true);
            modalData.params
              .onSave(values)
              .then(() => {
                formInstance?.resetFields();
                closeModal();
              })
              .finally(() => setSaving(false));
          } else {
            formInstance?.resetFields();
            closeModal();
          }
        } catch (error) {}
      }}
    >
      {!!FormComponent && (
        <FormComponent
          formData={modalData.params.data}
          funnelId={modalData.params.funnelId}
          modalClass={modalData.params.class}
          updateState={modalData.params.updateState}
          onFormReady={(instance) => {
            setFormInstance(instance);
          }}
        />
      )}
    </Modal>
  );
};

export default FormModal;
