import { atom } from 'jotai';

import { IPagination } from '../types';
import { IOrder } from '../types';
import { $http } from '../utils/http';
import { paramsAtom, totalAtom } from './pagination';

const dataAtom = atom<IOrder[]>([]);
const isLoading = atom<boolean>(false);

export interface OrdersState {
  data: IOrder[];
  isLoading: boolean;
}

export const ordersAtom = atom<OrdersState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
}));

export const fetchOrdersAtom = atom(
  (get) => get(ordersAtom),
  async (get, set) => {
    const params = get(paramsAtom);
    set(isLoading, true);
    $http
      .get(`/api/v1/orders/?${params ?? 1}`)
      .json<IPagination<IOrder>>()
      .then((orders: IPagination<IOrder>) => {
        set(dataAtom, orders.rows);
        set(totalAtom, orders.total_rows);
      })
      .finally(() => set(isLoading, false));
  },
);
