import { Form, Input, InputNumber, Radio, Select } from 'antd';
import { useEffect } from 'react';

import { IFormProps } from '../../types';
import { IOffer } from '../../types';

export const OffersAddForm = ({ formData, onFormReady }: IFormProps<IOffer>) => {
  const [form] = Form.useForm();

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  const offerNameSuffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select style={{ width: 120 }}>
        <Select.Option value="">Default</Select.Option>
        <Select.Option value=" - Infocus">Infocus</Select.Option>
        <Select.Option value=" - Prime Admin">Prime Admin</Select.Option>
        <Select.Option value=" - Reship">Reship</Select.Option>
        <Select.Option value=" - Klaviyo">Klaviyo</Select.Option>
        <Select.Option value=" - Network">Network</Select.Option>
      </Select>
    </Form.Item>
  );

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        ...formData,
        offer_reward: false,
        suffix: '',
      }}
    >
      <Form.Item label="Offer name" name="name" rules={[{ required: true, message: 'Missing Offer name' }]}>
        <Input placeholder="Offer name" addonAfter={offerNameSuffixSelector} />
      </Form.Item>
      <Form.Item name="offer_reward">
        <Radio.Group>
          <Radio value={false}>Not-Subscription</Radio>
          <Radio value={true}>Subscription</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="ef_offer_id"
        label="Everflow Offer Id"
        rules={[
          { required: false, message: 'Please input Everflow Offer Id' },
          { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
        ]}
      >
        <InputNumber style={{ width: '100%' }} maxLength={5} placeholder="Everflow Offer ID" />
      </Form.Item>
    </Form>
  );
};
