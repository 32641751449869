import { DeleteOutlined, EditFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Space, Table, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAtom, useSetAtom } from 'jotai';
import type { AlignType } from 'rc-table/es/interface';
import { useEffect } from 'react';

import {
  createCategoryAtom,
  deleteCategoryAtom,
  fetchCategoriesAtom,
  importCategoryStickyAtom,
  updateCategoryAtom,
} from '@/atoms/categories';
import { openModalAtom } from '@/atoms/modal';
import { ICategory } from '@/types';
import { columnSearch, commonFields, enableSorting, SortOrder } from '@/utils/table';

import { CategoryEditForm, CategoryImportForm } from '../Forms';
import Loading from '../Loading';
import PageHeader from '../PageHeader';

const Categories = () => {
  const openModal = useSetAtom(openModalAtom);
  const deleteCategory = useSetAtom(deleteCategoryAtom);
  const [categories, fetchCategories] = useAtom(fetchCategoriesAtom);
  const [, createCategory] = useAtom(createCategoryAtom);
  const [, updateCategory] = useAtom(updateCategoryAtom);
  const [, importCategorySticky] = useAtom(importCategoryStickyAtom);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const columns = [
    {
      title: 'ID',
      ...commonFields('ID'),
      ...enableSorting('ID', SortOrder.DESCEND),
    },
    {
      title: 'Sticky ID',
      ...commonFields('sticky_id'),
      ...enableSorting('sticky_id'),
      ...columnSearch('sticky_id'),
    },
    {
      title: 'Name',
      ...commonFields('name'),
      ...columnSearch('name'),
    },
    {
      title: 'Action',
      align: 'center' as AlignType,
      ...commonFields('action'),
      render: (_: any, category: ICategory) => (
        <Space>
          <Tooltip placement="topLeft" title="Category Edit">
            <Button
              shape="circle"
              icon={<EditFilled />}
              onClick={() => {
                openModal({
                  title: 'Edit Category',
                  component: CategoryEditForm,
                  data: category,
                  onSave: (values) => updateCategory(category.ID, [values]),
                });
              }}
            />
          </Tooltip>
          <Popconfirm
            title="Are you sure you want to remove?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteCategory(category.ID)}
          >
            <Button shape="circle" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  if (categories.isLoading) return <Loading />;
  return (
    <>
      <PageHeader
        title="Categories"
        extra={[
          <Button
            type="primary"
            onClick={() => {
              openModal({
                title: 'Create Category',
                component: CategoryEditForm,
                onSave: (values) => {
                  let haveIt = categories.data.find((category) => category.name === values.name);
                  if (haveIt) {
                    message.info(`Category [${values.name}] already exists`);
                    return Promise.reject();
                  }
                  values.description = values.name;
                  return createCategory(values);
                },
              });
            }}
            key="create"
          >
            <PlusCircleOutlined /> Add Category
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              openModal({
                title: 'Import Category',
                component: CategoryImportForm,
                onSave: (values) => {
                  let haveIt = categories.data.find((category) => category.sticky_id === +values.sticky_id);
                  if (haveIt) {
                    message.info(`Category with [${values.sticky_id}] already exists`);
                    return Promise.reject();
                  }
                  return importCategorySticky([values]);
                },
              });
            }}
            key="import"
          >
            <PlusCircleOutlined />
            Import Category
          </Button>,
        ]}
      />
      <Content>
        <Table dataSource={categories.data} rowKey="ID" columns={columns as any} pagination={false} />
      </Content>
    </>
  );
};

export default Categories;
