import { atom } from 'jotai';

import { IScrub } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<IScrub[]>([]);
const isLoading = atom<boolean>(false);

export interface ScrubState {
  data: IScrub[];
  isLoading: boolean;
}

export const scrubsAtom = atom<ScrubState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
}));

export const fetchScrubsAtom = atom(
  (get) => get(scrubsAtom),
  async (_, set, id) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/funnels/${id}/scrubs`)
      .json<IScrub[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const createScrubAtom = atom(null, async (get, set, body?: IScrub) => {
  if (!body) return;
  set(isLoading, true);
  $http
    .post('/api/v1/scrubs', { body })
    .json<IScrub>()
    .then((response) => set(dataAtom, [...get(dataAtom), response]))
    .finally(() => set(isLoading, false));
});

export const updateScrubAtom = atom(null, async (get, set, id: number, body: Partial<IScrub>) => {
  if (!id) return;
  const scrubs = get(scrubsAtom);
  set(isLoading, true);
  $http
    .put(`/api/v1/scrubs/${id}`, { body })
    .json<IScrub>()
    .then((scrub) => set(dataAtom, scrubs.data.updateValueBy('ID', scrub)))
    .finally(() => set(isLoading, false));
});

export const deleteScrubAtom = atom(null, async (get, set, id?: number) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .delete(`/api/v1/scrubs/${id}`)
    .json<IScrub>()
    .then(() =>
      set(
        dataAtom,
        get(dataAtom).filter(({ ID }) => ID !== id),
      ),
    )
    .finally(() => set(isLoading, false));
});
