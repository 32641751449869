import { Modal } from 'antd';

export const ShippingDeleteForm = ({ active, onFinished, pendingProduct, iframeDocument, onCancel }: any) => {
  const deleteItem = () => {
    onFinished(pendingProduct);
  };
  return (
    <Modal open={active} onCancel={onCancel} onOk={() => deleteItem()}>
      <div>{`Delete (${pendingProduct?.shipping_id})?`}</div>
    </Modal>
  );
};
