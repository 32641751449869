import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, InputNumber, message,Modal, Select } from 'antd';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { fetchOfferAtom, fetchOffersAtom } from '../../../../../atoms/offers';
import { ActionType, PendingAction } from '../../../../../types';
import { CustomScripts } from './CustomScripts/Index';

export const EditExtras = ({ pendingSaveData, pageData, listChange }: any) => {
  const [offers, fetchOffers] = useAtom(fetchOffersAtom);
  const [offer, fetchOffer] = useAtom(fetchOfferAtom);
  const [inputCampaign, setInputCampaign] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);
  const [form] = Form.useForm();
  let meta = !!pendingSaveData && !!pendingSaveData.meta && pendingSaveData.meta;
  let revProducts = !!offer?.products?.length ? offer.products : [];

  const [extrasChecked, setExtrasChecked] = useState([
    {
      active: !!meta && meta.productSizeActive === 'true' ? true : false,
      type: 'productSizeActive',
      discript: 'Product Size',
    },
    {
      active: !!meta && meta.productAddonsActive === 'true' ? true : false,
      type: 'productAddonsActive',
      discript: 'Product Extra',
    },
    {
      active: !!meta && meta.recurring ? true : false,
      type: 'recurring',
      discript: 'VIP Product',
    },
    {
      active: !!meta && meta.warrantyJourney === "true" ? true : false,
      type: 'warrantyJourney',
      discript: 'Warranty & Journey fixed price',
    }
  ]);

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  useEffect(() => {
    if (!!meta && !!meta.recurring_offer_id) {
      let offer = offers.data.find((offer) => offer.offer_id === meta.recurring_offer_id);
      !!offer && fetchOffer(offer.ID);
    }
  }, [offers.data, meta, fetchOffer]);

  useEffect(() => {
    form.setFieldsValue({ ...meta });
  }, [form, meta]);

  const onCheckChangeHandler = (checkbox) => {
    let el = [...extrasChecked];
    let elindex = el.findIndex((v) => v.type === checkbox.type);
    el[elindex].active = !checkbox.active;
    setExtrasChecked(el);
    let n = el.reduce((a, v) => ({ ...a, [v.type]: v.type === 'recurring' ? v.active : v.active.toString() }), {});
    listChange(n);
  };

  const handleOfferChange = (offerId: number) => {
    let offer = offers.data.find((offer) => offer.ID === offerId);
    listChange({ recurring_offer_id: offerId });
    if (!!offer?.ID) {
      fetchOffer(offerId);
    }
  };

  const handleProductChange = (productId: any) => {
    let product = !!revProducts && !!revProducts.length && revProducts.find((prod) => +prod.product_id === +productId);

    if (product) {
      listChange({
        recurring_product_id: productId,
        recurring_product_price: product?.product_price?.toFixed(2),
        recurring_product_name: product.name,
      });
    }
  };

  const onAddCampaign = () => {
    let existingCamps = !!meta && meta.recurring_campaigns ? meta.recurring_campaigns : [];
    let alreadyExist =
      !!meta &&
      !!meta.recurring_campaigns &&
      meta.recurring_campaigns.find((rec) => rec.ID === inputCampaign || rec.country === countryCode);
    if (inputCampaign === '' || countryCode === '') {
      message.error('Campaign ID or country code is missing!');
      return;
    }
    if (alreadyExist && alreadyExist.ID) {
      message.error('Campaign with that ID or country cody has already been added');
      return;
    }
    let dataToSend = [...existingCamps, { ID: inputCampaign, country: countryCode }];
    setInputCampaign('');
    listChange({ recurring_campaigns: dataToSend });
  };

  const onDeleteCampaign = (id: string) => {
    const filterCamps = !!meta && !!meta.recurring_campaigns && meta.recurring_campaigns.filter((rec) => rec.ID !== id);
    listChange({ recurring_campaigns: filterCamps });
  };

  const onChangeRecurringName = (e) => {
    listChange({ recurring_product_name: e.recurring_product_name });
    setPendingAction(null);
  };

  return (
    <div className="extras">
      <p className="info_text">Enable extras:</p>
      {extrasChecked.map((extra) => {
        return (
          <div key={extra.type}>
            <Checkbox
              key={extra.type}
              checked={extra.active}
              className={`extrasCheckBox ${extra.active && 'enabled'}`}
              onChange={() => onCheckChangeHandler(extra)}
            >
              Enable {extra.discript}
            </Checkbox>
            {extra.type === 'recurring' && extra.active && (
              <>
                <p className="info_text">Select Offer:</p>
                <Select
                  showSearch
                  placeholder="Pick vip offer"
                  defaultValue={meta && meta.recurring_offer_id && meta.recurring_offer_id}
                  style={{ display: 'block', marginBottom: 10 }}
                  onChange={handleOfferChange}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={
                    offers.data &&
                    Array.from(new Set(offers.data.map((a: any) => a.offer_id)))
                      .map((id: any) => {
                        return offers.data.find((a: any) => a.offer_id === id);
                      })
                      .filter((offer) => {
                        return (
                          offer?.name?.toLocaleLowerCase().includes('revboost') ||
                          offer?.name?.toLocaleLowerCase().includes('vip')
                        );
                      })
                      .map((offer: any) => {
                        return {
                          value: offer.offer_id,
                          label: `(${offer.offer_id}) ${offer.name}`,
                        };
                      })
                  }
                />
                <p className="info_text">Select Product:</p>
                <Select
                  placeholder="Select Product"
                  defaultValue={
                    !!meta && !!meta.recurring_product_id ? meta.recurring_product_id : '- Select Offer First -'
                  }
                  style={{ display: 'block', marginBottom: 10 }}
                  onChange={handleProductChange}
                  options={revProducts.map((product) => {
                    return { value: product.product_id, label: `(${product.product_id}) ${product.name}` };
                  })}
                />
                <hr />
                <p className="info_text">Selected Product:</p>
                {!!meta && meta.recurring_product_name && (
                  <>
                    {pendingAction?.item && (
                      <Form form={form} className="flexBetween" onFinish={onChangeRecurringName}>
                        <Form.Item name="recurring_product_name" className="margin-0">
                          <Input />
                        </Form.Item>
                        <Button style={{ border: 'none' }} htmlType="submit">
                          <CheckOutlined className="editWarranty" />
                        </Button>
                      </Form>
                    )}
                    {!pendingAction?.item && (
                      <div className="flexBetween">
                        <p>{meta.recurring_product_name}</p>
                        <EditOutlined
                          className="editWarranty"
                          onClick={() => {
                            setPendingAction({ item: meta.recurring_product_name, type: ActionType.Edit });
                          }}
                        />
                      </div>
                    )}
                  </>
                )}

                <hr />
                <div className="campaignInputs">
                  <p className="info_text">Add Campaigns:</p>
                  <InputNumber
                    placeholder="Campaign ID"
                    onChange={(e: any) => setInputCampaign(e)}
                    value={inputCampaign}
                  />
                  <p className="info_text">Select Country:</p>
                  <Select
                    placeholder="Country Code"
                    style={{ display: 'block', marginBottom: 10 }}
                    onChange={(value: string) => setCountryCode(value)}
                    options={[
                      { value: 'US', label: 'US' },
                      { value: 'CA', label: 'CA' },
                      { value: 'UK', label: 'UK' },
                      { value: 'DE', label: 'DE' },
                      { value: 'US-PP', label: 'US - Paypal' },
                      { value: 'CA-PP', label: 'CA - Paypal' },
                      { value: 'UK-PP', label: 'UK - Paypal' },
                      { value: 'DE-PP', label: 'DE - Paypal' },
                    ]}
                  />
                  <Button type="primary" onClick={onAddCampaign}>
                    Add
                  </Button>
                  <p className="info_text">Added Campaigns:</p>
                  {!!meta &&
                    !!meta.recurring_campaigns &&
                    meta.recurring_campaigns.map((recCam) => {
                      return (
                        <div className="recCam" key={recCam.ID}>
                          <span className="recCampSpan">
                            {recCam.ID} - ({recCam.country})
                          </span>
                          <DeleteOutlined
                            onClick={() =>
                              Modal.confirm({
                                title: `Delete Campaign ${recCam.ID}`,
                                content: ``,
                                onOk: () => onDeleteCampaign(recCam.ID),
                              })
                            }
                            className="recCampDelete"
                          />
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        );
      })}
      <hr />
      <p className="info_text">Custom Behaviors:</p>
      <CustomScripts />
    </div>
  );
};
