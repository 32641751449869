import parse from 'html-react-parser';

export const ProductElement = ({ product, pageData }) => {
  let price = product.product_data_price;
  let qty = product.product_data_quantity;
  let orgPrice = product.product_data_regPrice;
  let savePrice = product.product_data_save;
  let eachPrice = (+price / +qty).toFixed(2);
  let name = product.product_data_name;
  let discount = product.product_data_package_discount;
  let productPackage = product.product_data_package_name;
  let features = product.features;
  let img = product.product_unit_img || 'https://placehold.co/100x100?text=product+image';
  let type = !!pageData.template && !!pageData.template.meta && pageData.template.meta.checkoutType;

  return (
    <label className={type}>
      <div className={`package-header align-items-center py-0 buy-opt-left`}>
        <div className="package-title">
          <span className="package-main-title_blissy product_data_name">{name}</span>
          <span className="package-main-save align-self-center">
            Save <span className="bluTxt blu_blissy font-weight-normal">{discount}</span>{' '}
            <span className="textOff">OFF</span>
          </span>
          {product.preselected && <span className="package-best-seller">Best Seller</span>}
        </div>
        <div className="freeship align-middle ml-auto">
          <i className="fad fa-truck"></i>FAST SHIPPING
        </div>
      </div>
      <div className={`pt-2 pb-2 package-content`}>
        <div className="ml-5 package-image">
          <img
            width="220"
            height="110"
            className="product-img product_unit_img product_unit_img_blissy blur-up lazyloaded"
            alt="..."
            src={img}
          />
        </div>
        <div className="ml-2 ml-md-3 pr-3 package-info">
          <div className="pi-1 pl-lg-1">
            <span className="unit_pack">{productPackage}</span>
          </div>
          <div className="pi-2 pl-lg-1 productCartName" style={{ color: '#212529' }}>
            3x TetraBeams
          </div>
          <div className="pi-price-sa pl-lg-1">Only</div>
          <div className="pi-price-each pl-lg-1">
            <span className="currency-block product_data_unitPrice">${eachPrice}</span>
            <span className="small-each">/each</span>
          </div>
          <div className="pi-price-orig pl-lg-1 text-danger font-italic">
            <span>Orig</span>
            <span className="currency-block product_data_regPrice">${orgPrice}</span>
          </div>
          <div className="pi-price-save pl-lg-1 text-success font-weight-bold">
            <span>You Save</span>
            <span className="currency-block product_data_savePrice">${savePrice}</span>!
          </div>
          <div className="pi-price-total pl-lg-1">
            Total:
            <span className="currency-block product_data_price" id="buy">
              ${price}
            </span>
          </div>
        </div>
      </div>
      <div className="buy-opt-rgt">
        <p className="pkg-prc">
          <span className="cb-reg-price product_data_regPrice">${orgPrice}</span>
          <br />
          <span className="cb-buy">
            <span className="product_data_unitPrice font-weight-bold">${eachPrice}</span>/ea
          </span>
        </p>
      </div>
      <div className="select-button d-flex justify-content-center align-items-center"></div>
      <div className="features row mb-2 mx-1 py-1">
        {!!features &&
          features.map((feature) => {
            return (
              <div key={feature.f} className="col-6 col-lg-3 p-0">
                <ul className="ml-2 mb-0 p-0">
                  <li>{feature.f}</li>
                </ul>
              </div>
            );
          })}
      </div>
      <div className="mx-auto px-1 px-md-5 package-bullet-wrapper">
        <div className="d-flex py-1 package-bullet-container">
          <div className="flex-fill text-center bullet-item">
            Sell-Out Risk: <span style={{ color: 'red' }}>High</span>
          </div>
          <div className="flex-fill text-center bullet-sep">•</div>

          <div className="freeship flex-fill text-center bullet-item font-weight-bold text-success">FAST SHIPPING</div>

          <div className="flex-fill text-center bullet-sep">•</div>

          <div className="flex-fill text-center bullet-item font-weight-bold text-danger">
            Discount:
            <span className="package-main-save packs">{discount}</span>
          </div>

          <div className="flex-fill text-center bullet-sep">•</div>
          <div className="flex-fill text-center bullet-item text-success font-weight-bold">
            <i className="fad fa-signal-4"></i> <span className="sellOut">Best Seller</span>
          </div>
        </div>
      </div>
      {type === 'upsellList' && (
        <li className="upsell-products-list">
          <label htmlFor={`q${product.product_data_quantity}`} className="mb-0">
            <span style={{ background: 'rgb(116, 198, 180)', color: 'white' }} className="product_quantity">
              {product.product_data_quantity}
            </span>
          </label>
        </li>
      )}
    </label>
  );
};

export const ListElement = ({ list }) => {
  return (
    <>
      {list.img && <img className="s1-ic" alt="..." width="50" height="50" src={window.location.origin + list.img} />}
      <p>{list.listText}</p>
    </>
  );
};

export const AddonElement = ({ addon }) => {
  return (
    <div className={`add_onBac add-box_blissy ${addon.frame}`}>
      <div id="id9bie" className=" bump-div-className selectr-grpBox remote_gr_el select-color-box">
        <h5 id="bumpHeadingClass-3" className="fk-product bump-heading-className fk-element-container chiller_cb">
          <input
            type="checkbox"
            id="formCheckLabelOrderBump-{{.id}}"
            className="fk-form-checkbox order-bump-checkbox checkAddon mt-0"
          />
          <label htmlFor="formCheckLabelOrderBump" className="form-check-label expship">
            <strong>{addon.name}</strong>
          </label>
          <span></span>
        </h5>
        <p className="select-quty">Select Quantity:</p>
        <div className="btn-div">
          <button type="button" className={`select-color ${addon.color}_btn`} data-color="{{.description}}">
            {addon.name}
          </button>
        </div>
        <div className="quantity-controls">
          <button type="button" className="sub qtyBtn">
            -
          </button>
          <select disabled id="Quantity" name="{{.description}}_quantity" className="quantity-selector ">
            <option value="0">0</option>
          </select>
          <button type="button" className="add qtyBtn">
            +
          </button>
        </div>
        <section
          id="orderBumpVariants-{{.place}}"
          className="orderBumpVariants section-style fk-product-section w-100 fk-product-variant1 product-section-border fk-display-flex flex-column"
        >
          <select
            style={{ appearance: 'auto', display: addon.frame === 'addonFrame5' ? 'block' : 'none' }}
            name="addons[]"
            id="addonsRemote"
            className="form-control-custom mt-0 fk-form-select click-bump-qty my-2 py-1 "
          >
            {!!addon.products &&
              !!addon.products.length &&
              addon.products.map((adproduct, i) => {
                return <option key={i}>{adproduct.data_name}</option>;
              })}
          </select>
        </section>
        <div className="desc">{!!addon.description && parse(addon.description)}</div>
      </div>
    </div>
  );
};

export const JourneyElement = ({ addon }) => {
  return (
    <div className="remote_gr_el">
      <div className="row align-items-center">
        <div className="col-9">
          <h3 className="nw-txt">Journey Package Protection</h3>
        </div>
        <div className="col-3 p-0">
          <label className="switch">
            <input type="checkbox" className="journey_checkbox checkAddon" data-price="11.25" checked />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="clearfix"></div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="panel">{!!addon.description && parse(addon.description)}</div>
        </div>
      </div>
    </div>
  );
};

export const WarrantyElement = ({ meta }) => {
  if (!meta.warrantyFrame) return <p className="no_warranty">Please select warranty frame</p>;

  return (
    <div className={meta.warrantyFrame}>
      <div className="warranty_header_journey">
        <label htmlFor="checkStatus" className="mb-0">
          <p className="nw-txt">Journey Package Protection</p>
          <p className="grp-bx-text1 ml-2">PRODUCT Coverage</p>
          <strong className="protection">Yes, I Want Protection!</strong>
          <p className="simple-txt mb-0">YES, I Want 1 Year Protection!</p>
          <p className="simple-txt2 mb-0">1 Year Extended Warranty</p>
        </label>
        <div className="switch chiller_cb">
          <input type="checkbox" id="checkStatus" data-price="33.60" />
          <label className="simple-label" htmlFor="checkStatus">
            <img alt="..." src="/images/checkout/red-arrow.png" className="bounce-right" />
          </label>
          <span className="slider round"></span>
          <span className="checkmark"></span>
          <span className="sliderspan"></span>
        </div>
      </div>
      <div className="warranty_body_journey">
        <p className="np-txt mt-2">
          100% Guaranteed Package Protection From Damage. Loss. &amp; Theft For Just{' '}
          <span id="protection" className="each-warranty-price"></span>/unit. In the event your delivery is damaged.
          stolen. or lost during transit. We will replace it 100% free. No questions asked. Protect your package and
          your family today!{' '}
        </p>
        <p className="grp-bx-text2">
          <span className="cb-warranty-original-price warranty-original-price">$158.00</span>{' '}
          <span id="protection" className="each-warranty-price mr-0">
            $47.40
          </span>
          <span id="protection">/ea</span>
        </p>
        <ul className="grpbx-list">
          <li>Accidental damage protection</li>
          <li>Free parts replacement guarantee</li>
          <li>Express replacement service - we'll ship you a replacement, so you don't have to wait for a repair</li>
          <li>24/7 priority access to PRODUCT experts</li>
        </ul>
        <p className="blissy_warranty">
          <span className="ylw">One-Chance Offer:</span> By placing your order today, you can have 1 Year Extended
          Warranty replacement and protection warranty for only
          <span className="each-warranty-price font-weight-bold text-success hdbl">$13.80</span>
          <span className="text-success font-weight-bold hdbl">/ea!</span>
          <span className="full-warranty-price font-weight-bold hdbl-p">$9.95</span>
          <span>This extended warranty covers your product for 1 Year.</span>
        </p>
        <div className="body_simple_checkout p-2">
          <strong>One Time Offer:</strong> By placing your order today, you can have 1 Year Extended Warranty
          replacement and protection warranty for only
          <span className="each-warranty-price">31.60</span>/ea! This extended warranty covers your product for 1 Year.
        </div>
      </div>
    </div>
  );
};
