import { Form, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useState } from 'react';

const ProductInfoEdit = ({ pendingProduct, onChange }: any) => {
  const [form] = Form.useForm();
  const [info, setInfo] = useState(
    !!pendingProduct.product_info ? pendingProduct.product_info : 'LARGE HOUSE 3000+ SQ.FT',
  );
  const onProductInfoChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInfo(e.target.value);
    onChange({ product_info: e.target.value });
  };

  return (
    <div>
      <Form form={form} layout="vertical">
        <div className="productRow">
          <FormItem style={{ width: '50%' }} initialValue={info} name="product_info" label="Edit Product Info">
            <Input onChange={onProductInfoChangeHandler} value={info} />
          </FormItem>
        </div>
      </Form>
    </div>
  );
};
export default ProductInfoEdit;
