import { Table } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { fetchProductsAtom } from '../../../atoms/products';
import { IRouteParams } from '../../../types';
import { IShipping } from '../../../types';
import { columnSearch, commonFields } from '../../../utils/table';
import Loading from '../../Loading';

const Products = ({ id }: IRouteParams) => {
  const [product, fetchProducts] = useAtom(fetchProductsAtom);

  useEffect(() => {
    fetchProducts(id);
  }, [fetchProducts, id]);

  const uniqIds: any = Array.from(new Set(product?.data.map((a: any) => a.product_id))).map((id: any) => {
    return product.data.find((a: any) => a.product_id === id);
  });

  const columns = [
    {
      title: 'ID',
      ...commonFields('product_id'),
      width: 100,
    },
    {
      title: 'Name',
      ...commonFields('name'),
      ...columnSearch('name'),
    },
    {
      title: 'SKU',
      ...commonFields('sku'),
      ...columnSearch('sku'),
    },
    {
      title: 'Price',
      ...commonFields('product_price'),
      ...columnSearch('product_price'),
    },
    {
      title: 'Category',
      ...commonFields('product_category'),
      ...columnSearch('product_category'),
    },
    {
      title: 'Billing model',
      ...commonFields('billing_model'),
      ...columnSearch('billing_model'),
    },
    {
      title: 'Shipping',
      ...commonFields('shipping'),
      ...columnSearch('shipping'),
      render: (shipping: IShipping) => (shipping ? `${shipping.ID} ${shipping.name} ($${shipping.price})` : 'FREE'),
    },
  ];

  return product.isLoading ? (
    <Loading />
  ) : (
    <>
      <Table dataSource={uniqIds} rowKey="ID" columns={columns} pagination={false} scroll={{ y: 300 }} />
    </>
  );
};

export default Products;
