import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Select, Tooltip } from 'antd';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { pages } from '@/atoms/quickBuild';
import { fetchTemplatesAtom } from '@/atoms/templates';
import { IPage, ITemplate } from '@/types';

import { PageTypes } from '../../PageEditForm';
import { PendingAction } from '../Domains/Index';
import { EditPage } from './EditPage';

export const Pages = () => {
  const [form] = Form.useForm();
  const [templates, fetchTemplates] = useAtom(fetchTemplatesAtom);
  const [pagesAtom, setPagesAtom] = useAtom(pages);
  const [pageType, setPageType] = useState('');
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  const onFinish = (values: IPage) => {
    let buildedPages = !!pagesAtom ? [...pagesAtom] : [];
    let haveIt = buildedPages.find((page) => page.page_path === values.page_path);
    if (haveIt) {
      message.info('Page path already exists');
      return;
    }
    setPagesAtom([...buildedPages, values]);
    form.resetFields();
  };

  const onDelete = (pagePath) => {
    let filterPages = pagesAtom?.filter((v) => v.page_path !== pagePath);
    setPagesAtom(filterPages);
  };

  const onUpdate = (values: IPage) => {
    let buildedPages = !!pagesAtom ? [...pagesAtom] : [];
    let findPageIndex = buildedPages.findIndex((v) => v.page_path === values.page_path);
    buildedPages[findPageIndex] = values;
    setPagesAtom(buildedPages);
    setPendingAction(null);
  };

  const onPageTypeChangeHandler = (e: string) => {
    setPageType(e);
  };

  return (
    <div className="funnelCreate">
      <div className="currentFunnel">
        {!!pagesAtom?.length ? (
          <>
            {pagesAtom.map((page, i) => {
              return (
                <div key={i} className="currentFunneldiv">
                  {pendingAction?.item.page_path === page.page_path ? (
                    <EditPage page={page} onUpdate={(item) => onUpdate(item)} onClose={() => setPendingAction(null)} />
                  ) : (
                    <div className="flexBetween">
                      <p className="domainname">
                        <strong>{page.name}</strong> - {page.page_path}
                      </p>
                      <div>
                        <Tooltip title="Delete Page">
                          <DeleteOutlined className="deleteBtn" onClick={() => onDelete(page.page_path)} />
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <h4 className="noThxAdd">No Pages added</h4>
        )}
      </div>
      <hr />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="page_type" rules={[{ required: true, message: 'Missing Page Type' }]} label="Select Page Type">
          <Select placeholder="Add Page Type" onChange={onPageTypeChangeHandler}>
            {Object.values(PageTypes).map((type) => (
              <Select.Option key={type}>{type === 'upsell1' ? 'upsell' : type}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Page Name" name="name" rules={[{ required: true, message: 'Missing Name' }]}>
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="page_path"
          rules={[
            {
              required: true,
              type: 'string',
              message: 'Please input valid page path, it must start and end with slash',
              pattern: RegExp(/^\/([A-z0-9-_+]+\/)*$/),
            },
          ]}
          label="Page path"
        >
          <Input placeholder="Path: (exp: /offer/1/checkout)" />
        </Form.Item>
        <Form.Item name="template_id" label="Template Type">
          <Select placeholder="Select Page Template">
            <Select.Option value={''}>--Select Template--</Select.Option>
            {templates.data
              .filter((template: ITemplate) => !template.archived && pageType === template.page_type)
              .map((v) => {
                return (
                  <Select.Option key={v.ID} value={v.ID}>
                    <span>{v.name}</span>
                    <a className="viewTemplate" target={'_blank'} href={v.template_host_url} rel="noreferrer">
                      <EyeOutlined />
                    </a>
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
