import { Form, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useState } from 'react';

export const DiscountEdit = ({ pendingProduct, onChange }: any) => {
  const [form] = Form.useForm();
  const [discount, setDiscount] = useState(pendingProduct.product_data_package_discount);
  const [productName, setProductName] = useState(pendingProduct.product_data_name);
  const [productReceipt, setProductReceipt] = useState(
    pendingProduct.product_data_receipt ? pendingProduct.product_data_receipt : pendingProduct.product_data_name,
  );
  const [productQty, setProductQty] = useState(pendingProduct.product_data_quantity);

  const onDiscountChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscount(e.target.value);
    onChange({ product_data_package_discount: e.target.value });
  };

  const onProductNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductName(e.target.value);
    onChange({ product_data_name: e.target.value });
  };

  const onProductReceiptChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductReceipt(e.target.value);
    onChange({ product_data_receipt: e.target.value });
  };

  const onProductQtyChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductQty(e.target.value);
    onChange({ product_data_quantity: e.target.value });
  };
  return (
    <div>
      <Form form={form} layout="vertical">
        <div className="productRow">
          <FormItem
            label="Product Name"
            style={{ width: '100%', marginRight: 2 }}
            initialValue={productName}
            name="product_data_name"
          >
            <Input onChange={onProductNameChangeHandler} value={productName} />
          </FormItem>
          <FormItem
            label="Product Name for Receipt (for a thank you pages)"
            style={{ width: '100%', marginRight: 2 }}
            initialValue={productReceipt}
            name="product_data_receipt"
          >
            <Input onChange={onProductReceiptChangeHandler} value={productReceipt} />
          </FormItem>
          <FormItem
            label="Product Quantity"
            style={{ width: '100%', marginRight: 2 }}
            initialValue={productQty}
            name="product_data_quantity"
          >
            <Input style={{ width: '100%' }} onChange={onProductQtyChangeHandler} value={productQty} />
          </FormItem>
          <FormItem
            label="Product Discount"
            style={{ width: '100%' }}
            initialValue={discount}
            name="product_data_package_discount"
          >
            <Input onChange={onDiscountChangeHandler} value={discount} />
          </FormItem>
        </div>
      </Form>
    </div>
  );
};
