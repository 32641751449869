import { IFunnel } from '../../../types';
import { ImageGallery } from '../../common/ImageGallery';

const FunnelGallery = ({ funnel }: { funnel: IFunnel }) => {
  return (
    <div className="gallery">
      <ImageGallery funnelId={funnel.ID} disabled={funnel?.locked} />
    </div>
  );
};

export default FunnelGallery;
