import EditFaq from '../Details/EditFaqs/Index';
import EditManual from '../Details/EditManual/Index';

export const Faq = () => {
  return <EditFaq />;
};

export const Manual = () => {
  return <EditManual />;
};
