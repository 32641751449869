import { DesktopOutlined, MobileOutlined, TabletOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export enum DeviceType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  TABLET = 'tablet',
}

interface IEditDeviceProps {
  deviceType: string;
  onChange: ({ device }: { device: DeviceType }) => void;
}

export const EditDevice = ({ deviceType, onChange }: IEditDeviceProps) => {
  const devices = [
    {
      Type: DeviceType.DESKTOP,
      Icon: DesktopOutlined,
      Tooltip: 'Desktop',
    },
    {
      Type: DeviceType.MOBILE,
      Icon: MobileOutlined,
      Tooltip: 'Mobile',
    },
    {
      Type: DeviceType.TABLET,
      Icon: TabletOutlined,
      Tooltip: 'Tablet',
    },
  ];

  return (
    <div className="device">
      {devices.map(({ Type, Icon, Tooltip: tooltip }) => (
        <Tooltip key={Type} placement="right" title={tooltip}>
          <button onClick={() => onChange({ device: Type })} className={deviceType === Type ? 'checkedDevice' : ''}>
            <Icon />
          </button>
        </Tooltip>
      ))}
    </div>
  );
};
