/* eslint-disable no-extend-native */

export {};
declare global {
  interface Array<T> {
    uniqBy(this: Array<T>, id: string): Array<T>;
    findBy(this: Array<T>, key: string, value: T): Array<T>;
    updateValueBy(this: Array<T>, key: string, value: T): Array<T>;
  }
}

if (typeof Array.prototype.uniqBy !== 'function') {
  Array.prototype.uniqBy = function uniqBy<T>(id: any = 'id') {
    return this.filter((val: T, idx, self) => idx === self.findIndex((item) => item[id] === val[id]));
  };
}

Array.prototype.findBy = function findBy<T>(key: string, value: T) {
  return this.find((el) => el[key] === value[key]);
};

Array.prototype.updateValueBy = function findBy<T>(key: string, newValue: T) {
  let index = this.findIndex((el) => el[key] === newValue[key]);
  return index >= 0 ? this.toSpliced(index, 1, newValue) : this;
};
