import { Form, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useState } from 'react';

export const WarrantyEdit = ({ pendingProduct, onChange }: any) => {
  const [form] = Form.useForm();
  const [warrantyEach, setWarrantyEach] = useState(pendingProduct.product_data_warranty);

  const onWarrantyPriceChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWarrantyEach(e.target.value);
    onChange({ product_data_warranty: e.target.value });
  };

  return (
    <div>
      <Form form={form} layout="vertical">
        <div className="productRow">
          <FormItem
            label="This is warranty full price, add each price"
            style={{ width: '100%' }}
            initialValue={warrantyEach}
            name="product_data_warranty"
          >
            <Input onChange={onWarrantyPriceChangeHandler} value={warrantyEach} />
          </FormItem>
        </div>
      </Form>
    </div>
  );
};
