import 'reactflow/dist/style.css';
import './PagesFlow.css';

import Dagre from '@dagrejs/dagre';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef,useState } from 'react';
import ReactFlow, {
  applyNodeChanges,
  Controls,
  Edge,
  MarkerType,
  Node,
  OnConnect,
  OnNodesChange,
  useEdgesState,
} from 'reactflow';

import { updatePageAtom } from '../../../../atoms/pages';
import { IFunnel } from '../../../../types';
import { IPage } from '../../../../types';
import PageNode from './PageNode';

const PagesFlow = ({ funnel, pages }: { funnel: IFunnel; pages: IPage[] }) => {
  const [, updatePage] = useAtom(updatePageAtom);
  const [pagesMap, setPagesMap] = useState<Record<string, IPage>>({});
  const [nodes, setNodes] = useState<any[]>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<any[]>([]);
  const edgeUpdateSuccessful = useRef(true);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes],
  );

  const onUpdatePage = useCallback(
    (source: string, sourceHandle: string | null, target: string | null) => {
      const targetInt = !!target ? +target : target;
      const page = pagesMap[source];
      // If no sourceHandle switch
      const update = !!sourceHandle
        ? {
            page_id: sourceHandle === 'a' ? targetInt : page.page_id,
            page_alt_id: sourceHandle === 'b' ? targetInt : page.page_alt_id,
          }
        : {
            page_id: page.page_alt_id,
            page_alt_id: page.page_id,
          };
      updatePage(page.ID, {
        ...update,
        funnel_id: funnel.ID,
      });
    },
    [updatePage, funnel, pagesMap],
  );

  const onEdgeUpdateStart = useCallback(() => {
    if (funnel?.locked) return false;
    edgeUpdateSuccessful.current = false;
  }, [funnel]);

  const onEdgeUpdate = useCallback(
    (oldEdge, newEdge) => {
      edgeUpdateSuccessful.current = true;
      if (oldEdge.source !== newEdge.source) {
        // Delete old edge
        onUpdatePage(oldEdge.source, oldEdge.sourceHandle, null);
      } else if (oldEdge.sourceHandle !== newEdge.sourceHandle) {
        // Switch a and b
        onUpdatePage(oldEdge.source, null, null);
        return;
      }
      // Add new edge
      onUpdatePage(newEdge.source, newEdge.sourceHandle, newEdge.target);
    },
    [onUpdatePage],
  );

  const onEdgeUpdateEnd = useCallback(
    (_, edge) => {
      if (!edgeUpdateSuccessful.current) {
        // Delete edge
        onUpdatePage(edge.source, edge.sourceHandle, null);
      }
      edgeUpdateSuccessful.current = true;
    },
    [onUpdatePage],
  );

  const onConnect: OnConnect = useCallback(
    (newEdge) => {
      if (!!newEdge.source && !!newEdge.sourceHandle) {
        onUpdatePage(newEdge.source, newEdge.sourceHandle, newEdge.target);
      }
    },
    [onUpdatePage],
  );

  const nodeTypes = useMemo(() => ({ page: PageNode }), []);

  const getLayoutedElements = useCallback((nodes, edges, options) => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setGraph({ rankdir: options.direction });
    edges.forEach((edge) => g.setEdge(edge.source, edge.target));
    nodes.forEach((node) => g.setNode(node.id, { ...node, width: 150, height: 50 }));
    Dagre.layout(g);
    return {
      nodes: nodes.map((node) => {
        const { x, y } = g.node(node.id);
        return { ...node, position: { x, y } };
      }),
      edges,
    };
  }, []);

  useEffect(() => {
    if (!pages.length) return;
    setPagesMap(pages.reduce((map, page) => ({ ...map, [page.ID + '']: page }), {}));

    const initialNodes: Node[] = pages.map((page, index) => ({
      id: page.ID + '',
      data: { funnel, page },
      position: { x: 0, y: 0 },
      type: 'page',
      className: `${page.page_type} ${funnel.page_id === page.ID ? 'index' : ''}`,
    }));
    const initialEdges: Edge[] = [
      ...pages
        .filter(({ page_id }) => !!page_id)
        .map((page) => ({
          id: `${page.ID}-${page.page_id}`,
          source: page.ID + '',
          sourceHandle: 'a',
          target: page.page_id + '',
          markerEnd: { type: MarkerType.Arrow },
          label: !!page.split
            ? page.split
                .map((cond) => (cond.type === 'percent' ? `${cond.value}%` : `${cond.param}=${cond.value}`))
                .join()
            : '',
        })),
      ...pages
        .filter(({ page_alt_id }) => !!page_alt_id)
        .map((page) => ({
          id: `${page.ID}-${page.page_alt_id}`,
          source: page.ID + '',
          sourceHandle: 'b',
          target: page.page_alt_id + '',
          markerEnd: { type: MarkerType.Arrow },
          label: !!page.split
            ? page.split.map((cond) => (cond.type === 'percent' ? `${100 - +cond.value}%` : '')).join()
            : '',
        })),
    ];
    const layouted = getLayoutedElements(initialNodes, initialEdges, { direction: 'TB' });

    setNodes([...layouted.nodes]);
    setEdges([...layouted.edges]);
  }, [funnel, pages, onNodesChange, setNodes, setEdges, setPagesMap, getLayoutedElements]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      nodesConnectable={!funnel?.locked}
      elementsSelectable={!funnel?.locked}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onEdgeUpdate={onEdgeUpdate}
      onEdgeUpdateStart={onEdgeUpdateStart}
      onEdgeUpdateEnd={onEdgeUpdateEnd}
      onConnect={onConnect}
      proOptions={{ hideAttribution: true }}
      fitView
    >
      <Controls />
    </ReactFlow>
  );
};

export default PagesFlow;
