import { atom } from 'jotai';

import { IDomain } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<IDomain[]>([]);
const allDomainAtom = atom<IDomain[]>([]);
const isLoading = atom<boolean>(false);

export interface DomainsState {
  funnelDomains: IDomain[];
  data: IDomain[];
  isLoading: boolean;
}

export const funnelDomainsAtom = atom<IDomain[]>([]);

export const domainsAtom = atom<DomainsState>((get) => ({
  funnelDomains: get(funnelDomainsAtom),
  data: get(dataAtom),
  allDomain: get(allDomainAtom),
  isLoading: get(isLoading),
}));

export const groupedDomainsAtom = atom<Array<IDomain & { children: IDomain[] }>>((get) => {
  const groups = get(allDomainAtom).reduce((group, domain) => {
    let key = '';
    if (!!domain.domain) {
      key = domain.domain.name;
    } else {
      key = domain.name;
    }
    group[key] = group[key] ?? [];
    group[key].push(domain);
    return group;
  }, {});
  return Object.keys(groups).map((key) => {
    const [main, children] = groups[key].reduce(
      (g, domain) => {
        if (domain.domain_id) {
          g[1].push(domain);
        } else {
          g[0] = domain;
        }
        return g;
      },
      [null, []],
    );
    return !!children.length
      ? {
          ...main,
          name: `${key}`,
          funnel: null,
          domain: null,
          funnel_id: 0,
          children: children,
        }
      : main;
  });
});

export const fetchDomainsAtom = atom(
  (get) => get(domainsAtom),
  async (get, set, all = false) => {
    if (!all && get(dataAtom).length) return;
    set(isLoading, true);
    $http
      .get(`/api/v1/domains/?all=${all}`)
      .json<IDomain[]>()
      .then((response) => {
        if (all) {
          set(allDomainAtom, response);
        } else {
          set(dataAtom, response);
        }
      })
      .finally(() => set(isLoading, false));
  },
);

export const fetchSubDomainsAtom = atom(
  (get) => get(domainsAtom),
  async (_, set) => {
    set(isLoading, true);
    $http
      .get('/api/v1/domains/subdomains')
      .json<IDomain[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);
export const fetchFunnelDomainsAtom = atom(
  (get) => get(domainsAtom),
  async (_, set, funnelId: number) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/funnels/${funnelId}/domains`)
      .json<IDomain[]>()
      .then((response) => set(funnelDomainsAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const createDomainAtom = atom(null, async (get, set, body?: IDomain): Promise<IDomain> => {
  set(isLoading, true);
  return $http
    .post(`/api/v1/domains/?sync=${body?.sync}`, { body })
    .json<IDomain>()
    .then((domain) => {
      if (!!domain.funnel_id) {
        set(funnelDomainsAtom, [...get(funnelDomainsAtom), domain]);
      } else {
        set(dataAtom, [...get(dataAtom), domain]);
      }
      return domain;
    })
    .finally(() => set(isLoading, false));
});

export const updateDomainAtom = atom(null, async (get, set, id: number, body: Partial<IDomain>, sync = false) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .put(`/api/v1/domains/${id}?sync=${sync}`, { body })
    .json<IDomain>()
    .then((domain) => {
      if (!!domain.funnel_id) {
        set(funnelDomainsAtom, get(funnelDomainsAtom).updateValueBy('ID', domain));
      } else {
        set(dataAtom, get(dataAtom).updateValueBy('ID', domain));
      }
    })
    .finally(() => set(isLoading, false));
});

export const deleteDomainAtom = atom(null, async (get, set, id?: number) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .delete(`/api/v1/domains/${id}`)
    .json<IDomain>()
    .then(() =>
      set(
        funnelDomainsAtom,
        get(funnelDomainsAtom).filter(({ ID }) => ID !== id),
      ),
    )
    .finally(() => set(isLoading, false));
});

export const updateBotAttackCFAtom = atom(null, async (get, set, id: number, body: Partial<IDomain>) => {
  if (!id) return;
  const domains = get(funnelDomainsAtom);
  set(isLoading, true);
  $http
    .put(`/api/v1/domains/cloud_flare/${id}`, { body })
    .json<IDomain>()
    .then((domain) => set(funnelDomainsAtom, domains.updateValueBy('ID', domain)))
    .finally(() => set(isLoading, false));
});

export const createDomainCFAtom = atom(null, async (get, set, body?: IDomain): Promise<IDomain> => {
  set(isLoading, true);
  return $http
    .post(`/api/v1/domains/cloud_flare`, { body })
    .json<IDomain>()
    .then((response) => {
      set(funnelDomainsAtom, [...get(funnelDomainsAtom), response]);
      return response;
    })
    .finally(() => set(isLoading, false));
});
