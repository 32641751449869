import { Button, Form, Input } from 'antd';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';

import { funnel, offer } from '@/atoms/quickBuild';
import { IFunnel, IOffer } from '@/types';

export const Funnel = ({ updateStep }) => {
  const [funnelAtom, setFunnelAtom] = useAtom(funnel);
  const offerAtom = useAtomValue(offer);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!offerAtom) {
      form.resetFields();
    } else {
      form.setFieldsValue({ offer: { offer_id: offerAtom?.offer_id } });
    }
  }, [form, offerAtom]);

  const onCreate = (funnelForm: IFunnel) => {
    setFunnelAtom({
      ...funnelForm,
      offer: {
        ...offerAtom,
      } as IOffer,
    });
  };

  return (
    <>
      {funnelAtom && (
        <div className="funnelCreated">
          <div className="currentFunnel">
            <div>
              <p>Name: {funnelAtom.name}</p>
              <p>
                Offer: ({funnelAtom.offer?.offer_id}) - Everflow: ({funnelAtom.offer?.ef_offer_id})
              </p>
            </div>
          </div>
        </div>
      )}

      <Form form={form} onFinish={onCreate} layout="vertical" className="funnelQuickForm">
        <Form.Item
          name="name"
          label="Insert new Funnel Name"
          rules={[{ required: true, message: 'Please input Funnel Name' }]}
        >
          <Input placeholder="Funnel Name" />
        </Form.Item>
        <Form.Item
          name={['offer', 'offer_id']}
          label="Offer ID"
          rules={[{ required: true, message: 'Please Select Offer ID' }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
