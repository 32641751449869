import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';

export enum SortOrder {
  DESCEND = 'descend',
  ASCEND = 'ascend',
}

const getValue = (obj: any, key: string) => {
  return key.split('.').reduce((o, i) => o[i] ?? {}, obj);
};

export const commonFields = (dataKey: string) => ({
  dataIndex: dataKey,
  key: dataKey,
});

export const enableFilters = (data: any = [], dataKey: string = '', filterMultiple = true) => ({
  filters: data.map((item: any) => ({ text: getValue(item, dataKey), value: getValue(item, dataKey) })).uniqBy('value'),
  filterMultiple,
  onFilter: (value: any, record: any) => record[dataKey].indexOf(value) === 0,
});

export const enableSorting = (dataKey: any, defaultSortOrder: SortOrder | null = null) => ({
  sorter: { compare: (a: any, b: any) => (getValue(a, dataKey) > getValue(b, dataKey) ? 1 : -1) },
  defaultSortOrder,
});

export const columnSearch = (dataIndex: any) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
          confirm({ closeDropdown: false });
        }}
        onPressEnter={() => confirm()}
        onBlur={() => confirm()}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters({ confirm: true, closeDropdown: true });
            setSelectedKeys([]);
          }}
          size="small"
          style={{ width: 90, color: 'red' }}
        >
          Clear
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value: any, record: any) =>
    getValue(record, dataIndex)
      ?.toString()
      .toLowerCase()
      .includes((value as string).toLowerCase()),
});
