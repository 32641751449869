import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useRef } from 'react';

import { fetchPagePreviewAtom, pageAtom } from '../../../atoms/pages';
import { MakeEditable } from './Components/Editable';

const ContentEditableAttribute = 'data-content-editable';
const ImageEditableAttribute = 'data-image-editable';

interface IFrameEditorProps {
  funnelId: number;
  remoteUrl: string;
  deviceType: string;
  onChange: ({ key, value }: { key: string; value: any }) => void;
  onDocumentLoaded: (
    document: Document,
    editableImages: Array<{ key: string; el: HTMLElement }>,
    editableProducts: Array<{ key: string; el: HTMLElement }>,
    editableLists: Array<{ key: string; el: HTMLElement }>,
    editableSizes: Array<{ key: string; el: HTMLElement }>,
    editableAddons: Array<{ key: string; el: HTMLElement[] }>,
    editableWarranty: Array<{ key: string; el: HTMLElement }>,
  ) => void;
}

const IFrameEditor = ({ funnelId, remoteUrl, deviceType, onChange, onDocumentLoaded }: IFrameEditorProps) => {
  const [html, fetchPagePreview] = useAtom(fetchPagePreviewAtom);
  const page = useAtomValue(pageAtom);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const toolbarSelector = `[${ContentEditableAttribute}] + div.content-edit-toolbar`;
  const customStyle = `
  [${ContentEditableAttribute}] {
    position: relative;
    background: rgb(173, 255, 47, .1);
    outline-width: 1px;
    outline-style: solid;
    outline-color: rgb(173, 255, 47);
    color: blue;
  }
  [${ContentEditableAttribute}]:hover {
    background: rgb(173, 255, 47, .5);
  }
  [${ContentEditableAttribute}]:focus {
    background: transparent;
    outline-width: 1px;
    outline-style: solid;
    outline-color: #d9d9d9;
  }
  [${ContentEditableAttribute}]:focus + div.content-edit-toolbar {
    display: flex;
  }
  ${toolbarSelector} {
    display: none;
    position: absolute;
    background: #f5f5f5;
    padding: 10px;
    margin: 1px 0 0;
    z-index: 1;
  }
  ${toolbarSelector} button {
    color: rgba(0,0,0,.88);
    background: #fff;
    border-color: #d9d9d9;
    box-shadow: 0 2px 0 rgba(0,0,0,.02);
    padding: 3px;
    margin: 0;
    border-radius: 10px;
    border: 1px solid gray;
  }
  ${toolbarSelector} button:hover {
    color: #4096ff;
    border-color: #4096ff;
  }
  ${toolbarSelector} button + button {
    margin-left: 3px;
  }
  ${toolbarSelector} > span {
    width: 1px;
    background: #d9d9d9;
    margin: 0 6px;
  }
  .logo {
    width:150px;
  }
  editable.focused > * {
    outline: 5px solid greenyellow;
  }
  editable img {
    cursor: zoom-in;
  }
  * {
    float:none !important;
  }
  .pkg-hdng{
    float:none !important;
  }
  .buyopt {
    float: none !important;
  }
  .buyopt label {
    cursor:pointer;
  }
  .buyopt:hover {
    background:#e5e5e557 !important;
    box-shadow:2px 2px 4px black;
  }
  .buyopt:hover label {
    opacity:0.3;
  }
  .buyopt:hover + .editIcon {
    display:block;
  }
  .s1-list li:hover {
    background:#e5e5e557 !important;
    cursor:pointer;
    box-shadow:2px 2px 4px black;
  }
  .s1-list li:hover .editListIcon {
    display:block;
  }
  .addonEl {
    position: relative;
    display:flex;
    align-items:center;
  } 
  .selectr-grpBox:hover  {
    background:#e5e5e557 !important;
    cursor:pointer;
    box-shadow:2px 2px 4px black;
    opacity:0.3;
  }
  .journey_protection:hover {
    background:#e5e5e557 !important;
    cursor:pointer;
    box-shadow:2px 2px 4px black;
    opacity:0.3;
    transition: all .5s ease;
  }
  .journey_protection .switch {
    margin-left: 20px !important;
  }
  .add_onBac:hover + .editIcon {
    display:block;
  }
  .s1-list li:hover p,.s1-list li:hover img {
    opacity:0.3;
  }
  
  .override {
    font-size: 14px;
    font-weight: bold;
    margin: 10px 0;
  }
  .addicon {
    display: block;
    font-size:32px;
    color:#4f4f4f;
    padding:10px;
    line-height: 30px;
    box-shadow: 2px 2px 1px #8d8d8d;
    margin: 10px auto;
    border-radius: 20px;
    background: white;
    position:relative;
  }
  .addicon {
    background: #10f53c;
    color:white;
  }
  .warrantyFrame3 .warranty_header_journey {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  
  .addListIcon {
    padding:10px;
    margin:10px;
    color:#4f4f4f;
    background: #ebebeb;
    font-size:32px;
    display: block;
    width:70px;
    height:70px;
  }
  .addicon:hover, .addListIcon:hover {
    cursor:pointer;
    box-shadow: none;
  }
  .pulsing {
    animation-name: pulsing;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
  @keyframes pulsing {
    0% {
      transform: scale(0.98);
      box-shadow:1px 2px 3px black;
    }
    50% {
      transform: scale(1);
      box-shadow:none;
    }
    100% {
      transform: scale(0.98);
      box-shadow:1px 2px 3px black;
    }
  }
  
  .wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }
  .listWrapper {
    display: flex;
    align-items: center;
  }
  .package-header {
    display:flex;
  }
  .editIcon {
    position: absolute;
    left: 40%;
    top: 25%;
    color:black;
    display:none;
    pointer-events: none;
    font-size:30px;
  }
  .editListIcon {
    position: absolute;
    left: 40%;
    top: 20%;
    color:black;
    display:none;
    pointer-events: none;
    font-size:20px;
    transition:display 0.3s ease;
  }
  .wrapper_delete {
    padding: 5px;
    margin-left: 5px;
    color: black;
    font-size:20px;
    cursor:pointer;
  }
  .wrapper_delete:hover {
    opacity:0.3;
  }
  .wrapper_delete.pulsing {
    background-color:unset !important;
    border: none !important;
  }
  .kinetic label {
    width:100%;
  }
  .kinetic .package-best-seller,
  .kinetic .package-bullet-wrapper,
  .kinetic .package-content,
  .kinetic .freeship
   {
    display:none
  }
  .kinetic .package-title {
    display:block;
  }
  .kinetic .package-main-save {
    display:block;
    font-weight: 500;
    color: #bf4800;
  }
  .kinetic .blu_blissy {
    font-family: unset;
  }
  .kinetic .select-button {
    display:none !important;
  }
  .kinetic .buy-opt-left .package-title, .kinetic .package-main-title_blissy {
    font-size: 16px;
    line-height: 25px;
  }
  .kinetic ul.s1-list{
    justify-content:center !important;
    align-items:center;
  }
  .kinetic ul.s1-list li {
    position: relative;
    margin:0 5px !important;
  }
  .kinetic .colourSection ul {
    justify-content:center;
    align-items:center;
  }
  .kinetic .checkout-section .container{
    display:flex;
    justify-content:space-between;
    flex-wrap: wrap;
  }
  .kinetic .payment-flds-box.credit-card {
    display: flex;
    flex-wrap: wrap;
  }
  .addicon.journey_addon::after, 
  .addicon.addon_normal::after,
  .addicon.main_products::after {
    position: absolute;
    right: 0;
    top: -29px;
    background: #00000099;
    font-size: 12px;
    border-radius: 20px;
    padding: 10px;
    transition: 0.3s ease;
    display:none;
    color:white;
  }
  .addonFrame6 .selectr-grpBox h5, .addonFrame6 section {
    display: none !important;
  }
  .addonFrame6 .select-color-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .addicon.journey_addon::after {
    content: "add journey package protection";
  }
  .addicon.addon_normal::after {
    content: "add extra products";
  }
  .addicon.main_products::after {
    content: "add products";
  }
  .addicon.journey_addon:hover::after,
  .addicon.addon_normal:hover::after,
  .addicon.main_products:hover::after {
    display:block;
  }
  .kinetic .warrantyClass:hover, .blissy-checkout .warrantyClass:hover {
    background: #e5e5e557 !important;
    box-shadow: 2px 2px 4px black;
    cursor:pointer;
  }
  .kinetic .form-check-label {
    margin-bottom:10px !important;
  }
  .kinetic .form-check-label input {
    margin-right:10px;
  }
  .kinetic .slider {
    background-color: #2196f3 !important;
  }
  .kinetic .features {
    display: none;
  }
  .listWrapper {
    display:flex;
    flex-direction:column;
    margin:0 10px !important;
  }
  .enableExtraProducts {
    width: 100%;
    text-align: center;
    padding: 10px !important;
    background-color:rgb(169 169 169 / 50%);
    font-size: 18px;
    font-weight: 700;
    user-select: none;
    cursor: pointer;
  }
  .enableExtraProducts:hover {
    opacity: 0.3;
  }
  .blissy .package-title {
    display:flex;
    align-items: center;
  }
  .blissy .buy-opt-rgt{
    display:none;
  }
  .blissy .package-content {
    display: flex;
  }
  .blissy {
    width:100%;
    position: relative;
  }
  .blissy .select-button  {
    bottom:80px !important;
  }
  .blissy .features {
    display: flex !important;
    background: #265f91 50%;
    color: #fff;
    font-size: 8.5px;
    border-radius: 5px;
    font-weight: 500;
  }
  .add-box_blissy {
    border: unset;
    background: unset;
  }
  .upsellList .package-header,
  .upsellList .package-content,
  .upsellList .buy-opt-rgt,
  .upsellList .features,
  .upsellList .package-bullet-wrapper {
    display: none;
  }
  .addonFrame5 .desc {
    display:flex;
    align-items: flex-start;
  }
  .upsellList {
    border: 1px solid #cecece;
    border-radius: 7px;
    padding: 3px;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
  }
  .upsellList li span{
    display: block;
    width: 45px;
    background: #e5f1ee;
    text-align: center;
    margin: 0 1px;
    padding: 3px 0;
    border-radius: 2px;
    font-weight: 700;
    color: #74c6b4;
    cursor: pointer;
    font-size: 15px;
  }
  h5.chiller_cb {
    display:inherit;
  }
  .upsellList label {
    margin:0;
  }
  .no_warranty {
    font-weight: bold;
    text-align: center;
  }
  .up-prcBox {
    display:flex;
  }
  .product-main:has(.upsell-products-list){
    display: flex;
  }
  .up-section1 .container form{
    display:flex;
  }
  .overlay {
    display: none !important;
  }
  .warrantyFrame5 .warranty_header_journey {
    display:flex;
    flex-direction:row-reverse;
  }
  p.blissy_warranty > span:nth-child(3) {
    font-weight:normal !important;
    color: #212529 !important;
  }
  `;

  useEffect(() => {
    fetchPagePreview(remoteUrl, customStyle);
  }, [remoteUrl, customStyle, fetchPagePreview]);

  const initHandlers = useCallback((doc: Document) => {
    if (!page?.data) return;
    // Init editable text
    const meta = page.data.meta ?? page.data.template?.meta;
    [...doc.querySelectorAll<HTMLElement>(`[${ContentEditableAttribute}]`)].forEach((element) => {
      const dataKey = element.dataset.contentEditable ?? '';
      const text = (meta ?? {})[dataKey] ?? element.innerText;
      !!element &&
        MakeEditable({
          element,
          funnelId,
          props: {
            doc,
            text,
            dataKey,
            containerProps: { [ContentEditableAttribute]: dataKey },
            onChange,
          },
        });
    });

    // Get editable images
    let editableImages: Array<{ key: string; el: HTMLElement }> = [];
    [...doc.querySelectorAll<HTMLImageElement>(`[${ImageEditableAttribute}]`)].forEach((element) => {
      if (!element || !element.dataset.imageEditable) return;
      const key = element.dataset.imageEditable;
      const el = MakeEditable({
        element,
        funnelId,
        props: {
          dataKey: key,
          onChange,
          className: 'pulsing',
        },
      });
      editableImages.push({ key, el });
    });

    // Get editable products
    let editableProducts: Array<{ key: string; el: HTMLElement }> = [];
    let productEl = doc.querySelector('#checkout-product-container') as HTMLDivElement;
    const el =
      !!productEl &&
      MakeEditable({
        element: productEl,
        funnelId,
        props: {
          doc,
          className: 'main_products',
          onChange,
        },
      });

    editableProducts.push({ key: 'products', el });

    // Get editable sizes
    let editableSizes: Array<{ key: string; el: HTMLElement }> = [];
    let sizeEl = doc.querySelector('.sizeSelection') as HTMLDivElement;
    const sizelement =
      !!sizeEl &&
      MakeEditable({
        element: sizeEl,
        funnelId,
        props: {
          doc,
          className: 'pulsing',
          onChange,
        },
      });

    editableSizes.push({ key: 'productSizes', el: sizelement });

    // Get editable addons
    let editableAddons: Array<{ key: string; el: HTMLElement[] }> = [];
    let addonEl = doc.querySelector('.addon-container') as HTMLDivElement;
    let journeyEl = doc.querySelector('.journey_protection') as HTMLDivElement;
    const addon = !!journeyEl
      ? [
          MakeEditable({
            element: journeyEl,
            funnelId,
            props: {
              doc,
              className: 'journey_addon',
              onChange,
            },
          }),
          MakeEditable({
            element: addonEl,
            funnelId,
            props: {
              doc,
              className: 'addon_normal',
              onChange,
            },
          }),
        ]
      : addonEl && [
          MakeEditable({
            element: addonEl,
            funnelId,
            props: {
              doc,
              className: 'addon_normal',
              onChange,
            },
          }),
        ];

    editableAddons.push({ key: 'productAddons', el: addon });

    // Get editable list
    let editableLists: Array<{ key: string; el: HTMLElement }> = [];
    [...doc.querySelectorAll<HTMLDivElement>('.s1-list')].forEach((element) => {
      const list =
        !!element &&
        MakeEditable({
          element,
          funnelId,
          props: {
            doc,
            className: 'pulsing',
            onChange,
          },
        });
      editableLists.push({ key: 'list', el: list });
    });

    // Get editable warranty
    let editableWarranty: Array<{ key: string; el: HTMLElement }> = [];
    let warrantyEl = doc.querySelector('.warrantyClass') as HTMLDivElement;
    const warranty =
      !!warrantyEl &&
      MakeEditable({
        element: warrantyEl,
        funnelId,
        props: {
          doc,
          className: 'pulsing',
          onChange,
        },
      });

    editableWarranty.push({ key: 'warranty', el: warranty });

    onDocumentLoaded(
      doc,
      editableImages.uniqBy('key'),
      editableProducts,
      editableLists,
      editableSizes,
      editableAddons,
      editableWarranty,
    );

    doc.querySelectorAll('button').forEach((v) => v.setAttribute('disabled', 'disabled'));
    doc.querySelectorAll('a').forEach((v) => v.addEventListener('click', (e) => e.preventDefault()));
    doc.querySelectorAll('img').forEach((v: any) => {
      if (v) {
        v.setAttribute('src', v.getAttribute('data-src'));
        v.className = v.className.replace('lazyload', '').replace('blur-up', '');
        let hasClass = v.className.includes('orderd-product-img');
        if (hasClass) {
          v.src = 'https://placehold.co/800?text=Image+From+Selected+Product';
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!iframeRef.current?.contentWindow?.document) return;
    const doc = iframeRef.current.contentWindow?.document;
    doc.open();
    doc.write(html);
    initHandlers(doc);
    doc.close();
  }, [html, initHandlers]);

  return <iframe id="template_editor" title="template editor" ref={iframeRef} className={deviceType} />;
};

export default IFrameEditor;
