import { MinusCircleOutlined, PlusCircleOutlined, SwapOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';

import { fetchAuditLogsAtom } from '@/atoms/auditLog';
import { IRouteParams } from '@/types';
import { columnSearch, commonFields, enableSorting, SortOrder } from '@/utils/table';

import Loading from '../../Loading';

const FunnelLogs = ({ id }: IRouteParams) => {
  const [auditLog, fetchAuditLogs] = useAtom(fetchAuditLogsAtom);

  useEffect(() => {
    fetchAuditLogs(id);
  }, [fetchAuditLogs, id]);

  const getOpIcon = (op: string) => {
    switch (op) {
      case 'add':
        return <PlusCircleOutlined />;
      case 'remove':
        return <MinusCircleOutlined />;
      case 'replace':
        return <SwapOutlined />;
    }
  };

  const getOpColor = (op: string) => {
    switch (op) {
      case 'add':
        return 'green';
      case 'remove':
        return 'red';
      case 'replace':
        return 'blue';
    }
  };
  const columns = useMemo(() => {
    return [
      {
        title: 'Date',
        ...commonFields('CreatedAt'),
        ...enableSorting('CreatedAt', SortOrder.DESCEND),
        render: (value: any) => {
          const date = new Date(value);
          return <span style={{ whiteSpace: 'nowrap' }}>{date.toLocaleString()}</span>;
        },
      },
      {
        title: 'Type',
        ...commonFields('type'),
        ...enableSorting('type'),
        ...columnSearch('type'),
      },
      {
        title: 'Title',
        ...commonFields('title'),
        ...enableSorting('title'),
        ...columnSearch('title'),
      },
      {
        title: 'Message',
        ...commonFields('message'),
        ...columnSearch('message'),
        render: (value: any, { type }: any) => {
          if (type !== 'diff') {
            return value;
          }
          const log = JSON.parse(value);
          return (
            <pre>
              {log
                .filter(({ op }) => op !== 'test')
                .map(({ op, path, value }, index) => {
                  return (
                    <p key={index} style={{ color: getOpColor(op) }}>
                      {getOpIcon(op)}
                      <span>{path}</span>:<span>{JSON.stringify(value)}</span>
                    </p>
                  );
                })}
            </pre>
          );
        },
      },
      {
        title: 'User',
        ...commonFields('user.name'),
        ...enableSorting('user.name'),
        ...columnSearch('user.name'),
        render: (_: any, log: any) => log.user?.name,
      },
      {
        title: 'Task URL',
        ...commonFields('task_url'),
        ...enableSorting('task_url'),
        ...columnSearch('task_url'),
      },
    ];
  }, []);

  if (auditLog.isLoading) {
    return <Loading />;
  }
  return <Table dataSource={auditLog.data} rowKey="ID" columns={columns} pagination={false} />;
};

export default FunnelLogs;
