import { Col, Form, Input, InputNumber, Row } from 'antd';
import { useEffect } from 'react';

import { ICategory, IFormProps } from '@/types';

export const CategoryEditForm = ({ formData, onFormReady }: IFormProps<ICategory>) => {
  const [form] = Form.useForm();
  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <Form.Item
        label="Insert Category Name from Sticky.io"
        name="name"
        rules={[{ required: true, message: 'Missing Category Name' }]}
      >
        <Input style={{ width: '100%' }} placeholder="Category Name" />
      </Form.Item>
      {formData?.ID && (
        <Form.Item
          hidden
          label="Insert Category ID from Sticky.io"
          name="sticky_id"
          rules={[
            { required: true, message: 'Missing Category Sticky ID' },
            { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
          ]}
        >
          <InputNumber style={{ width: '100%' }} placeholder="Category Sticky ID" maxLength={5} />
        </Form.Item>
      )}
      <h4>Fill Email Trigers in fields for this Category:</h4>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Shipping Confirmation"
            name="event_shipping"
            rules={[
              { required: false, message: 'Missing Shipping Confirmation ID' },
              { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
            ]}
          >
            <Input placeholder="Shipping Confirmation ID" maxLength={5} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Order Confirmation"
            name="event_order"
            rules={[
              { required: false, message: 'Missing Order Confirmation ID' },
              { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
            ]}
          >
            <Input placeholder="Order Confirmation ID" maxLength={5} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Order Refund"
            name="event_refund"
            rules={[
              { required: false, message: 'Missing Order Refund ID' },
              { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
            ]}
          >
            <Input placeholder="Order Refund ID" maxLength={5} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Void Notification"
            name="event_void"
            rules={[
              { required: false, message: 'Missing Void Notification ID' },
              { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
            ]}
          >
            <Input placeholder="Void Notification ID" maxLength={5} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="RMA Notification"
            name="event_rma"
            rules={[
              { required: false, message: 'Missing RMA Notification ID' },
              { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
            ]}
          >
            <Input placeholder="RMA Notification ID" maxLength={5} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Return Notification"
            name="event_return"
            rules={[
              { required: false, message: 'Missing Return Notification ID' },
              { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
            ]}
          >
            <Input placeholder="Return Notification ID" maxLength={5} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
