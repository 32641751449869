import { atom } from 'jotai';

import { IRole } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<IRole[]>([]);
const isLoading = atom<boolean>(false);

export interface RolesState {
  data: IRole[];
  isLoading: boolean;
}

export const rolesAtom = atom<RolesState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
}));

export const fetchRolesAtom = atom(
  (get) => get(rolesAtom),
  async (_, set) => {
    set(isLoading, true);
    $http
      .get('/api/v1/roles')
      .json<IRole[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);
