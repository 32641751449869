import { CloseOutlined } from '@ant-design/icons';
import Editor from '@monaco-editor/react';
import { Alert, Button, Checkbox, Form, Image, Input, message, Modal, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useAtom, useAtomValue } from 'jotai';
import { useState } from 'react';

import { updateMetaAtom } from '../../../../../atoms/pages';
import { productsAtom } from '../../../../../atoms/products';
import { IFrame } from '../../../../../types';
import { containsNumber, getNumberFromString } from '../../../../../utils/numbers';
import { DragAndDrop } from '../../../../UI/DragAndDrop';
import { AddAddonsProps, ClassPrice } from './Index';

export const AddAddons = ({
  onAdd,
  active,
  onCancel,
  onDeleteProduct,
  onFrameChange,
  frames,
  pageData,
  onAddonTypeChange,
}: AddAddonsProps) => {
  const [form] = Form.useForm();
  const [, updateMeta] = useAtom(updateMetaAtom);
  const products = useAtomValue(productsAtom);
  const [inputValues, setInputValues] = useState([]) as any;
  const [addedProducts, setAddedProducts] = useState([]) as any;
  const [kind, setKind] = useState('');
  const [color, setColor] = useState('');
  const [html, setHtml] = useState('');
  const [frameValid, setFrameValid] = useState(true);

  const randstr = (prefix: any) => {
    return Math.random()
      .toString(36)
      .replace('0.', prefix || '');
  };

  const Finish = (values: any) => {
    let selectedAddon = frames.find((frame: any) => frame?.checked) as IFrame;
    if (!selectedAddon) {
      setFrameValid(false);
      return;
    }
    let { frame } = selectedAddon;

    if (!addedProducts.length) {
      message.error('No added Products');
      return;
    }

    const data = {
      id: randstr('For Now:'),
      name: values.name,
      products: addedProducts.sort((a: any, b: any) => a.quantity - b.quantity),
      description: html,
      frame: frame,
      type: kind,
      color: color === '' ? 'blue' : color,
    };

    onAdd(data);
    form.resetFields();
    setAddedProducts([]);
  };

  const onChange = () => {
    const tempArr: any[] = [];
    let checkedValues = products.data.filter((p: any) => inputValues.some((i: any) => +i === p.ID));
    checkedValues.forEach((value: any) => {
      const product = products.data.find((prod: any) => prod.ID === value.ID);
      if (!product) return;
      const productquantity = containsNumber(product.name.toString());
      const dataToAdd: any = {
        id: product.product_id,
        data_name: product.name,
        data_box_name: product.name,
        data_price: product.product_price,
        data_discount_price: product.product_price,
        quantity: !productquantity ? 1 : getNumberFromString(product.name),
      };
      tempArr.push(dataToAdd);
    });
    setAddedProducts([...tempArr]);
  };

  const handleChangeType = (value: string) => {
    setKind(value);
    onAddonTypeChange(value);
  };

  const onColorAddonChange = (value: string) => {
    setColor(value);
  };

  const checkClass = () => {
    let matchCheck = html.match('remote-each-price');
    if (matchCheck?.length) {
      return true;
    } else {
      return false;
    }
  };

  const onPriceChange = () => {
    let newHtml;
    if (checkClass()) {
      newHtml = html.replace(ClassPrice.each, ClassPrice.full);
    } else {
      newHtml = html.replace(ClassPrice.full, ClassPrice.each);
    }
    setHtml(newHtml);
  };

  return (
    <Modal open={active} onOk={() => form.submit()} onCancel={onCancel} className="addonModal">
      <p className="info_text">
        Addon Type <i>(Journey or Normal)</i>:
      </p>
      <Select
        defaultValue="normal"
        style={{ width: 150 }}
        onChange={handleChangeType}
        options={[
          { value: 'normal', label: 'Normal' },
          { value: 'journey', label: 'Journey' },
        ]}
      />
      <p className="info_text">Choose frame:</p>
      <div className="addonFrames">
        {!!frames &&
          frames.map((img) => {
            if (!img.hidden) {
              return (
                <div key={img.frame} className="addonFrame">
                  <Checkbox checked={img.checked} onChange={() => onFrameChange(img)}>
                    Click
                  </Checkbox>
                  <Image width={150} src={img.img} />
                </div>
              );
            }
            return null;
          })}
      </div>
      {!frameValid && (
        <Alert
          showIcon={true}
          style={{ textAlign: 'center', marginTop: 10 }}
          message="Please select product extra frame"
          type="error"
        />
      )}
      <p className="info_text">Bolded Info Text:</p>
      <Form form={form} onFinish={Finish}>
        <FormItem name="name">
          <Input placeholder="Info Text" />
        </FormItem>
        {!!frames &&
          frames.map((image, index) => {
            if (image.checked) {
              return (
                <>
                  <div key={index} style={{ marginBottom: 20 }}>
                    {image.styles && image.styles.colors && (
                      <>
                        <p className="info_text">
                          Pick Color <i>(this frame has colors)</i>:
                        </p>
                        <Select
                          defaultValue="blue"
                          className="colorSelect"
                          onChange={onColorAddonChange}
                          options={image.styles.colors.map((color) => {
                            return {
                              value: color,
                              label: <span className={`colorSpan ${color}`}>{color}</span>,
                            };
                          })}
                        />
                      </>
                    )}
                    {image.html && (
                      <>
                        <p className="info_text">Extra Product Description HTML:</p>
                        <Editor
                          options={{
                            fontSize: 12,
                            formatOnPaste: true,
                            formatOnType: true,
                            minimap: { enabled: false },
                            lineNumbers: 'off',
                            autoIndent: 'full',
                            tabSize: 2,
                            wordWrap: 'on',
                          }}
                          height="200px"
                          language="html"
                          theme="vs-dark"
                          value={image.html}
                          onChange={(e: any) => setHtml(e)}
                          onMount={(editor) => setHtml(editor.getValue())}
                        />
                        <Button className="changer" type="primary" onClick={onPriceChange}>
                          Switch to {checkClass() ? 'full' : 'each'} price
                        </Button>
                      </>
                    )}
                  </div>
                </>
              );
            }
            return null;
          })}
        <p className="info_text">Products:</p>
        <Select
          showSearch
          style={{ width: '100%' }}
          mode="multiple"
          placeholder="Select product"
          optionFilterProp="children"
          onChange={setInputValues}
          value={inputValues}
        >
          {Array.from(new Set(products.data.map((a: any) => a.product_id)))
            .map((id: any) => {
              return products.data.find((a: any) => a.product_id === id);
            })
            .map((prod) => {
              if (!prod) return null;
              return (
                <Select.Option key={prod.ID}>
                  ({prod.product_id}){prod.name}
                </Select.Option>
              );
            })}
        </Select>
        {inputValues.length > 0 && (
          <Button className="addButton" onClick={onChange}>
            Add All
          </Button>
        )}
        <p className="info_text">Selected Products:</p>
        <div className="addon_products">
          {addedProducts.length > 0 ? (
            addedProducts.map((added: any) => (
              <div key={added.id}>
                {added.id}{' '}
                <CloseOutlined onClick={() => onDeleteProduct(added.id, setAddedProducts)} className="removeAddon" />
              </div>
            ))
          ) : (
            <span className="noAdded">No products selected</span>
          )}
        </div>
      </Form>
      <p className="alreadyAdd">Already Added:</p>
      {!!pageData && !!pageData.meta && !!pageData.meta.productAddons && !!pageData.meta.productAddons.length ? (
        <>
          <DragAndDrop
            listChange={(item) => updateMeta(item)}
            meta={!!pageData && !!pageData.meta && !!pageData.meta.productAddons && pageData.meta.productAddons}
            type="productAddons"
          />
        </>
      ) : (
        <p className="noAdded">No added Addons</p>
      )}
    </Modal>
  );
};
