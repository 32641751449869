import { Form, Input, Modal } from 'antd';

export const ReviewAddForm = ({ active, onAdd, onCancel }: any) => {
  const [form] = Form.useForm();

  const randstr = (prefix: any) => {
    return Math.random()
      .toString(36)
      .replace('0.', prefix || '');
  };

  const onFinish = ({ avatar, user, review, review_header }: any) => {
    onAdd({
      id: randstr('For_Now:'),
      avatar,
      user,
      review,
      review_header,
    });

    form.resetFields();
  };

  return (
    <Modal
      open={active}
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="avatar" label="Customer Avatar Image">
          <Input />
        </Form.Item>
        <Form.Item name="user" label="Customer Name">
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="review_header" label="Customer Review Header">
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="review" label="Customer Review">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
