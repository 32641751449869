import { Col, Form, InputNumber, Modal, Row } from 'antd';
interface ParentProps {
  listChange: any;
  visible: any;
  handleVisible: any;
}
const RewardsModal = ({ listChange, visible, handleVisible }: ParentProps) => {
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    listChange(values);
  };
  return (
    <Modal
      title="Special Rewards"
      className="frame_modal"
      open={visible}
      onOk={() => {
        form.submit();
        handleVisible();
      }}
      onCancel={handleVisible}
    >
      <Form form={form} onFinish={onFinish} className="rewardsForm">
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="productId_cc" label="product Id creditCard">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="productId_pp" label="product Id paypal">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default RewardsModal;
