import { Checkbox, Form, Input, InputNumber } from 'antd';
import { useEffect } from 'react';

import { IFormProps } from '../../types';
import { IShipping } from '../../types';

export const ShippingEditForm = ({ formData, onFormReady }: IFormProps<IShipping>) => {
  const [form] = Form.useForm();

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      {!formData?.ID && (
        <Form.Item
          name="ID"
          label="Sticky Shipping Id"
          rules={[{ required: true, message: 'Please input Sticky Shipping Id' }]}
        >
          <InputNumber style={{ width: '100%' }} maxLength={3} placeholder="Sticky Shipping ID" />
        </Form.Item>
      )}
      {!!formData?.ID && (
        <>
          <Form.Item
            style={{ width: '100%' }}
            name="name"
            rules={[{ required: true, message: 'Missing Shipping Name' }]}
            label="Shipping name"
          >
            <Input style={{ width: '100%' }} placeholder="Shipping name" />
          </Form.Item>
          <Form.Item
            style={{ width: '100%' }}
            name="description"
            rules={[{ required: true, message: 'Missing Shipping Description' }]}
            label="Shipping Description"
          >
            <Input style={{ width: '100%' }} placeholder="Shipping Description" />
          </Form.Item>
          <Form.Item
            style={{ width: '100%' }}
            name="price"
            rules={[{ required: true, message: 'Missing Shipping price' }]}
            label="Shipping Price"
          >
            <InputNumber style={{ width: '100%' }} placeholder="Shipping Price" />
          </Form.Item>
        </>
      )}
      <Form.Item style={{ width: '100%' }} name="shipping_code" label="Shipping Code (29 Next)">
        <Input style={{ width: '100%' }} placeholder="Shipping Code" />
      </Form.Item>
      {!!formData?.ID && (
        <Form.Item name="sync" valuePropName="checked">
          <Checkbox>Update on Sticky</Checkbox>
        </Form.Item>
      )}
    </Form>
  );
};
