import { ExclamationCircleOutlined, UndoOutlined } from '@ant-design/icons';
import { useAtom } from 'jotai';
import { Link, useLocation } from 'react-router-dom';

import { errorAtom } from '../../atoms/error';

export const ErrorComponent = () => {
  const [error, setError] = useAtom(errorAtom);
  const router = useLocation();

  return (
    <div className="errorMessageOverLay">
      <div className="erroMessageContainer">
        <p className="errorIcon">
          <ExclamationCircleOutlined />
        </p>
        <p className="errorInfo">{error}</p>
        <Link className="refreshLink" onClick={() => setError('')} to={router.pathname}>
          Try again <UndoOutlined className="refrashIcon" />
        </Link>
        <p className="errorContact">If error still occur please contact us or try again later.</p>
      </div>
    </div>
  );
};
