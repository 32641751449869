import { Card } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export const DragAndDrop = ({ listChange, meta, type }) => {
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    let currentProductList = meta;
    var updatedList = [...currentProductList];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    listChange({ [type]: updatedList });
  };

  return (
    <div className="cards">
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div className="list-container" {...provided.droppableProps} ref={provided.innerRef}>
              {!!meta &&
                meta.map((item, index) => (
                  <Draggable
                    key={
                      type === 'list' || type === 'productAddons'
                        ? item.id
                        : type === 'products'
                        ? item.product_id
                        : item
                    }
                    draggableId={
                      type === 'list' || type === 'productAddons'
                        ? item.id
                        : type === 'products'
                        ? item.product_id
                        : item
                    }
                    index={index}
                  >
                    {(provided) => (
                      <Card
                        className="item-container card-products"
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                      >
                        {type === 'list' ? (
                          <div>text: {item.listText}</div>
                        ) : type === 'products' ? (
                          <div>
                            ({item.product_id}){item.product_data_name}
                          </div>
                        ) : type === 'productAddons' ? (
                          <div>
                            ({item.id}){item.name}
                          </div>
                        ) : (
                          <div>{item}</div>
                        )}
                      </Card>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
