import './SignIn.css';

import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';

import HydraLogo from '../assets/hydra-logo-lines.svg';
import { getConfig } from '../config';
import Loading from './Loading';

const config = getConfig();

const SignIn = (props: any) => {
  const { loginWithRedirect, isLoading } = useAuth0();
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="loginPage">
      <div style={{ textAlign: 'center' }}>
        <img src={HydraLogo} alt="logo" />
        <h3 className="title_signin_in">Welcome to Hydra CMS</h3>
        <Button type="primary" onClick={() => loginWithRedirect({ audience: config.audience })}>
          Sign in
        </Button>
      </div>
    </div>
  );
};

export default SignIn;
