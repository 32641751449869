import { InfoCircleFilled } from '@ant-design/icons';
import { Checkbox, Form, Modal } from 'antd';
import { useReducer, useState } from 'react';

import { InternalActionKind, InternalActionType, InternalState } from '../../../../../types';
import { CustomTooltip } from '../../../../UI/CustomTooltip';

export const reducer = (state: InternalState, action: InternalActionType) => {
  const { type, payload } = action;
  switch (type) {
    case InternalActionKind.BLUE:
      return {
        ...state,
        checkBlue: true,
        checkGreen: false,
        checkOrange: false,
        main_color: 'blue',
      };
    case InternalActionKind.GREEN:
      return {
        ...state,
        checkBlue: false,
        checkGreen: true,
        checkOrange: false,
        main_color: 'green',
      };
    case InternalActionKind.ORANGE:
      return {
        ...state,
        checkBlue: false,
        checkGreen: false,
        checkOrange: true,
        main_color: 'orange',
      };
    case InternalActionKind.STOCK:
      return {
        ...state,
        outOfStock: payload,
      };
    case InternalActionKind.SHIPPING:
      return {
        ...state,
        withShipping: payload,
      };
    case InternalActionKind.FULLPRICE:
      return {
        ...state,
        withFullPrice: payload,
      };
    default:
      return state;
  }
};

const EditFrames = ({ savedFields, pageData, listChange }: any) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [state, dispatch] = useReducer(reducer, {
    checkBlue: savedFields && savedFields.main_color === 'blue' ? true : false,
    checkGreen: savedFields && savedFields.main_color === 'green' ? true : false,
    checkOrange: savedFields && savedFields.main_color === 'orange' ? true : false,
    withShipping: savedFields && savedFields.withShipping === 'true' ? true : false,
    withFullPrice: savedFields && savedFields.withFullPrice === 'true' ? true : false,
    outOfStock: savedFields && savedFields.outOfStock === 'true' ? true : false,
    main_color: savedFields && savedFields.main_color ? savedFields.main_color : '',
  });

  // const onColorBlueChangeHandler = () => {
  //   dispatch({ type: InternalActionKind.BLUE });
  // };

  // const onColorGreenChangeHandler = () => {
  //   dispatch({ type: InternalActionKind.GREEN });
  // };

  // const onColorOrangeChangeHandler = () => {
  //   dispatch({ type: InternalActionKind.ORANGE });
  // };

  const onStockChangeHandler = (e) => {
    dispatch({ type: InternalActionKind.STOCK, payload: e.target.checked });
  };

  const onShippingChangeHandler = (e) => {
    dispatch({ type: InternalActionKind.SHIPPING, payload: e.target.checked });
  };

  const onSummaryChangeHandler = (e) => {
    dispatch({ type: InternalActionKind.FULLPRICE, payload: e.target.checked });
  };

  const onFinished = () => {
    listChange({
      main_color: state.main_color,
      withShipping: `${state.withShipping}`,
      withFullPrice: `${state.withFullPrice}`,
      outOfStock: `${state.outOfStock}`,
    });
    setIsModalVisible(false);
  };

  return (
    <Modal
      title="Template - Colors,Frames"
      className="frame_modal"
      open={isModalVisible}
      onOk={onFinished}
      onCancel={() => setIsModalVisible(false)}
    >
      <div className="upsell_multi">
        {pageData.template.meta.outOfStock && (
          <Form>
            <Checkbox onChange={onStockChangeHandler} checked={state.outOfStock}>
              <span>Out Of Stock </span>
              <CustomTooltip
                icon={<InfoCircleFilled />}
                text="Check this if there is no products left in a stock,and there will be no submit button to procced with payment"
              />
            </Checkbox>
          </Form>
        )}
        {/* {pageData.template.meta.main_color && (
          <Form>
            <p>Color</p>
            <div className="checkboxes">
              <div
                className="bluecheckbox"
                style={{
                  opacity: state.checkGreen ? '0.6' : '1',
                  transform: state.checkBlue ? 'scale(1.2)' : 'scale(1)',
                }}
              >
                <Checkbox checked={state.checkBlue}></Checkbox>
                <div onClick={onColorBlueChangeHandler} className="color_overlay">
                  {state.checkBlue && <CheckCircleOutlined style={{ color: 'white', fontSize: '20px' }} />}
                </div>
              </div>
              <div
                className="greencheckbox"
                style={{
                  opacity: state.checkBlue ? '0.6' : '1',
                  transform: state.checkGreen ? 'scale(1.2)' : 'scale(1)',
                }}
              >
                <Checkbox checked={state.checkGreen}></Checkbox>
                <div onClick={onColorGreenChangeHandler} className="color_overlay_green">
                  {state.checkGreen && <CheckCircleOutlined style={{ color: 'white', fontSize: '20px' }} />}
                </div>
              </div>
              <div
                className="orangecheckbox"
                style={{
                  opacity: state.checkOrange ? '0.6' : '1',
                  transform: state.checkOrange ? 'scale(1.2)' : 'scale(1)',
                }}
              >
                <Checkbox checked={state.checkOrange}></Checkbox>
                <div onClick={onColorOrangeChangeHandler} className="color_overlay_orange">
                  {state.checkOrange && <CheckCircleOutlined style={{ color: 'white', fontSize: '20px' }} />}
                </div>
              </div>
            </div>
          </Form>
        )} */}
        <div>
          <p>Shipping Calculated</p>
          <Checkbox onChange={onShippingChangeHandler} checked={state.withShipping}>
            YES
          </Checkbox>
        </div>
        <div>
          <p>Order Summary Full Price</p>
          <Checkbox onChange={onSummaryChangeHandler} checked={state.withFullPrice}>
            YES
          </Checkbox>
        </div>
      </div>
    </Modal>
  );
};

export default EditFrames;
