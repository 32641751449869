import './utils/prototypes';
import './assets/css/index.css';

import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { ConfigProvider } from 'antd';
import { getDefaultStore, Provider } from 'jotai';
import { createRoot } from 'react-dom/client';

import App from './App';
import { getConfig } from './config';
import reportWebVitals from './reportWebVitals';
import { sessionStorageCache } from './utils/cache';

// Please see https://auth0.github.io/auth0-react/interfaces/auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const defaultStore = getDefaultStore();

const providerConfig: Auth0ProviderOptions = {
  domain: config.domain,
  clientId: config.clientId,
  audience: config.audience,
  redirectUri: window.location.origin,
  scope: 'read:users_app_metadata',
  cache: sessionStorageCache,
  onRedirectCallback: (appState?: AppState) => {
    window.history.pushState(null, '', appState?.returnTo ? appState.returnTo : window.location.pathname);
  },
};
const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <Auth0Provider {...providerConfig}>
      <Provider store={defaultStore}>
        <ConfigProvider
          theme={{
            token: {
              // colorPrimary: '#f60',
            },
            components: {
              Button: {
                colorPrimary: '#f60',
              },
              Layout: {
                headerBg: '#ffffff',
                bodyBg: '#ffffff',
              },
            },
          }}
        >
          <App />
        </ConfigProvider>
      </Provider>
    </Auth0Provider>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
