import { Col, Form, Input, InputNumber, Row } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { IFormProps, IOffer } from '../../types';
import { fetchOfferAtom } from '@/atoms/offers';

export const OfferEditForm = ({ formData, funnelId, onFormReady }: IFormProps<IOffer>) => {
  const [form] = Form.useForm();
  const [offer, fetchOffer] = useAtom(fetchOfferAtom);

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  useEffect(() => {
    if (funnelId) {
      fetchOffer(funnelId);
    }
  }, [fetchOffer, funnelId]);
  console.log('offer', offer);
  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="name" label="Offer name : ">
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="ef_offer_id" label="EF OID : ">
            <InputNumber placeholder="EF OID" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="offer_id" hidden>
        <Input value={formData?.offer_id} />
      </Form.Item>
    </Form>
  );
};
