import { DeleteOutlined, EditFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Table, Tabs, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';

import { openModalAtom } from '@/atoms/modal';
import { fetchRolesAtom } from '@/atoms/roles';
import { createUserAtom, deleteUserAtom, fetchUsersAtom, updateUserAtom } from '@/atoms/users';
import { IUser } from '@/types';
import { columnSearch, commonFields, enableSorting } from '@/utils/table';

import { UsersEditForm } from '../Forms';
import ActivityStatus from '../Funnels/ActiveStatusTags';
import PageHeader from '../PageHeader';

const Users = () => {
  const openModal = useSetAtom(openModalAtom);
  const updateUser = useSetAtom(updateUserAtom);
  const createUser = useSetAtom(createUserAtom);
  const deleteUser = useSetAtom(deleteUserAtom);
  const [roles, fetchRoles] = useAtom(fetchRolesAtom);
  const [users, fetchUsers] = useAtom(fetchUsersAtom);
  const [roleId, setRole] = useState('');

  useEffect(() => {
    fetchRoles();
    fetchUsers(roleId);
  }, [fetchRoles, fetchUsers, roleId]);

  const columns = useMemo(() => {
    return [
      !roleId && {
        title: 'Verified',
        ...commonFields('email_verified'),
        ...enableSorting('email_verified'),
        render: (email_verified: boolean) => <ActivityStatus active={email_verified} />,
      },
      {
        title: 'ID',
        ...commonFields('user_id'),
        ...enableSorting('user_id'),
      },
      {
        title: 'Name',
        ...commonFields('name'),
        ...enableSorting('name'),
        ...columnSearch('name'),
      },
      !roleId && {
        title: 'Nickname',
        ...commonFields('nickname'),
        ...enableSorting('nickname'),
        ...columnSearch('nickname'),
      },
      {
        title: 'Email',
        ...commonFields('email'),
        ...enableSorting('email'),
        ...columnSearch('email'),
      },
      {
        title: 'Role',
        ...commonFields('role'),
        ...enableSorting('role'),
        ...columnSearch('role'),
      },
      {
        title: 'Action',
        render: (_: any, user: IUser) => (
          <Space key={user.name}>
            <Popconfirm
              title="Are you sure you want to remove User?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                deleteUser(user.user_id);
              }}
            >
              <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
            <>
              <Tooltip placement="topLeft" title="Edit User">
                <Button
                  shape="circle"
                  icon={<EditFilled />}
                  onClick={() => {
                    openModal({
                      title: 'Edit User',
                      component: UsersEditForm,
                      data: user,
                      onSave: (values: IUser) => (user.user_id ? updateUser(user.user_id, values) : Promise.resolve()),
                    });
                  }}
                />
              </Tooltip>
            </>
          </Space>
        ),
      },
    ].filter(Boolean);
  }, [deleteUser, updateUser, openModal, roleId]);

  return (
    <>
      <PageHeader
        title="Users"
        extra={[
          <Button
            type="primary"
            onClick={() => {
              openModal({
                title: 'Add User',
                component: UsersEditForm,
                data: null,
                onSave: (values: IUser) => createUser(values),
              });
            }}
            key="add"
          >
            <PlusCircleOutlined /> Add User
          </Button>,
        ]}
        footer={
          <Tabs
            defaultActiveKey=""
            onChange={(key: string) => {
              setRole(key);
              fetchUsers(key);
            }}
            tabBarStyle={{ marginBottom: 0 }}
            items={[{ id: '', name: 'All' }, ...roles.data].map((cat) => ({
              key: cat.id,
              label: cat.name,
            }))}
          />
        }
      />
      <Content>
        <Table
          dataSource={users.data}
          columns={columns as any}
          rowKey="email"
          loading={users.isLoading}
          pagination={{ showSizeChanger: true }}
        />
      </Content>
    </>
  );
};
export default Users;
