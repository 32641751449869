import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { openModalAtom } from '@/atoms/modal';
import { PageEditForm } from '@/components/Forms';
import { IPage } from '@/types';

import { createPageAtom, fetchPagesAtom } from '../../../atoms/pages';
import PagesFlow from './Components/PagesFlow';
import PagesTable from './Components/PagesTable';

const Pages = ({ id, funnel, view }: any) => {
  const openModal = useSetAtom(openModalAtom);
  const createPage = useSetAtom(createPageAtom);
  const [pages, fetchPages] = useAtom(fetchPagesAtom);

  useEffect(() => {
    fetchPages(id);
  }, [fetchPages, id]);

  return !!pages.data.length ? (
    <>
      {view === 'table' ? (
        <PagesTable funnel={funnel} pages={pages.data}></PagesTable>
      ) : (
        <PagesFlow funnel={funnel} pages={pages.data}></PagesFlow>
      )}
    </>
  ) : (
    <Empty description="There are no pages" style={{ flex: 1, alignContent: 'center' }}>
      <Button
        onClick={() =>
          openModal({
            title: 'Add page',
            component: PageEditForm,
            onSave: (values: IPage) => createPage({ ...values, funnel_id: funnel.ID }),
            funnelId: funnel.ID,
          })
        }
      >
        <PlusCircleOutlined />
        Add page
      </Button>
    </Empty>
  );
};

export default Pages;
