import { atom } from 'jotai';

import { IBlockers } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<IBlockers[]>([]);
const isLoading = atom<boolean>(false);

export interface BlockersState {
  data: IBlockers[];
  isLoading: boolean;
}

export const blockerAtom = atom<BlockersState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
}));

export const fetchBlockersAtom = atom(
  (get) => get(blockerAtom),
  async (_, set, id?: number) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/funnels/${id}/blockers`)
      .json<IBlockers[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const createBlockerAtom = atom(null, async (get, set, body?: IBlockers) => {
  if (!body) return;
  set(isLoading, true);
  $http
    .post(`/api/v1/blockers`, { body })
    .json<IBlockers>()
    .then((response) => set(dataAtom, [...get(dataAtom), response]))
    .finally(() => set(isLoading, false));
});

export const updateBlockerAtom = atom(null, async (get, set, id: number, body: Partial<IBlockers>) => {
  const blockers = get(blockerAtom);
  set(isLoading, true);
  $http
    .put(`/api/v1/blockers/${id}`, { body })
    .json<IBlockers>()
    .then((blocker) => set(dataAtom, blockers.data.updateValueBy('ID', blocker)))
    .finally(() => set(isLoading, false));
});

export const deleteBlockerAtom = atom(null, async (get, set, id?: number) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .delete(`/api/v1/blockers/${id}`)
    .json<IBlockers>()
    .then(() =>
      set(
        dataAtom,
        get(dataAtom).filter(({ ID }) => ID !== id),
      ),
    )
    .finally(() => set(isLoading, false));
});
