import { Button, Col, Row, Table } from 'antd';
import {  useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { campaignsAtom, } from '../../../atoms/campaigns';
import { IRouteParams } from '../../../types';
import { columnSearch, commonFields } from '../../../utils/table';
import Loading from '../../Loading';

const Campaign = (props: any) => {
  const campaigns = useAtomValue(campaignsAtom);
  const { id }: IRouteParams = useParams();

  const campaignsById: any = [campaigns.data[Number(id) - 1]];

  const columns = useMemo(() => {
    return [
      {
        title: 'campaign_id',
        ...commonFields('campaign_id'),
        ...columnSearch('campaign_id'),
      },
      {
        title: 'campaign_name',
        ...commonFields('campaign_name'),
        ...columnSearch('campaign_name'),
      },
      {
        title: 'country_code',
        ...commonFields('country_code'),
        ...columnSearch('country_code'),
      },
      {
        title: 'scrub',
        ...commonFields('scrub'),
        ...columnSearch('scrub'),
      },
    ];
  }, []);

  if (campaigns.isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Table dataSource={campaignsById} columns={columns} rowKey="ID" />
        <Row>
          <Col span={4}>
            <Button type="primary">+ Add Campaign</Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Campaign;
