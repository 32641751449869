import { Form, Input, Modal } from 'antd';
import { useEffect } from 'react';

export const ReviewEditForm = ({ active, onEdit, item, onCancel }: any) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ ...item });
  }, [item, form]);

  const onFinish = (e: any) => {
    onEdit({ id: item.id, ...e });
    form.resetFields();
  };

  return (
    <Modal
      open={active}
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="avatar" label="Customer Avatar Image">
          <Input />
        </Form.Item>
        <Form.Item name="user" label="Customer Name">
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="review_header" label="Customer Review Header">
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="review" label="Customer Review">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
