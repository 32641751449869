import { atom } from 'jotai';

export interface IModalParams {
  title?: string;
  component?: any;
  data?: any;
  updateState?(a: any): void;
  onSave?(a: any): Promise<any>;
  funnelId?: any;
  class?: string;
}

interface IModalState {
  params: IModalParams;
  isOpen: boolean;
}

const isOpen = atom(false);
const params = atom<IModalParams>({
  title: '',
  class: '',
});

export const modalAtom = atom<IModalState>((get) => ({
  params: get(params),
  isOpen: get(isOpen),
}));

export const openModalAtom = atom(null, (_, set, data: IModalParams) => {
  set(params, data);
  set(isOpen, true);
});

export const closeModalAtom = atom(null, (_, set) => {
  set(isOpen, false);
});
