import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';

import { RichTextEditor } from '../../../Pages/TemplateEditor/Components/RichTextEditor';

export const FaqEditForm = ({ onEdit, item, onCancel }: any) => {
  const [form] = Form.useForm();
  const [html, setHtml] = useState('');

  useEffect(() => {
    form.setFieldsValue({ ...item });
    setHtml(item.faq_content[0].faq_ct_text);
  }, [item, form]);

  const onTextChange = (incoming) => {
    setHtml(incoming);
  };

  const onFinish = ({ faq_question }: any) => {
    onEdit({
      faq_id: item.faq_id,
      faq_question: faq_question,
      faq_content: [{ faq_ct_text: html }],
    });
    form.resetFields();
  };

  return (
    <Form form={form} className="listForm faqForm" layout="vertical" onFinish={onFinish}>
      <p>Faq Question:</p>
      <Form.Item name="faq_question">
        <Input style={{ width: '50%' }} placeholder="please add question" />
      </Form.Item>
      <p>Faq Description:</p>
      <RichTextEditor onValueChange={(v) => onTextChange(v)} value={html} />
      <Button style={{ marginTop: 5 }} type="primary" htmlType="submit">
        Done
      </Button>
    </Form>
  );
};
