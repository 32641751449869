import { message, Modal, Progress, Row } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { progressAtom } from '@/atoms/progress';

const ProgressModal = () => {
  const [progress, setProgress] = useAtom(progressAtom);
  const [messageApi, messageContextHolder] = message.useMessage();
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    switch (progress.percent) {
      case 0:
        !!progress.message &&
          modalApi.error({
            title: 'An error occurred',
            content: <pre style={{ overflow: 'auto' }}>{progress.message}</pre>,
            width: 600,
            onOk() {
              setProgress({ percent: 0, message: '' });
            },
          });
        break;
      case 100:
        modalApi.success({
          title: 'Done',
          content: <pre style={{ overflow: 'auto' }}>{progress.message}</pre>,
          width: 600,
        });
        setProgress({ percent: 0, message: '' });
        break;
      default:
        !!progress.message && messageApi.info(progress.message);
        break;
    }
  }, [progress, setProgress, modalApi, messageApi]);

  return (
    <>
      <Modal
        open={!!progress.percent}
        centered
        keyboard={false}
        maskClosable={false}
        modalRender={() => (
          <Row justify="center">
            <Progress type="circle" percent={progress.percent} strokeColor="#ff6600" trailColor="#3333" />
          </Row>
        )}
      ></Modal>
      {messageContextHolder}
      {modalContextHolder}
    </>
  );
};

export default ProgressModal;
