import { atom } from 'jotai';

import { ICampaign } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<ICampaign[]>([]);
const isLoading = atom<boolean>(false);

export interface CampaignsState {
  data: ICampaign[];
  isLoading: boolean;
}

export const campaignsAtom = atom<CampaignsState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
}));

export const fetchCampaignsAtom = atom(
  (get) => get(campaignsAtom),
  async (_, set, id?: number) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/funnels/${id}/campaigns`)
      .json<ICampaign[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const fetchCampaignsOfferAtom = atom(
  (get) => get(campaignsAtom),
  async (_, set, offerId?: number) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/campaigns/${offerId}`)
      .json<ICampaign[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const importCampaignAtom = atom(null, async (get, set, body?: ICampaign) => {
  if (!body) return;
  set(isLoading, true);
  $http
    .post(`/api/v1/campaigns`, { body })
    .json<ICampaign[]>()
    .then((response) => set(dataAtom, response))
    .finally(() => set(isLoading, false));
});

export const addCampaignAtom = atom(null, async (get, set, body?: ICampaign) => {
  if (!body) return;
  set(isLoading, true);
  $http
    .post(`/api/v1/campaigns/camps`, { body })
    .json<ICampaign[]>()
    .then((response) => set(dataAtom, [...get(dataAtom), ...response]))
    .finally(() => set(isLoading, false));
});

export const addCampaignFulfillmentAtom = atom(null, async (get, set, { campaigns_id, fulfillment_id }: ICampaign) => {
  set(isLoading, true);
  $http
    .post(`/api/v1/campaigns/fulfillment`, {
      body: {
        campaigns_id,
        fulfillment_id,
      },
    })
    .json<ICampaign>()
    .then((response) => set(dataAtom, [...get(dataAtom), response]))
    .finally(() => set(isLoading, false));
});

export const updateCampaignAtom = atom(null, async (get, set, id: number, body: Partial<ICampaign>) => {
  if (!id) return;
  const campaigns = get(dataAtom);
  set(isLoading, true);
  $http
    .put(`/api/v1/campaigns/${id}`, { body })
    .json<ICampaign>()
    .then((campaign) => set(dataAtom, campaigns.updateValueBy('ID', campaign)))
    .finally(() => set(isLoading, false));
});

export const deleteCampaignAtom = atom(null, async (get, set, id?: number) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .delete(`/api/v1/campaigns/${id}`)
    .json<ICampaign>()
    .then(() =>
      set(
        dataAtom,
        get(dataAtom).filter(({ ID }) => ID !== id),
      ),
    )
    .finally(() => set(isLoading, false));
});
