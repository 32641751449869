import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useEffect } from 'react';

import { ICampaign, IFormProps } from '@/types';

export const CampaignAddForm = ({ formData, onFormReady }: IFormProps<ICampaign>) => {
  const [form] = Form.useForm();
  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <Form.Item name="offer_id" hidden>
        <Input />
      </Form.Item>
      <Form.Item label="Campaign Name will be like offer name if needed just add in field Sufix" name="sufix">
        <Input placeholder="Example: Network + Sufix (V1, V2, V3 etc)" />
      </Form.Item>
      <Form.Item
        label="Campaign description"
        name="description"
        rules={[{ required: false, message: 'Missing Campaign Description' }]}
      >
        <Input placeholder="Campaign Description" />
      </Form.Item>
      <h4>Chose Campaign to be created : </h4>
      <Form.Item name="regions">
        <Checkbox.Group style={{ width: '100%' }}>
          <Row>
            <Col span={4.8}>
              <Checkbox value="US">US</Checkbox>
            </Col>
            <Col span={4.8}>
              <Checkbox value="CA">CA</Checkbox>
            </Col>
            <Col span={4.8}>
              <Checkbox value="PP">PP</Checkbox>
            </Col>
            <Col span={4.8}>
              <Checkbox value="DE">DE</Checkbox>
            </Col>
            <Col span={4.8}>
              <Checkbox value="UK">UK</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};
