import { DeleteOutlined, EditFilled, PlusCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAtom, useSetAtom } from 'jotai';
import type { AlignType } from 'rc-table/es/interface';
import { useEffect } from 'react';

import { openModalAtom } from '@/atoms/modal';
import {
  createShippingAtom,
  deleteShippingAtom,
  fetchShippingAtom,
  syncShippingAtom,
  updateShippingAtom,
} from '@/atoms/shipping';
import { IShipping } from '@/types';
import { columnSearch, commonFields, enableSorting } from '@/utils/table';

import { ShippingEditForm } from '../Forms';
import Loading from '../Loading';
import PageHeader from '../PageHeader';
import { EditButton } from '../UI/EditButton';

const Shipping = () => {
  const openModal = useSetAtom(openModalAtom);
  const deleteShipping = useSetAtom(deleteShippingAtom);
  const syncShipping = useSetAtom(syncShippingAtom);
  const createShipping = useSetAtom(createShippingAtom);
  const updateShipping = useSetAtom(updateShippingAtom);
  const [shipping, fetchShipping] = useAtom(fetchShippingAtom);

  function confirm(shipping: IShipping) {
    deleteShipping(shipping.ID);
  }
  useEffect(() => {
    fetchShipping();
  }, [fetchShipping]);

  const columns = [
    {
      title: 'Name',
      ...commonFields('name'),
      ...columnSearch('name'),
      ...enableSorting('name'),
    },
    {
      title: 'Description',
      ...commonFields('description'),
      ...columnSearch('description'),
    },
    {
      title: 'Price',
      ...commonFields('price'),
      ...columnSearch('price'),
      ...enableSorting('price'),
    },
    {
      title: 'Sticky ID',
      ...commonFields('ID'),
      ...enableSorting('ID'),
    },
    {
      title: '29 Next Code',
      ...commonFields('shipping_code'),
      ...columnSearch('shipping_code'),
    },
    {
      title: 'Action',
      align: 'center' as AlignType,
      ...commonFields('action'),
      render: (_: any, shipping: IShipping) => (
        <Space key={shipping.ID}>
          <EditButton
            data={shipping}
            title="Edit shipping"
            component={ShippingEditForm}
            shape="circle"
            icon={<EditFilled />}
            onSave={(values: IShipping) => updateShipping(shipping.ID, values)}
          />
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            onClick={() => {
              syncShipping(shipping);
            }}
          ></Button>
          <Popconfirm
            title="Are you sure you want to remove shipping?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => confirm(shipping)}
          >
            <Button shape="circle" danger={true} icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <>
      <PageHeader
        title="Shippings"
        extra={[
          <Button
            type="primary"
            onClick={() => {
              openModal({
                title: 'Import shipping',
                component: ShippingEditForm,
                data: {},
                onSave: (values: IShipping) => createShipping(values),
              });
            }}
            key="import"
          >
            <PlusCircleOutlined /> Import from Sticky
          </Button>,
        ]}
      />
      <Content>
        {shipping.isLoading ? <Loading /> : <Table dataSource={shipping.data} rowKey="ID" columns={columns as any} />}
      </Content>
    </>
  );
};
export default Shipping;
