import { Form } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useState } from 'react';

import { ImagePicker } from '../../../../../common/ImagePicker';

export const ProductImageUnit = ({ pendingProduct, onChange, pageData }: any) => {
  const [form] = Form.useForm();
  const [productImage, setproductImage] = useState(pendingProduct.product_unit_img);

  const onImageChangeHandler = (value) => {
    setproductImage(value);
    onChange({ product_unit_img: value });
  };

  return (
    <div>
      <Form form={form} layout="vertical">
        <div className="productRow">
          <FormItem style={{ width: '100%' }} initialValue={productImage} name="product_unit_img" label="Product Image">
            <ImagePicker onChange={(v) => onImageChangeHandler(v)} funnelId={pageData.funnel_id} />
          </FormItem>
        </div>
      </Form>
    </div>
  );
};
