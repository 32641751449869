import { Form, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';

const ProductSize = ({ pendingProduct, onChange, pageData }: any) => {
  const [form] = Form.useForm();
  let checkSizeLen =
    !!pageData && !!pageData.meta && !!pageData.meta.productSizes && pageData.meta.productSizes.length > 1;

  const onProductSizeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ product_size_name: e });
  };

  return (
    <div>
      <Form form={form} layout="vertical">
        {!checkSizeLen && (
          <p style={{ marginBottom: 0 }}>*If you want to add product size you need to add at least 2 sizes</p>
        )}
        <div className="productRow">
          <FormItem
            style={{ width: '100%' }}
            name="product_size_name"
            label="Product Size"
            initialValue={!!pendingProduct && !!pendingProduct.product_size_name && pendingProduct.product_size_name}
          >
            <Select
              defaultValue={!!pendingProduct && !!pendingProduct.product_size_name && pendingProduct.product_size_name}
              disabled={!checkSizeLen}
              onChange={onProductSizeChangeHandler}
              placeholder="-- Choose a size --"
            >
              {pageData &&
                pageData.meta &&
                pageData.meta.productSizes &&
                pageData.meta.productSizes.map((size) => (
                  <Select.Option key={size} value={size}>
                    {size}
                  </Select.Option>
                ))}
            </Select>
          </FormItem>
        </div>
      </Form>
    </div>
  );
};
export default ProductSize;
