import { atom } from 'jotai';

import { IUser } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<IUser[]>([]);
const isLoading = atom<boolean>(false);

export interface UsersState {
  data: IUser[];
  isLoading: boolean;
}

export const usersAtom = atom<UsersState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
}));

export const fetchUsersAtom = atom(
  (get) => get(usersAtom),
  async (_, set, role?: string) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/users?role=${role}`)
      .json<IUser[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const createUserAtom = atom(null, async (get, set, body?: IUser) => {
  if (!body) return;
  set(isLoading, true);
  $http
    .post(`/api/v1/users`, { body })
    .json<IUser>()
    .then((response) => set(dataAtom, [...get(dataAtom), response]))
    .finally(() => set(isLoading, false));
});

export const updateUserAtom = atom(null, async (get, set, id: string, body: Partial<IUser>) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .patch(`/api/v1/users/${id}`, { body })
    .json<IUser>()
    .then((response) => set(dataAtom, [...get(dataAtom), response]))
    .finally(() => set(isLoading, false));
});

export const deleteUserAtom = atom(null, async (get, set, id?: string) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .delete(`/api/v1/users/${id}`)
    .json<IUser>()
    .then(() =>
      set(
        dataAtom,
        get(dataAtom).filter(({ user_id }) => user_id !== id),
      ),
    )
    .finally(() => set(isLoading, false));
});
