import { Form, Input, Select } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { fetchOffersAtom } from '@/atoms/offers';

export const FunnelCreateForm = ({ onFormReady }) => {
  const [offers, fetchOffers] = useAtom(fetchOffersAtom);
  const [form] = Form.useForm();

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label="Insert new Funnel Name"
        rules={[{ required: true, message: 'Please input Funnel Name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="offer_id" label="Offer ID" rules={[{ required: true, message: 'Please Select Offer ID' }]}>
        <Select
          showSearch
          placeholder="Search to Select"
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
          options={offers.data.map((v) => {
            return { value: v.ID, label: `${v.name}(${v.offer_id})` };
          })}
        />
      </Form.Item>
    </Form>
  );
};
