import { atom } from 'jotai';

import { IPagesPayload } from '../types';
import { IPage } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<IPage[]>([]);
const page = atom<IPage | undefined>(undefined);
const html = atom<string>('');
const isLoading = atom<boolean>(false);

export interface PagesState {
  data: IPage[];
  isLoading: boolean;
}

export const pageAtom = atom<{
  data: IPage | undefined;
  html: string;
  isLoading: boolean;
}>((get) => ({
  data: get(page),
  html: get(html),
  isLoading: false,
}));

export const proofAtom = atom<IPage | undefined>(undefined);

export const pagesAtom = atom<PagesState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
}));

export const fetchPageAtom = atom(
  (get) => get(pageAtom),
  async (_, set, pageId?: number) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/pages/${pageId}`)
      .json<IPage>()
      .then((response) => set(page, response))
      .finally(() => set(isLoading, false));
  },
);

export const fetchPagePreviewAtom = atom(
  (get) => get(html),
  async (_, set, remoteUrl: string, customStyle: string) => {
    set(isLoading, true);
    const response = await $http.get(remoteUrl, true).text();
    set(
      html,
      response
        // Remove all scripts
        .replaceAll(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
        .replace(
          '<head>',
          // Set base
          `<head>
              <base href="${remoteUrl}"/>
              <style>${customStyle}</style>
              `,
        ),
    );
  },
);

export const updateMetaAtom = atom(null, (get, set, newMeta: any) => {
  const p = get(page);
  p && set(page, { ...p, meta: { ...p.meta, ...newMeta } });
});

export const fetchPagesAtom = atom(
  (get) => get(pagesAtom),
  async (_, set, funnelId?: number) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/funnels/${funnelId}/pages`)
      .json<IPage[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const createPageAtom = atom(null, async (get, set, body?: IPagesPayload) => {
  if (!body) return;
  set(isLoading, true);
  $http
    .post(`/api/v1/pages`, { body })
    .json<IPage>()
    .then((response) => set(dataAtom, [...get(dataAtom), response]))
    .finally(() => set(isLoading, false));
});

export const updatePageAtom = atom(null, async (get, set, id: number, body: Partial<IPage>) => {
  if (!id) return;
  const pages = get(pagesAtom);
  set(isLoading, true);
  $http
    .put(`/api/v1/pages/${id}`, { body })
    .json<IPage>()
    .then((updatedPage) => {
      set(dataAtom, pages.data.updateValueBy('ID', updatedPage));
      set(page, updatedPage);
      set(proofAtom, updatedPage);
    })
    .finally(() => set(isLoading, false));
});

export const deletePageAtom = atom(null, async (get, set, id?: number) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .delete(`/api/v1/pages/${id}`)
    .json<IPage>()
    .then(() =>
      set(
        dataAtom,
        get(dataAtom).filter(({ ID }) => ID !== id),
      ),
    )
    .finally(() => set(isLoading, false));
});

// on component unmount function (reset state)
export const resetPageAtom = atom(null, (_, set) => {
  set(page, undefined);
  set(html, '');
  set(isLoading, false);
});
