import {
  CreditCardTwoTone,
  DeleteOutlined,
  DollarTwoTone,
  EditFilled,
  EyeOutlined,
  GiftFilled,
  HomeTwoTone,
  PlusCircleOutlined,
  RestOutlined,
  RetweetOutlined,
  SaveFilled,
} from '@ant-design/icons';
import { Button, Space, Table, Tabs } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAtom, useSetAtom } from 'jotai';
import type { AlignType } from 'rc-table/es/interface';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { openModalAtom } from '@/atoms/modal';
import {
  createTemplateAtom,
  deleteTemplateAtom,
  fetchTemplateLayoutsAtom,
  fetchTemplatesAtom,
  syncTemplatesAtom,
  updateTemplateAtom,
} from '@/atoms/templates';
import { ITemplate } from '@/types';
import { columnSearch, commonFields } from '@/utils/table';

import { PREVIEW_URL_PREFIX, TemplateEditForm } from '../Forms';
import Loading from '../Loading';
import PageHeader from '../PageHeader';
import { DeleteButton } from '../UI/DeleteButton';
import { EditButton } from '../UI/EditButton';

const categories = [
  {
    key: 'all',
    label: 'All templates',
    icon: SaveFilled,
    color: 'black',
  },
  {
    key: 'interstitial',
    label: 'Interstitial',
    icon: HomeTwoTone,
    color: 'red',
  },
  {
    key: 'checkout',
    label: 'Checkout',
    icon: CreditCardTwoTone,
    color: 'red',
  },
  {
    key: 'upsell1',
    label: 'Upsell',
    icon: DollarTwoTone,
    color: '#52c41a',
  },
  {
    key: 'upsell2',
    label: 'Upsell2',
    icon: DollarTwoTone,
    color: '#52c41a',
  },
  {
    key: 'thankyou',
    label: 'Thank you',
    icon: GiftFilled,
    color: 'red',
  },
  {
    key: 'warranty',
    label: 'Warranty',
    icon: DollarTwoTone,
    color: 'blue',
  },
  {
    key: 'stashed',
    icon: RestOutlined,
    label: 'Stashed',
    color: 'black',
  },
];

const Templates = () => {
  const openModal = useSetAtom(openModalAtom);
  const fetchTemplateLayouts = useSetAtom(fetchTemplateLayoutsAtom);
  const createTemplate = useSetAtom(createTemplateAtom);
  const updateTemplate = useSetAtom(updateTemplateAtom);
  const deleteTemplate = useSetAtom(deleteTemplateAtom);
  const syncTemplates = useSetAtom(syncTemplatesAtom);
  const [templates, fetchTemplates] = useAtom(fetchTemplatesAtom);
  const [filteredTemplates, setFilteredTemplates] = useState(templates.data);
  const [selectedTabKey, setSelectedTabKey] = useState('all');

  useEffect(() => {
    fetchTemplates();
    fetchTemplateLayouts();
  }, [fetchTemplates, fetchTemplateLayouts]);

  const confirm = useMemo(() => (template: ITemplate) => deleteTemplate(template.ID), [deleteTemplate]);

  const callback = useCallback(
    (key: any) => {
      setSelectedTabKey(key);
      switch (key) {
        case 'all':
          setFilteredTemplates(templates.data.filter((item) => !item.archived));
          break;
        case 'stashed':
          setFilteredTemplates(templates.data.filter((item) => item.archived));
          break;
        default:
          setFilteredTemplates(templates.data.filter((item) => !item.archived && item.page_type === key));
          break;
      }
    },
    [templates.data],
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'Name',
        ...commonFields('name'),
        ...columnSearch('name'),
      },
      {
        title: 'Page Type',
        ...commonFields('page_type'),
        ...columnSearch('page_type'),
      },
      {
        title: 'Page Layout',
        ...commonFields('layout'),
        ...columnSearch('layout'),
      },
      {
        title: 'Template Preview URL',
        ...commonFields('template_host_url'),
        ...columnSearch('template_host_url'),
      },
      {
        title: 'Action',
        ...commonFields('action'),
        align: 'center' as AlignType,
        render: (_: any, template: ITemplate) => (
          <Space className={`${template.archived && 'archived_template'}`}>
            <EditButton
              data={template}
              title="Edit Template"
              component={TemplateEditForm}
              shape="circle"
              icon={<EditFilled />}
              onSave={(values: ITemplate) =>
                updateTemplate(template.ID, {
                  ...values,
                  template_host_url: PREVIEW_URL_PREFIX + values.template_host_url,
                  meta: JSON.parse(values.meta),
                })
              }
            />
            <Button shape="circle" icon={<EyeOutlined />} target="_blank" href={`${template.template_host_url}`} />
            <DeleteButton onConfirm={() => confirm(template)} shape="circle" icon={<DeleteOutlined />} />
          </Space>
        ),
      },
    ];
  }, [confirm, updateTemplate]);

  useEffect(() => {
    callback(selectedTabKey);
  }, [callback, selectedTabKey, templates.data]);

  return (
    <>
      <PageHeader
        title="Templates"
        extra={[
          <Button onClick={() => syncTemplates()} disabled={templates.isSyncing} key="synv">
            <RetweetOutlined /> Sync templates
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              openModal({
                title: 'Add Template',
                component: TemplateEditForm,
                data: null,
                onSave: (values: ITemplate) =>
                  createTemplate({
                    ...values,
                    meta: JSON.parse(values.meta),
                    template_host_url: PREVIEW_URL_PREFIX + values.template_host_url,
                  }),
              });
            }}
            key="add"
          >
            <PlusCircleOutlined /> Add Template
          </Button>,
        ]}
        footer={
          <Tabs
            defaultActiveKey="1"
            onChange={callback}
            tabBarStyle={{ marginBottom: 0 }}
            items={categories.map((cat) => ({
              key: cat.key,
              label: cat.label,
              icon: <cat.icon style={{ color: cat.color }} />,
            }))}
          />
        }
      />
      <Content>
        {templates.isLoading ? (
          <Loading />
        ) : (
          <Table dataSource={filteredTemplates} columns={columns as any} rowKey="ID" />
        )}
      </Content>
    </>
  );
};

export default Templates;
