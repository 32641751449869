import { Form, Input } from 'antd';
import { useEffect } from 'react';

import { IFormProps } from '../../types';
import { IFunnel } from '../../types';

export const FunnelAddNoteForm = ({ onFormReady }: IFormProps<IFunnel>) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);
  
  return (
    <>
      <Form form={form} layout="vertical">
        <Form.Item name="title" label="Title">
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item
          name="message"
          label="Note"
          rules={[{ required: true, message: 'Please input Note you want to add to Funnel' }]}
        >
          <TextArea rows={6} placeholder="Input Funnel Note" />
        </Form.Item>
        <Form.Item
          name="task_url"
          label="Task URL"
          rules={[{ message: 'Please input Task URL you want to add to Funnel' }]}
        >
          <Input placeholder="Input Funnel Task URL" />
        </Form.Item>
      </Form>
    </>
  );
};
