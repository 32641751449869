import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Image } from 'antd';
import { useState } from 'react';

import { SliderImageAddForm } from './SliderImageAddForm';
import { SliderImageDeleteForm } from './SliderImageDeleteForm';
import { SliderImageEditForm } from './SliderImageEditForm';

enum ActionType {
  Add,
  Delete,
  Edit,
}

interface PendingAction {
  type: ActionType;
  item: any;
}
const EditSliderImages = ({ pageData, listChange, pendingSaveData, iframeDocument }: any) => {
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);

  const onAddSliderImageHandler = (incomingData: any[]) => {
    let data = !!pendingSaveData.meta && !!pendingSaveData.meta.imgSlider ? pendingSaveData.meta.imgSlider : [];
    listChange({ imgSlider: [...data, incomingData] });
    setPendingAction(null);
  };

  const onEditSliderImageHandler = (item: any) => {
    if (item && !!pendingSaveData) {
      let newList = [...pendingSaveData.meta.imgSlider];
      const index = newList.findIndex((v: any) => v.id === item.id);
      newList[index] = item;
      listChange({ imgSlider: newList });
    }
    setPendingAction(null);
  };

  const onDeleteSliderImageHandler = (itemDeleted: any) => {
    if (itemDeleted && !!pendingSaveData) {
      let dataFiltered = pendingSaveData.meta.imgSlider.filter((v) => v.id !== itemDeleted.id);
      listChange({ imgSlider: dataFiltered });
    }
    setPendingAction(null);
  };

  const onPointScrollToHandler = () => {
    let sliderDoc = iframeDocument.querySelector('.swiper');
    sliderDoc.style.outline = '5px solid greenyellow';
    sliderDoc.scrollIntoView();
  };
  const onPointScrollOutHandler = () => {
    let sliderDoc = iframeDocument.querySelector('.swiper');
    sliderDoc.style.outline = '0px';
  };

  return (
    <>
      {!!pendingSaveData && !!pendingSaveData.meta && !!pendingSaveData.meta.imgSlider ? (
        pendingSaveData.meta.imgSlider.map((item: any, index: any) => {
          return (
            <Card
              onMouseOver={onPointScrollToHandler}
              onMouseLeave={onPointScrollOutHandler}
              className="card-products"
              size="small"
              key={String(index)}
            >
              <div style={{ overflow: 'hidden', width: '70%' }}>
                <div className="listImageCards">
                  <span>
                    <Image
                      src={item.img}
                      style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                      preview={{ maskClassName: 'maskImgCss' }}
                    />
                  </span>
                </div>
              </div>
              <Button
                type="primary"
                className="removeBTN"
                danger
                shape="circle"
                onClick={() => {
                  setPendingAction({ type: ActionType.Delete, item });
                }}
              >
                <DeleteOutlined />
              </Button>
              <Button
                type="primary"
                className="editBTN"
                shape="circle"
                onClick={() => setPendingAction({ type: ActionType.Edit, item })}
              >
                <EditOutlined />
              </Button>
            </Card>
          );
        })
      ) : (
        <p style={{ textAlign: 'center' }}>No Images added</p>
      )}
      <div
        className="addIconPlusImageList"
        onClick={() => {
          setPendingAction({
            type: ActionType.Add,
            item: !!pendingSaveData.meta && !!pendingSaveData.meta.imgSlider && pendingSaveData.meta.imgSlider,
          });
        }}
      >
        + Add new Image
      </div>
      <SliderImageDeleteForm
        active={pendingAction?.type === ActionType.Delete}
        iframeDocument={iframeDocument}
        onFinished={(itemDeleted: any) => onDeleteSliderImageHandler(itemDeleted)}
        onCancel={() => setPendingAction(null)}
        pendingProduct={pendingAction?.item}
      />
      <SliderImageAddForm
        active={pendingAction?.type === ActionType.Add}
        onAdd={(product: any) => onAddSliderImageHandler(product)}
        onCancel={() => setPendingAction(null)}
        funnelId={pageData.funnel_id}
      />
      <SliderImageEditForm
        active={pendingAction?.type === ActionType.Edit}
        onEdit={(item: any) => onEditSliderImageHandler(item)}
        onCancel={() => setPendingAction(null)}
        item={pendingAction?.item}
        funnelId={pageData.funnel_id}
      />
    </>
  );
};

export default EditSliderImages;
