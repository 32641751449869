import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useSetAtom } from 'jotai';

import { openModalAtom } from '../../atoms/modal';
import { CampaignFulFillmentEditForm } from '../Forms';
import PageHeader from '../PageHeader';

const Sticky = () => {
  const openModal = useSetAtom(openModalAtom);

  return (
    <>
      <PageHeader
        title="Sticky"
        extra={[
          <Button
            type="primary"
            onClick={() => {
              openModal({
                title: 'Upload CSV File for Campaign Fulfillment Update',
                component: CampaignFulFillmentEditForm,
                data: null,
              });
            }}
            key="import"
          >
            <PlusCircleOutlined /> Update Campaigns Fulfillment ID
          </Button>,
        ]}
      />
    </>
  );
};
export default Sticky;
