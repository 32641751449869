import { Button, Select } from 'antd';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';

import { fetchPageAtom, pagesAtom, updateMetaAtom } from '../../../../../../atoms/pages';

export const SkipPage = ({ pageData }) => {
  const [, updateMeta] = useAtom(updateMetaAtom);
  const [page, fetchPage] = useAtom(fetchPageAtom);
  const pages = useAtomValue(pagesAtom);
  const isActive = !!page.data?.meta?.skipPage;
  useEffect(() => {
    fetchPage(pageData.ID);
  }, [fetchPage, pageData.ID]);

  const onActive = () => {
    updateMeta({ skipPage: !isActive });
  };

  const handleChange = (value: string) => {
    updateMeta({ skipPagePath: value });
  };

  return (
    <div className="script_container">
      <p>
        The skip page script allows this page to be redirected to a specific page if an <b>addon</b> is purchased on the{' '}
        <b>checkout page</b>
      </p>
      {isActive && (
        <>
          <p className="info_text">Redirect to:</p>
          <Select
            placeholder="Pick a page path"
            defaultValue={!!pageData && !!pageData.meta && pageData.meta.skipPagePath}
            style={{ width: '100%', display: 'block', marginBottom: 10 }}
            onChange={handleChange}
            options={pages.data.map((page) => {
              return { value: page.page_path, label: page.page_path };
            })}
          />
        </>
      )}
      <Button
        onClick={onActive}
        className="activeBtn"
        style={{ backgroundColor: isActive ? 'rgb(177, 29, 10)' : 'rgb(10, 177, 10)' }}
      >
        {isActive ? 'Deactivate' : 'Activate'}
      </Button>
    </div>
  );
};
