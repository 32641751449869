import Form from 'antd/es/form';

import { ImagePicker } from '../../../common/ImagePicker';
import { ITemplateImageEdit } from '.';

export const TemplateImageEdit = ({ pendingSaveData, funnelId, onChange, editableImages }: ITemplateImageEdit) => {
  const [form] = Form.useForm();

  if (editableImages.length === 0) {
    return <p>There is no images to edit</p>;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ display: 'flex', flexDirection: 'column' }}
      className="formEditable"
      onFinish={onChange}
    >
      {editableImages.map(({ key, el }) => (
        <div key={key}>
          <Form.Item
            name={key}
            label={key}
            initialValue={!!pendingSaveData && pendingSaveData.meta ? pendingSaveData.meta[key] : ''}
          >
            <ImagePicker funnelId={funnelId} onFinish={form.submit} />
          </Form.Item>
        </div>
      ))}
    </Form>
  );
};
