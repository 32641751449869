import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useEffect } from 'react';

export const EditDomain = ({ domain, onUpdate, onClose }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.getFieldInstance('name').focus();
  }, [form]);

  const onFinish = (values) => {
    onUpdate({ name: values.name });
  };

  return (
    <Form form={form} onFinish={onFinish} className="currentDomainEdit">
      <Form.Item style={{ marginBottom: 0 }} initialValue={domain.name} name="name">
        <Input autoComplete="true" />
      </Form.Item>
      <div className="formbtns">
        <Form.Item>
          <Button size="small" type="primary" htmlType="submit">
            <SaveOutlined />
          </Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={onClose} size="small" danger type="primary" htmlType="button">
            <CloseOutlined />
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};
