import { DeleteOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { useAtom, useSetAtom } from 'jotai';
import type { AlignType } from 'rc-table/es/interface';
import { useEffect } from 'react';

import { deleteCampaignAtom, fetchCampaignsAtom } from '../../../atoms/campaigns';
import { IRouteParams } from '../../../types';
import { ICampaign } from '../../../types';
import { columnSearch, commonFields } from '../../../utils/table';
import Loading from '../../Loading';
import { DeleteButton } from '../../UI/DeleteButton';

const Campaigns = ({ id }: IRouteParams) => {
  const [campaigns, fetchCampaigns] = useAtom(fetchCampaignsAtom);
  const deleteCampaign = useSetAtom(deleteCampaignAtom);

  useEffect(() => {
    fetchCampaigns(id);
  }, [fetchCampaigns, id]);

  const columns = [
    {
      title: 'Campaign ID',
      ...commonFields('campaign_id'),
    },
    {
      title: 'Campaign name',
      ...commonFields('name'),
      ...columnSearch('name'),
    },
    {
      title: 'Country',
      ...commonFields('country'),
      ...columnSearch('country'),
    },
    {
      title: 'Payment Method',
      ...commonFields('payment_method'),
      ...columnSearch('payment_method'),
    },
    {
      title: 'Scrub',
      ...commonFields('is_scrub'),
      ...columnSearch('is_scrub'),
      render: (s: any) => (s ? 'YES' : 'NO'),
    },
    {
      title: 'Action',
      ...commonFields('action'),
      align: 'center' as AlignType,
      render: (_: any, campaign: ICampaign) => (
        <DeleteButton onConfirm={() => deleteCampaign(campaign.ID)} shape="circle" icon={<DeleteOutlined />} />
      ),
    },
  ];

  if (campaigns.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Table
        style={{ width: '100%' }}
        dataSource={campaigns.data}
        rowKey="ID"
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default Campaigns;
