import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { Space, Table } from 'antd';
import { useAtom, useSetAtom } from 'jotai';
import type { AlignType } from 'rc-table/es/interface';
import { useEffect } from 'react';

import { deleteScrubAtom, fetchScrubsAtom, updateScrubAtom } from '@/atoms/scrub';
import { IScrub } from '@/types';
import { columnSearch, commonFields, enableSorting } from '@/utils/table';

import { ScrubEditForm } from '../../Forms';
import Loading from '../../Loading';
import { DeleteButton } from '../../UI/DeleteButton';
import { EditButton } from '../../UI/EditButton';

const Scrubs = ({ id, funnel }: any) => {
  const [scrubs, fetchScrubs] = useAtom(fetchScrubsAtom);
  const updateScrub = useSetAtom(updateScrubAtom);
  const deleteScrub = useSetAtom(deleteScrubAtom);

  useEffect(() => {
    fetchScrubs(id);
  }, [fetchScrubs, id]);

  const confirm = (scrub: IScrub) => {
    deleteScrub(scrub.ID);
  };

  const columns = [
    {
      title: 'ID',
      ...commonFields('ID'),
      ...enableSorting('ID'),
    },
    { title: 'Funnel ID', ...commonFields('funnel_id'), ...columnSearch('funnel_id') },
    {
      title: 'Affiliate ID',
      ...commonFields('affiliate_id'),
      ...enableSorting('affiliate_id'),
      ...columnSearch('affiliate_id'),
      render: (v: string) => {
        if (!v) {
          return <p className="global_p">GLOBAL SCRUB</p>;
        }
        return v;
      },
    },
    {
      title: 'Affiliate Name',
      ...commonFields('affiliate_name'),
      ...columnSearch('affiliate_name'),
      render: (v: string) => {
        if (!v) {
          return <p className="global_p">GLOBAL SCRUB</p>;
        }
        return v;
      },
    },
    {
      title: 'Scrub',
      ...commonFields('scrub_pct'),
      ...enableSorting('scrub_pct'),
      render: (val: number) => `${val}%`,
    },
    {
      title: 'Action',
      ...commonFields('action'),
      align: 'center' as AlignType,
      render: (_: any, scrub: IScrub) => (
        <Space>
          <EditButton
            title="Edit Scrub"
            component={ScrubEditForm}
            data={scrub}
            disabled={funnel?.locked}
            shape="circle"
            icon={<EditFilled />}
            onSave={(values: IScrub) => updateScrub(scrub.ID, values)}
          />
          <DeleteButton
            onConfirm={() => confirm(scrub)}
            disabled={funnel?.locked}
            shape="circle"
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ];

  if (scrubs.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Table
        style={{ width: '100%' }}
        dataSource={scrubs.data}
        rowKey="ID"
        columns={columns as any}
        pagination={false}
      />
    </>
  );
};

export default Scrubs;
