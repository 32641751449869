import { atom } from 'jotai';

import { ICampaign, IDomain, IFunnel, IOffer } from '../types';
import { IPage } from '../types';
import { $http } from '../utils/http';

export const offer = atom<IOffer | undefined>(undefined);
export const funnel = atom<IFunnel | undefined>(undefined);
export const domain = atom<IDomain | undefined>(undefined);
export const campaigns = atom<ICampaign[]>([]);
export const pages = atom<IPage[]>([]);
export const error = atom<string>('');
export const isBuilding = atom<boolean>(false);
interface IQuickBuildInner {
  offer?: IOffer;
  funnel?: IFunnel;
  domain?: IDomain;
  campaigns?: ICampaign[];
  pages?: IPage[];
  error?: string;
  isBuilding?: boolean;
}

export const buildedAtom = atom<IQuickBuildInner>((get) => ({
  offer: get(offer),
  funnel: get(funnel),
  domain: get(domain),
  campaigns: get(campaigns),
  pages: get(pages),
  error: get(error),
  isBuilding: get(isBuilding),
}));

export const createQuickFunnel = atom(null, async (get, set): Promise<any> => {
  set(isBuilding, true);

  const newOffer: any = await $http.post(`/api/v1/offers`, { body: get(offer) }).json();
  set(offer, newOffer);

  const newFunnel = await $http
    .post('/api/v1/funnels', { body: { name: get(funnel)?.name, offer: newOffer } })
    .json<IFunnel>();

  let funnelAtom = get(funnel);
  set(funnel, { ...funnelAtom, newFunnel } as IFunnel);

  const newDomain = await $http
    .post(`/api/v1/domains/`, { body: { name: get(domain)?.name, funnel_id: newFunnel.ID } })
    .json<IDomain>();

  set(domain, newDomain);

  for (const campaign of get(campaigns)) {
    await $http
      .post(`/api/v1/campaigns`, {
        body: {
          ...campaign,
          funnel_id: newFunnel.ID,
          offer_id: newOffer.ID,
        },
      })
      .json<ICampaign>();
  }
  let pushPages: any = [];
  for (const page of get(pages)) {
    let pageCreated = await $http.post(`/api/v1/pages`, { body: { ...page, funnel_id: newFunnel.ID } }).json<IPage>();
    pushPages.push(pageCreated);
  }
  set(pages, pushPages);

  if (newFunnel.ID) {
    let updatedFunnel = await $http
      .put(`/api/v1/funnels/${newFunnel.ID}`, {
        body: {
          ...get(funnel),
          ID: newFunnel.ID,
          offer: newOffer,
          page_id: get(pages)[0].ID,
        },
      })
      .json<IFunnel>();

    set(funnel, updatedFunnel);
  }

  set(isBuilding, false);
});
