import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';

import { offersAtom } from '@/atoms/offers';
import { funnel, offer, pages } from '@/atoms/quickBuild';

export const Details = ({ updateStep }) => {
  const offers = useAtomValue(offersAtom);
  const [form] = Form.useForm();
  const [funnelAtom, setFunnelAtom] = useAtom(funnel);
  const pageAtom = useAtomValue(pages);
  const offerAtom = useAtomValue(offer);

  const currencies = [
    { key: 'USD', name: 'USD' },
    { key: 'EUR', name: 'EUR' },
    { key: 'GBP', name: 'GBP' },
    { key: 'AUD', name: 'AUD' },
  ];

  const languages = [
    { key: 'en', name: 'English' },
    { key: 'de', name: 'German' },
    { key: 'fr', name: 'French' },
  ];

  const countries = [
    { key: 'US', name: 'USA' },
    { key: 'CA', name: 'Canada' },
    { key: 'GB', name: 'Great Britain' },
    { key: 'IE', name: 'Ireland' },
    { key: 'DE', name: 'Germany' },
    { key: 'FR', name: 'France' },
    { key: 'AU', name: 'Australia' },
  ];

  useEffect(() => {
    if (!funnelAtom) {
      form.resetFields();
    } else {
      form.setFieldsValue({ ...funnelAtom, offer: { offer_id: funnelAtom.offer?.offer_id } });
    }
  }, [form, funnelAtom]);

  const onFinish = (values) => {
    const data = {
      ...values,
      offer: offerAtom,
      details: {
        ...values.details,
      },
    };
    setFunnelAtom(data);
  };

  return (
    <Form form={form} layout="vertical" className="row detail" onFinish={onFinish}>
      <Row gutter={12} style={{ justifyContent: 'space-between' }}>
        <Col span={6}>
          <Form.Item
            initialValue="USD"
            name={['currency']}
            label="Currency"
            rules={[{ required: true, message: 'Please select currency' }]}
          >
            <Select placeholder="Select Currency">
              {currencies.map(({ key, name }) => (
                <Select.Option key={key} value={key}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            initialValue="en"
            name={['language']}
            label="Language"
            rules={[{ required: true, message: 'Please select language' }]}
          >
            <Select placeholder="Select Language">
              {languages.map(({ key, name }) => (
                <Select.Option key={key} value={key}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            initialValue={['US', 'CA']}
            name={['countries']}
            label="Countries"
            rules={[{ required: true, message: 'Please select at least on country' }]}
          >
            <Select placeholder="Select country" mode="multiple">
              {countries.map(({ key, name }) => (
                <Select.Option key={key} value={key}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="cs_number"
            label="Customer Support Number"
            rules={[{ required: true, message: 'Please input customer support number' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="cs_email"
            label="Customer Support E-mail"
            rules={[
              { required: true, message: 'Please input customer support Email' },
              {
                // eslint-disable-next-line no-useless-escape
                pattern: new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
                message: 'Email format is not valid',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="name" label="Offer Name" rules={[{ required: true, message: 'Please input Offer Name' }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={['details', 'GTM']}
            label="GTM"
            rules={[{ pattern: new RegExp(/^[a-zA-Z0-9]*$/), message: 'Special characters not allowed -/@`!?' }]}
          >
            <Input addonBefore="GTM-" maxLength={8} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={['details', 'OPT']}
            label="OPT"
            rules={[{ pattern: new RegExp(/^[a-zA-Z0-9]*$/), message: 'Special characters not allowed -/@`!?' }]}
          >
            <Input addonBefore="OPT-" maxLength={7} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={['details', 'UA']}
            label="UA"
            // eslint-disable-next-line no-useless-escape
            rules={[{ pattern: new RegExp(/^[0-9\.\-\/]*$/), message: 'Only numbers and "-" is allowed' }]}
          >
            <Input addonBefore="UA-" maxLength={14} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['details', 'dl_offer_name']}
            label="DataLayer Offer Name"
            rules={[{ required: true, message: 'Please input DataLayer Offer Name' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['details', 'guarantee']} label="Guarantee">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['offer', 'offer_id']}
            label="Offer ID"
            rules={[
              { required: true, message: 'Please input Offer ID(for products)' },
              { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
            ]}
          >
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
              options={offers.data.map((v) => {
                return { value: v.ID, label: `${v.name}(${v.offer_id})` };
              })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          style={{ width: '100%' }}
          name="page_id"
          label="Index page"
          rules={[{ required: true, message: 'Please input root page' }]}
        >
          <Select placeholder="Select index page">
            {!!pageAtom &&
              pageAtom.map(({ ID, name }, i) => (
                <Select.Option key={i} value={ID}>
                  {name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        </Col>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Row>
      {!!funnelAtom && (
        <div className="buildedDetails">
          <p className="savedBuild">Saved:</p>
          <p>Offer name: {funnelAtom.name}</p>
          <p>Support phone: {funnelAtom.cs_number}</p>
          <p>Support email: {funnelAtom.cs_email}</p>
          <p>Sticky Offer ID: {!!funnelAtom.offer && funnelAtom.offer.offer_id}</p>
          <p>Everflow Offer ID: {!!funnelAtom.offer && funnelAtom.offer.ef_offer_id}</p>
          <p className="info">Code:</p>
          <p>GTM: {!!funnelAtom.details && funnelAtom.details.GTM}</p>
          <p>UA: {!!funnelAtom.details && funnelAtom.details.UA}</p>
          <p>DataLayer name: {!!funnelAtom.details && funnelAtom.details.dl_offer_name}</p>
        </div>
      )}
    </Form>
  );
};
