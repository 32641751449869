import { useAuth0 } from '@auth0/auth0-react';
import { Button, Tooltip } from 'antd';
import { useAtom } from 'jotai';

import { openModalAtom } from '../../atoms/modal';

export const EditButton = (props: any) => {
  const { user }: any = useAuth0();
  const [, openModal] = useAtom(openModalAtom);
  const roles = Object.keys(user)[0];

  return (
    <Tooltip placement="topLeft" title="Edit">
      <Button
        hidden={user[roles][0] !== 'admin'}
        disabled={props.disabled}
        shape={props.shape}
        icon={props.icon}
        onClick={() => openModal(props)}
      >
        {props.children}
      </Button>
    </Tooltip>
  );
};
