import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, message, Tooltip } from 'antd';
import { useAtom } from 'jotai';

import { campaigns } from '@/atoms/quickBuild';
import { ICampaign } from '@/types';

export const Campaigns = ({ updateStep }) => {
  const [form] = Form.useForm();
  const [campaignAtom, setCampaignAtom] = useAtom(campaigns);

  const onFinish = (values) => {
    let buildedCamps = !!campaignAtom ? [...campaignAtom] : [];
    let haveIt = buildedCamps.find((camp: ICampaign) => camp.campaign_id === values.campaign_id);
    if (haveIt) {
      message.info(`Campaign [${values.campaign_id}] already added`);
      return;
    }

    setCampaignAtom([...buildedCamps, values]);
  };

  const onDelete = (ID) => {
    if (!campaignAtom) return;
    let campaigns = campaignAtom.filter((v) => v.campaign_id !== ID) as ICampaign;
    setCampaignAtom([...campaignAtom, campaigns]);
  };

  return (
    <>
      <div className="funnelCreated">
        <div className="currentFunnel">
          {!!campaignAtom?.length ? (
            <>
              {campaignAtom.map((campaign: ICampaign, i) => {
                return (
                  <div key={i} className="currentFunneldiv">
                    <div className="flexBetween">
                      <p className="domainname">ID:{campaign.campaign_id}</p>
                      <div>
                        <Tooltip title="Delete Campaign">
                          <DeleteOutlined className="deleteBtn" onClick={() => onDelete(campaign.campaign_id)} />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <h4 className="noThxAdd">No Campaigns added</h4>
          )}
        </div>
      </div>
      <hr />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Insert Campaign Id"
          name="campaign_id"
          style={{ width: '60%', margin: '10px auto' }}
          rules={[
            { required: true, message: 'Missing Campaign Id' },
            { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
          ]}
        >
          <InputNumber style={{ width: '100%' }} maxLength={5} placeholder="Campaign ID" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
