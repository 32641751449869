import { Form, Input, Modal } from 'antd';
import { useState } from 'react';

import { RichTextEditor } from '../../../Pages/TemplateEditor/Components/RichTextEditor';

export const FaqAddForm = ({ active, onAdd, onCancel }: any) => {
  const [form] = Form.useForm();
  const [html, setHtml] = useState('');

  const randstr = (prefix: any) => {
    return Math.random()
      .toString(36)
      .replace('0.', prefix || '');
  };

  const onFinish = (values: any) => {
    onAdd({
      faq_id: randstr('For_Now:'),
      faq_question: values.faq_question,
      faq_content: [{ faq_ct_text: html }],
    });
    form.resetFields();
    setHtml('');
  };

  const onTextChange = (incoming: string) => {
    setHtml(incoming);
  };

  return (
    <Modal
      open={active}
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}
    >
      <Form form={form} className="listForm" layout="vertical" onFinish={onFinish}>
        <p>Faq Question:</p>
        <Form.Item name="faq_question">
          <Input style={{ width: '50%' }} placeholder="please add question" />
        </Form.Item>
        <p>Faq Description:</p>
        <RichTextEditor onValueChange={(v) => onTextChange(v)} value={html} />
      </Form>
    </Modal>
  );
};
