import Editor from '@monaco-editor/react';
import { Form } from 'antd';
import { useEffect, useState } from 'react';

import { IFormProps } from '@/types';

export const CodeEditorForm = ({ formData, onFormReady }: IFormProps<any>) => {
  const [form] = Form.useForm();
  const [isValid, setValid] = useState(true);

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <Form.Item
        name="code"
        rules={[
          {
            validator() {
              return isValid ? Promise.resolve() : Promise.reject(`Not valid ${formData.language} code`);
            },
          },
        ]}
      >
        <Editor
          options={{
            fontSize: 15,
            lineNumbersMinChars: 2,
            formatOnType: true,
            formatOnPaste: true,
            inlineSuggest: { enabled: true },
            minimap: { enabled: false },
          }}
          height="300px"
          language={formData.language}
          onValidate={(markers) => {
            setValid(!markers.length);
          }}
        />
      </Form.Item>
    </Form>
  );
};
