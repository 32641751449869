import {
  CopyOutlined,
  DeleteOutlined,
  ForkOutlined,
  FormatPainterOutlined,
  SettingOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { Button, Modal, Space, Tooltip } from 'antd';
import { useSetAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { Handle, Position } from 'reactflow';

import { updateFunnelAtom } from '@/atoms/funnels';
import { openModalAtom } from '@/atoms/modal';
import { createPageAtom, deletePageAtom, updatePageAtom } from '@/atoms/pages';
import { IFunnel } from '@/types';
import { IPage } from '@/types';

import { PageCloneForm, PageEditForm, RedirectEditForm } from '../../../Forms';

function PageNode({ data }: { data: { funnel: IFunnel; page: IPage } }) {
  const openModal = useSetAtom(openModalAtom);
  const createPage = useSetAtom(createPageAtom);
  const deletePage = useSetAtom(deletePageAtom);
  const updateFunnel = useSetAtom(updateFunnelAtom);
  const updatePage = useSetAtom(updatePageAtom);
  const navigate = useNavigate();
  const hasFork = data.page.page_type === 'split' || data.page.page_type.startsWith('upsell');

  return (
    <>
      <Handle type="target" position={Position.Top} />
      <header>{data.page.name}</header>
      {data.page.page_path && <div className="subheader">{data.page.page_path}</div>}
      <Handle
        type="source"
        isConnectable={!data.page.page_id}
        position={Position.Bottom}
        id="a"
        style={{ left: hasFork ? '33%' : '50%' }}
      />
      {hasFork && (
        <Handle
          type="source"
          isConnectable={!data.page.page_alt_id}
          position={Position.Bottom}
          id="b"
          style={{ left: '66%' }}
        />
      )}
      {data.page.page_type !== 'split' && (
        <Space className="left">
          <Tooltip placement="left" title="Clone">
            <Button
              shape="circle"
              icon={<CopyOutlined />}
              size="small"
              onClick={() => {
                openModal({
                  component: PageCloneForm,
                  title: 'Clone Page',
                  data: data.page,
                  funnelId: data.funnel.ID,
                  onSave: (values: IPage) => {
                    const { ID, ...pageData } = data.page;
                    return createPage({ ...pageData, ...values });
                  },
                });
              }}
            />
          </Tooltip>
          <Tooltip placement="left" title="Redirect">
            <Button
              shape="circle"
              icon={<ForkOutlined />}
              size="small"
              onClick={() => {
                openModal({
                  component: RedirectEditForm,
                  data: data.page,
                  funnelId: data.funnel.ID,
                  onSave: (values: { path: string; ef: string }) =>
                    updatePage(data.page.ID, {
                      ...data.page,
                      meta: {
                        ...data.page.meta,
                        redirect: {
                          path: values.path,
                          ef: values.ef,
                        },
                      },
                    }),
                });
              }}
            />
          </Tooltip>
          {data.funnel.page_id !== data.page.ID && (
            <Tooltip placement="left" title="Make index page">
              <Button
                shape="circle"
                icon={<StarOutlined />}
                size="small"
                onClick={() => {
                  if (data.funnel.ID) {
                    updateFunnel(data.funnel.ID, { ...data.funnel, page_id: data.page.ID });
                  }
                }}
              />
            </Tooltip>
          )}
        </Space>
      )}
      <Space>
        <Tooltip placement="right" title="Edit">
          <Button
            shape="circle"
            icon={<SettingOutlined />}
            size="small"
            onClick={() => {
              openModal({
                component: PageEditForm,
                title: 'Edit Page',
                data: data.page,
                funnelId: data.funnel.ID,
                onSave: (values: IPage) =>
                  updatePage(data.page.ID, {
                    ...values,
                    funnel_id: data.funnel.ID,
                  }),
              });
            }}
          />
        </Tooltip>
        {data.page.page_type !== 'split' && (
          <Tooltip placement="right" title="Designer">
            <Button
              shape="circle"
              icon={<FormatPainterOutlined />}
              size="small"
              onClick={() => {
                navigate(`/template-editor/${data.page.ID}`);
              }}
            />
          </Tooltip>
        )}
        <Tooltip placement="right" title="Delete">
          <Button
            danger
            shape="circle"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => {
              Modal.confirm({
                title: `Delete page - ${data.page.name}(${data.page.ID})`,
                content: 'Are you sure you want to delete this page?',
                onOk: () => {
                  deletePage(data.page.ID);
                },
              });
            }}
          />
        </Tooltip>
      </Space>
    </>
  );
}

export default PageNode;
