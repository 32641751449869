import { Form, Modal } from 'antd';
import { useEffect } from 'react';

import { ImagePicker } from '../../../../common/ImagePicker';

export const SliderImageEditForm = ({ active, onEdit, item, onCancel, funnelId }: any) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ ...item });
  }, [item, form]);

  const onFinish = (e: any) => {
    onEdit({ id: item.id, ...e });
    form.resetFields();
  };

  return (
    <Modal
      open={active}
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="img" label="List Image">
          <ImagePicker funnelId={funnelId} onFinish={form.submit} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
