import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { useState } from 'react';

import { InternalActionKind, InternalActionType, InternalState } from '../../../../../types';
import { ListAddForm } from './ListAddForm';
import { ListDeleteForm } from './ListDeleteForm';
import { ListEditForm } from './ListEditForm';

enum ActionType {
  Add,
  Delete,
  Edit,
}

interface PendingAction {
  type: ActionType;
  item: any;
}

export const reducer = (state: InternalState, action: InternalActionType) => {
  const { type } = action;
  switch (type) {
    case InternalActionKind.BLACK:
      return {
        checkBlack: true,
        checkBlue: false,
        color: 'blackClass',
      };
    case InternalActionKind.BLUE:
      return {
        checkBlack: false,
        checkBlue: true,
        color: 'blueClass',
      };
    default:
      return state;
  }
};

const EditList = ({ pageData, listChange, savedFields, iframeDocument }: any) => {
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);

  const onAddListHandler = (incommingList: any) => {
    let data = !!savedFields.meta && !!savedFields.meta.list ? savedFields.meta.list : [];
    listChange({ list: [...data, incommingList] });
  };

  const onEditListHandler = (item: any) => {
    if (item && !!savedFields && !!savedFields.meta) {
      let newList = [...savedFields.meta.list];
      const index = newList.findIndex((v: any) => v.id === item.id);
      newList[index] = item;
      listChange({ list: newList });
    }
    setPendingAction(null);
  };

  const onDeleteListHandler = (itemDeleted: any) => {
    if (itemDeleted && !!savedFields && !!savedFields.meta) {
      let listsFiltered = savedFields.meta.list.filter((v) => v.id !== itemDeleted.id);
      listChange({ list: listsFiltered });
    }
    setPendingAction(null);
  };

  return (
    <>
      {!!savedFields && !!savedFields.meta && !!savedFields.meta.list ? (
        savedFields.meta.list.map((item: any, index: any) => {
          return (
            <Card className="card-products" size="small" key={String(index)}>
              <div className="_lists" style={{ wordBreak: 'break-all' }}>
                {item.boldText && <div>{!item.boldText ? null : item.boldText}</div>}
                <div>{item.listText}</div>
              </div>
              <Button
                type="primary"
                className="removeBTN"
                danger
                shape="circle"
                onClick={() => {
                  setPendingAction({ type: ActionType.Delete, item });
                }}
              >
                <DeleteOutlined />
              </Button>
              <Button
                type="primary"
                className="editBTN"
                shape="circle"
                onClick={() => setPendingAction({ type: ActionType.Edit, item })}
              >
                <EditOutlined />
              </Button>
            </Card>
          );
        })
      ) : (
        <p style={{ textAlign: 'center' }}>No List added</p>
      )}
      <div
        className="addIconPlus"
        onClick={() => {
          setPendingAction({ type: ActionType.Add, item: null });
        }}
      >
        <PlusOutlined />
      </div>
      <ListDeleteForm
        active={pendingAction?.type === ActionType.Delete}
        iframeDocument={iframeDocument}
        onFinished={(itemDeleted: any) => onDeleteListHandler(itemDeleted)}
        onCancel={() => setPendingAction(null)}
        pendingProduct={pendingAction?.item}
      />
      <ListAddForm
        onCancel={() => setPendingAction(null)}
        onOk={() => setPendingAction(null)}
        active={pendingAction?.type === ActionType.Add}
        pageData={pageData}
        onAdd={(list: any) => onAddListHandler(list)}
      />
      {pendingAction?.item && (
        <ListEditForm
          active={pendingAction?.type === ActionType.Edit}
          onEdit={(item: any) => onEditListHandler(item)}
          onCancel={() => setPendingAction(null)}
          item={pendingAction?.item}
          pageData={pageData}
          onDeleteItem={(list: any) => onDeleteListHandler(list)}
        />
      )}
    </>
  );
};

export default EditList;
