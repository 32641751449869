import Campaign from './components/Pages/Campaign';
import Categories from './components/Pages/Categories';
import Dashboard from './components/Pages/Dashboard';
import Domains from './components/Pages/Domains';
import Funnel from './components/Pages/Funnel';
import Funnels from './components/Pages/Funnels';
import Offer from './components/Pages/Offer';
import Offers from './components/Pages/Offers';
import Orders from './components/Pages/Orders';
import Product from './components/Pages/Product';
import Scrub from './components/Pages/Scrub';
import Shipping from './components/Pages/Shipping';
import Sticky from './components/Pages/Sticky';
import TemplateEditor from './components/Pages/TemplateEditor';
import Templates from './components/Pages/Templates';
import Users from './components/Pages/Users';
import { Page404 } from './components/UI/404';

export const ROUTES = [
  {
    path: '/',
    component: Dashboard,
  },
  {
    path: '/funnels',
    component: Funnels,
  },
  {
    path: '/funnels/:id/*',
    component: Funnel,
  },
  {
    path: '/offers',
    component: Offers,
  },
  {
    path: '/offer/:id/*',
    component: Offer,
  },
  {
    path: '/domains',
    component: Domains,
  },
  {
    path: '/template-editor/:id',
    component: TemplateEditor,
  },
  {
    path: '/campaigns/:id',
    component: Campaign,
  },
  {
    path: '/scrubs/:id',
    component: Scrub,
  },
  {
    path: '/products/:id',
    component: Product,
  },
  {
    path: '/templates',
    component: Templates,
  },
  {
    path: '/users',
    component: Users,
  },
  {
    path: '/categories',
    component: Categories,
  },
  {
    path: '/orders',
    component: Orders,
  },
  {
    path: '/shipping',
    component: Shipping,
  },
  {
    path: '/sticky',
    component: Sticky,
  },
  {
    path: '*',
    component: Page404,
  },
];
