import { atom } from 'jotai';

import { IFunnel } from '../types';
import { $http } from '../utils/http';
import { progressAtom } from './progress';

const dataAtom = atom<IFunnel[]>([]);
const funnel = atom<IFunnel | undefined>(undefined);
const funnelQuick = atom<IFunnel | undefined>(undefined);
const isLoading = atom<boolean>(false);
const isBuilding = atom<boolean>(false);

export interface FunnelsState {
  data: IFunnel[];
  funnel: IFunnel | undefined;
  funnelQuick: IFunnel | undefined;
  isLoading: boolean;
  isBuilding: boolean;
}

export const funnelsAtom = atom<FunnelsState>((get) => ({
  data: get(dataAtom),
  funnel: get(funnel),
  funnelQuick: get(funnelQuick),
  isLoading: get(isLoading),
  isBuilding: get(isBuilding),
}));

export const fetchFunnelsAtom = atom(
  (get) => get(funnelsAtom),
  async (_, set) => {
    set(isLoading, true);
    $http
      .get('/api/v1/funnels')
      .json<IFunnel[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const fetchFunnelAtom = atom(null, async (get, set, funnelId: number) => {
  set(isLoading, true);
  $http
    .get(`/api/v1/funnels/${funnelId}`)
    .json<IFunnel>()
    .then((response) => set(funnel, response))
    .finally(() => set(isLoading, false));
});

export const createFunnelAtom = atom(null, async (get, set, body?: IFunnel): Promise<IFunnel> => {
  set(isLoading, true);
  return $http
    .post('/api/v1/funnels', { body })
    .json<IFunnel>()
    .finally(() => set(isLoading, false));
});

export const updateFunnelAtom = atom(null, async (get, set, funnelId: number, body: Partial<IFunnel>) => {
  const funnels = get(dataAtom);
  set(isLoading, true);
  $http
    .put(`/api/v1/funnels/${funnelId}`, { body })
    .json<IFunnel>()
    .then((updatedFunnel) => {
      set(dataAtom, funnels.updateValueBy('ID', updatedFunnel));
      set(funnel, updatedFunnel);
    })
    .finally(() => set(isLoading, false));
});

export const deleteFunnelAtom = atom(null, async (get, set, id?: number) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .delete(`/api/v1/funnels/${id}`)
    .json<IFunnel>()
    .then(() =>
      set(
        dataAtom,
        get(dataAtom).filter(({ ID }) => ID !== id),
      ),
    )
    .finally(() => set(isLoading, false));
});

export const cloneFunnelAtom = atom(null, async (get, set, funnelId: number, body: IFunnel): Promise<IFunnel> => {
  set(isLoading, true);
  return $http
    .post(`/api/v1/funnels/${funnelId}/clone`, { body })
    .json<IFunnel>()
    .finally(() => set(isLoading, false));
});

export const lockFunnelAtom = atom(null, async (get, set, funnelId: number) => {
  const funnels = get(dataAtom);
  set(isLoading, true);
  $http
    .put(`/api/v1/funnels/${funnelId}/lock`)
    .json<IFunnel>()
    .then((updatedFunnel) => {
      set(dataAtom, funnels.updateValueBy('ID', updatedFunnel));
      set(funnel, updatedFunnel);
    })
    .finally(() => set(isLoading, false));
});

export const buildFunnelAtom = atom(null, async (get, set, funnelId: number) => {
  set(isBuilding, true);
  $http
    .put(`/api/v1/funnels/${funnelId}/publish`)
    .jsonStream<{ progress: number; message: string }>((data) => {
      set(progressAtom, {
        percent: data.progress,
        message: data.message,
      });
    })
    .finally(() => set(isBuilding, false));
});
