import { Table, Tooltip } from 'antd';
import { useAtomValue } from 'jotai';
import { Link } from 'react-router-dom';

import { offersAtom } from '@/atoms/offers';
import { IFunnel, IRouteParams } from '@/types';
import { columnSearch, commonFields, enableSorting, SortOrder } from '@/utils/table';

export const OfferFunnels = ({ offer }: IRouteParams) => {
  const offers = useAtomValue(offersAtom);
  const columns = [
    {
      title: 'ID',
      ...commonFields('ID'),
      ...enableSorting('ID', SortOrder.DESCEND),
    },
    {
      title: 'Funnel Name',
      ...commonFields('name'),
      ...columnSearch('name'),
      render: (_: any, funnel: IFunnel) => (
        <Tooltip placement="right" title="View Funnel">
          <Link style={{ textDecoration: 'underline' }} to={`/funnels/${funnel.ID}/domains`}>
            {funnel.name}
          </Link>
        </Tooltip>
      ),
    },
  ];
  return (
    <Table
      loading={offers.isLoading}
      style={{ width: '100%' }}
      dataSource={offer?.funnels}
      rowKey="ID"
      columns={columns as any}
      pagination={false}
    />
  );
};
