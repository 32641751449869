import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { useState } from 'react';

import { ShippingAddForm } from './ShippingAddForm';
import { ShippingDeleteForm } from './ShippingDeleteForm';
import { ShippingEditForm } from './ShippingEditForm';

enum ActionType {
  Add,
  Delete,
  Edit,
}

interface PendingAction {
  type: ActionType;
  item: any;
}
const EditShipping = ({ pendingSaveData, pageData, listChange, iframeDocument }: any) => {
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);

  const onAddShippingHandler = (incomingProduct: any) => {
    let data = !!pendingSaveData.meta && !!pendingSaveData.meta.intShipping ? pendingSaveData.meta.intShipping : [];
    listChange({ intShipping: [...data, incomingProduct] });
    setPendingAction(null);
  };

  const onEditShippingHandler = (item: any) => {
    if (item && !!pendingSaveData && !!pendingSaveData.meta) {
      let newList = [...pendingSaveData.meta.intShipping];
      const index = newList.findIndex((v: any) => v.id === item.id);
      newList[index] = item;
      listChange({ intShipping: newList });
    }
    setPendingAction(null);
  };

  const onDeleteShippingHandler = (itemDeleted: any) => {
    if (itemDeleted && !!pendingSaveData && !!pendingSaveData.meta) {
      let intShippingFiltered = pendingSaveData.meta.intShipping.filter((v) => v.id !== itemDeleted.id);
      listChange({ intShipping: intShippingFiltered });
    }
    setPendingAction(null);
  };

  return (
    <>
      {!!pendingSaveData && !!pendingSaveData.meta && !!pendingSaveData.meta.intShipping ? (
        pendingSaveData.meta.intShipping.map((item: any, index: any) => {
          return (
            <Card className="card-products" size="small" key={String(index)}>
              <div style={{ overflow: 'hidden', width: '70%' }}>
                <span>
                  ({item.shipping_id}) - {item.country} - {item.price}
                </span>
              </div>
              <Button
                type="primary"
                className="removeBTN"
                danger
                shape="circle"
                onClick={() => {
                  setPendingAction({ type: ActionType.Delete, item });
                }}
              >
                <DeleteOutlined />
              </Button>
              <Button
                type="primary"
                className="editBTN"
                shape="circle"
                onClick={() => setPendingAction({ type: ActionType.Edit, item })}
              >
                <EditOutlined />
              </Button>
            </Card>
          );
        })
      ) : (
        <p style={{ textAlign: 'center' }}>No Shipping added</p>
      )}
      <div
        className="addIconPlus"
        onClick={() => {
          setPendingAction({
            type: ActionType.Add,
            item: !!pendingSaveData.meta && !!pendingSaveData.meta.intShipping && pendingSaveData.meta.intShipping,
          });
        }}
      >
        <PlusOutlined />
      </div>
      <ShippingDeleteForm
        active={pendingAction?.type === ActionType.Delete}
        iframeDocument={iframeDocument}
        onFinished={(itemDeleted: any) => onDeleteShippingHandler(itemDeleted)}
        onCancel={() => setPendingAction(null)}
        pendingProduct={pendingAction?.item}
      />
      <ShippingAddForm
        active={pendingAction?.type === ActionType.Add}
        onAdd={(product: any) => onAddShippingHandler(product)}
        onCancel={() => setPendingAction(null)}
        pageData={pageData}
      />
      <ShippingEditForm
        active={pendingAction?.type === ActionType.Edit}
        onEdit={(item: any) => onEditShippingHandler(item)}
        onCancel={() => setPendingAction(null)}
        item={pendingAction?.item}
        pageData={pageData}
      />
    </>
  );
};

export default EditShipping;
