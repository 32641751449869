import { Button, Popconfirm } from 'antd';

export const DeleteButton = (props: any) => {
  return (
    <Popconfirm
      title="Are you sure you want to remove?"
      okText="Yes"
      cancelText="No"
      onConfirm={props.onConfirm}
      disabled={props.disabled}
    >
      <Button shape={props.shape} icon={props.icon} disabled={props.disabled}>
        {props.children}
      </Button>
    </Popconfirm>
  );
};
