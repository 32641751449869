import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Image } from 'antd';
import { useState } from 'react';

import { ExtraReviewAddForm } from './ExtraReviewAddForm';
import { ExtraReviewDeleteForm } from './ExtraReviewDeleteForm';
import { ExtraReviewEditForm } from './ExtraReviewEditForm';

enum ActionType {
  Add,
  Delete,
  Edit,
}

interface PendingAction {
  type: ActionType;
  item: any;
}
// extraReviews
const EditExtraReview = ({ pageData, listChange, savedFields, iframeDocument }: any) => {
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);

  const onAddReviewExtraHandler = (incomingExtraReview: any) => {
    let data =
      !!savedFields && !!savedFields.meta && !!savedFields.meta.extraReviews ? savedFields.meta.extraReviews : [];
    listChange({ extraReviews: [...data, incomingExtraReview] });
    setPendingAction(null);
  };

  const onEditReviewExtraHandler = (item: any) => {
    if (item && !!savedFields) {
      let newList = [...savedFields.meta.extraReviews];
      const index = newList.findIndex((v: any) => v.id === item.id);
      newList[index] = item;
      listChange({ extraReviews: newList });
    }
    setPendingAction(null);
  };

  const onDeleteReviewExtraHandler = (itemDeleted: any) => {
    if (itemDeleted && !!savedFields) {
      let reviewsFiltered = savedFields.meta.extraReviews.filter((v) => v.id !== itemDeleted.id);
      listChange({ extraReviews: reviewsFiltered });
    }
    setPendingAction(null);
  };

  return (
    <>
      {!!savedFields && !!savedFields.meta && !!savedFields.meta.extraReviews ? (
        savedFields.meta.extraReviews.map((item: any, index: any) => {
          return (
            <Card className="card-products" size="small" key={String(index)}>
              <div style={{ overflow: 'hidden', width: '70%' }}>
                <span>
                  {<Image width={'40px'} src={`${item.avatar || ''}`} preview={false} />} - {item.user} - {item.review}
                </span>
              </div>
              <Button
                type="primary"
                className="removeBTN"
                danger
                shape="circle"
                onClick={() => {
                  setPendingAction({ type: ActionType.Delete, item });
                }}
              >
                <DeleteOutlined />
              </Button>
              <Button
                type="primary"
                className="editBTN"
                shape="circle"
                onClick={() => setPendingAction({ type: ActionType.Edit, item })}
              >
                <EditOutlined />
              </Button>
            </Card>
          );
        })
      ) : (
        <p style={{ textAlign: 'center' }}>No Extra Reviews added</p>
      )}
      <div
        className="addIconPlus"
        onClick={() => {
          setPendingAction({ type: ActionType.Add, item: null });
        }}
      >
        <PlusOutlined />
      </div>
      <ExtraReviewDeleteForm
        active={pendingAction?.type === ActionType.Delete}
        iframeDocument={iframeDocument}
        onFinished={(itemDeleted: any) => onDeleteReviewExtraHandler(itemDeleted)}
        pendingProduct={pendingAction?.item}
        onCancel={() => setPendingAction(null)}
      />
      <ExtraReviewAddForm
        active={pendingAction?.type === ActionType.Add}
        onAdd={(item: any) => onAddReviewExtraHandler(item)}
        onCancel={() => setPendingAction(null)}
      />
      <ExtraReviewEditForm
        active={pendingAction?.type === ActionType.Edit}
        onEdit={(item: any) => onEditReviewExtraHandler(item)}
        item={pendingAction?.item}
        onCancel={() => setPendingAction(null)}
      />
    </>
  );
};

export default EditExtraReview;
