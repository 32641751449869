import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, Col, Divider, message, Row, Upload } from 'antd';

import { $http } from '../../utils/http';

export const CampaignFulFillmentEditForm = () => {
  function handleUrlSchema(url: string) {
    const isUrl = new RegExp(/^https?:\/\//i);
    if (url.match(isUrl)) return url;
    return !!import.meta.env.VITE_PROXY ? `${import.meta.env.VITE_PROXY}${url}` : url;
  }
  const props: UploadProps = {
    accept: '.csv',
    multiple: false,
    name: 'file',
    action: handleUrlSchema(`/api/v1/csv_upload/sticky/campaigns_fulfillment`),
    headers: { Authorization: `Bearer ${$http.getToken()}` },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <Row>
        <Col>
          <a href="/campaign_fulfillment.csv" download="campaign_fulfillment.csv">
            Campaign FulFillment CSV Example
          </a>
        </Col>
      </Row>
      <Divider plain />
      <Row>
        <Col>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Col>
      </Row>
    </>
  );
};
