import { Button, Form, Input, Modal, Select } from 'antd';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';

import { updatePageAtom } from '@/atoms/pages';
import { error, funnel, pages } from '@/atoms/quickBuild';
import { ActionType, PendingAction } from '@/types';

import { TemplateQuickEditor } from '../../Pages/TemplateEditor';

export const Design = ({ onDone }) => {
  const [pagesAtom, setPageAtom] = useAtom(pages);
  const funnelAtom = useAtomValue(funnel);
  const updatePage = useSetAtom(updatePageAtom);
  const setErrorAtom = useSetAtom(error);
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);

  const onPageEdit = (event, page) => {
    let dataTosend = {
      ...page,
      page_id: event.page_id,
      page_alt_id: event.page_alt_id,
      funnel_id: funnelAtom?.ID,
      meta: { ...page.meta, nextPage: pagesAtom.find((v) => v.ID === event.page_id)?.page_path },
    };
    updatePage(page.ID, dataTosend);

    let copyPageAtom = [...pagesAtom];
    let index = pagesAtom.findIndex((p) => p.ID === page.ID);
    copyPageAtom[index] = dataTosend;
    setPageAtom(copyPageAtom);

    setPendingAction(null);
  };

  const goDesign = (page) => {
    if (!page.ID) {
      return setErrorAtom('Missing Page ID');
    }
    setPendingAction({ type: ActionType.Add, item: page });
  };

  console.log(pagesAtom);

  return (
    <>
      {!!pagesAtom.length ? (
        pagesAtom.map((page, index) => {
          return (
            <div key={index} className="flexBetween pageBuilded">
              {pendingAction?.type === ActionType.Edit && pendingAction.item.name === page.name ? (
                <Form layout="vertical" onFinish={(e) => onPageEdit(e, page)}>
                  <Form.Item
                    initialValue={page.name}
                    label="Page Name"
                    name="name"
                    rules={[{ required: true, message: 'Missing Name' }]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                  <Form.Item
                    name="page_id"
                    label="Next Page (action)"
                    tooltip="Redirect page on main action"
                    initialValue={page.page_id}
                  >
                    <Select
                      placeholder="Select Next page"
                      options={[
                        { value: null, label: '-- None --' },
                        ...pagesAtom
                          .filter(({ ID }) => page.ID !== ID)
                          .map(({ ID, name, page_path }) => ({ value: ID, label: `${name} - ${page_path}` })),
                      ]}
                    ></Select>
                  </Form.Item>
                  <Form.Item
                    name="page_alt_id"
                    label="Next Page (skip)"
                    tooltip="Redirect page on no thanks action"
                    initialValue={page.page_alt_id}
                  >
                    <Select
                      placeholder="Select index page"
                      options={[
                        { value: null, label: '-- None --' },
                        ...pagesAtom
                          .filter(({ ID }) => page.ID !== ID)
                          .map(({ ID, name, page_path }) => ({ value: ID, label: `${name} - ${page_path}` })),
                      ]}
                    ></Select>
                  </Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save / Exit
                  </Button>
                </Form>
              ) : (
                <p>
                  ({page.ID}) {page.name}
                </p>
              )}
              <div>
                <Button onClick={() => setPendingAction({ type: ActionType.Edit, item: page })}>Edit</Button>
                <Button onClick={() => goDesign(page)}>Design</Button>
              </div>
            </div>
          );
        })
      ) : (
        <p>No Pages added</p>
      )}
      {pendingAction?.item && (
        <Modal
          className="quickDesignModal"
          open={pendingAction.type === ActionType.Add}
          onCancel={() => setPendingAction(null)}
          footer={null}
        >
          <TemplateQuickEditor quickPageItem={pendingAction.item} />
        </Modal>
      )}
    </>
  );
};
