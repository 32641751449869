import { Button, Checkbox, Form, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useAtom, useAtomValue } from 'jotai';
import { useState } from 'react';

import { updateMetaAtom } from '../../../../../atoms/pages';
import { productsAtom } from '../../../../../atoms/products';
import { getNumberFromString } from '../../../../../utils/numbers';

interface ParentProps {
  onAdd: Function;
  pageData: any;
}

enum Pack {
  'Single Pack' = 1,
  'Studio Pack' = 2,
  'Multi Pack' = 3,
  'Deluxe Pack' = 4,
  'Family Pack' = 5,
}

enum Discount {
  '50%' = 1,
  '55%' = 2,
  '60%' = 3,
  '65%' = 4,
  '70%' = 5,
}

export const AddModal = ({ onAdd, pageData }: ParentProps) => {
  const [form] = Form.useForm();
  const [, updateMeta] = useAtom(updateMetaAtom);
  const products = useAtomValue(productsAtom);
  const [overridePriceCheck, setoverridePriceCheck] = useState(!!pageData.meta && pageData.meta.product_override_price);
  const [inputValues, setInputValues] = useState([]) as any;

  const AddProduct = (product: any) => {
    onAdd(product);
  };

  const onOverridePriceChange = (e: CheckboxChangeEvent) => {
    setoverridePriceCheck(e.target.checked);
    updateMeta({ product_override_price: e.target.checked });
  };

  const onSearch = () => {
    let prods = products.data.filter(({ ID }) => inputValues.some((i) => +i === ID));
    let productToAdd = prods.map((prod: any) => {
      const productquantity = getNumberFromString(prod.name);

      let dataToReturn: any = {
        preselected: false,
        showDiscount: true,
        product_id: `${prod.product_id}`,
        product_data_name: prod.name,
        product_data_receipt: prod.name,
        product_data_price: parseFloat(prod.product_price).toFixed(2),
        product_data_save: parseFloat(prod.product_price).toFixed(2),
        product_data_unitPrice: (parseFloat(prod.product_price) / (!productquantity ? 1 : productquantity)).toFixed(2),
        product_data_warranty: (
          (parseFloat(prod.product_price) * 0.2) /
          (!productquantity ? 1 : productquantity)
        ).toFixed(2),
        product_data_regPrice: (parseFloat(prod.product_price) * 2).toFixed(2),
        product_data_quantity: !productquantity ? 1 : productquantity,
        product_data_package_discount: !productquantity ? '50%' : (Discount[productquantity] ? Discount[productquantity] : Discount[1]),
        product_data_package_name: !productquantity ? '' : (Pack[productquantity] ? Pack[productquantity] : Pack[1]),
        product_info: '',
        product_data_cart_name: prod.name,
        product_data_box_name: '',
        free_ship: 'https://offer.buybuzzbugg.com/offer/1/app/desktop/images/free-ship.png',
        free_shipping_text: 'Free Shipping',
        shippingId: prod.shippingId,
        pack: '72',
        product_image: 'https://via.placeholder.com/600x400?text=productImg',
        sellOut: 'Moderate',
        free_gift: '+ PLUS 3x FREE Veggie Spiralizer(s) w/ Purchase',
        product_unit_img: '',
        product_data_upsell_name: '3x Product Name',
        product_color: 'Blue',
        product_size_name: '',
        features: [],
      };

      let existingKeys = Object.keys(pageData.template.meta.products[0]);
      let hash: any = {};
      existingKeys.forEach((v: any) =>
        Object.keys(dataToReturn).some((c) => (v === c ? (hash[v] = dataToReturn[c]) : null)),
      );

      return hash;
    });

    AddProduct(productToAdd);
    form.resetFields();
  };

  return (
    <Form form={form}>
      <Checkbox className="override" checked={overridePriceCheck} onChange={onOverridePriceChange}>
        Enable override price
      </Checkbox>
      <Select
        showSearch
        style={{ width: '100%' }}
        mode="multiple"
        placeholder="Select product"
        optionFilterProp="children"
        onChange={setInputValues}
        value={inputValues}
      >
        {Array.from(new Set(products.data.map((a: any) => a.product_id)))
          .map((id: any) => {
            return products.data.find((a: any) => a.product_id === id);
          })
          .map((prod) => {
            if (!prod) return null;
            return (
              <Select.Option key={prod.ID}>
                ({prod.product_id}){prod.name}
              </Select.Option>
            );
          })}
      </Select>
      {inputValues.length > 0 && (
        <Button className="addButton" onClick={onSearch}>
          Add All
        </Button>
      )}
    </Form>
  );
};
