import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

const ActiveTag = () => (
  <Tag color={'success'} icon={<CheckCircleOutlined />}>
    Active
  </Tag>
);

export const InactiveTag = () => (
  <Tag color={'default'} icon={<MinusCircleOutlined />}>
    Inactive
  </Tag>
);

const ActivityStatus = ({ active }: { active: boolean }) => (active ? <ActiveTag /> : <InactiveTag />);

export default ActivityStatus;
