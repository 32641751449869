import { atom } from 'jotai';

import { IShipping } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<IShipping[]>([]);
const isLoading = atom<boolean>(false);
const isSyncing = atom<boolean>(false);

export interface ShippingState {
  data: IShipping[];
  isLoading: boolean;
  isSyncing: boolean;
}

export const shippingsAtom = atom<ShippingState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
  isSyncing: get(isSyncing),
}));

export const fetchShippingAtom = atom(
  (get) => get(shippingsAtom),
  async (_, set) => {
    set(isLoading, true);
    $http
      .get('/api/v1/shippings')
      .json<IShipping[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const createShippingAtom = atom(null, async (get, set, body?: IShipping) => {
  if (!body) return;
  set(isLoading, true);
  $http
    .post('/api/v1/shippings', { body })
    .json<IShipping>()
    .then((response) => set(dataAtom, [...get(dataAtom), response]))
    .finally(() => set(isLoading, false));
});

export const updateShippingAtom = atom(null, async (get, set, id: number, body: Partial<IShipping>) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .put(`/api/v1/shippings/${id}`, { body })
    .json<IShipping>()
    .then((shipping) => set(dataAtom, get(dataAtom).updateValueBy('ID', shipping)))
    .finally(() => set(isLoading, false));
});

export const deleteShippingAtom = atom(null, async (get, set, id?: number) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .delete(`/api/v1/shippings/${id}`)
    .json<IShipping>()
    .then(() =>
      set(
        dataAtom,
        get(dataAtom).filter(({ ID }) => ID !== id),
      ),
    )
    .finally(() => set(isLoading, false));
});

export const syncShippingAtom = atom(null, async (get, set, shipping: IShipping) => {
  set(isSyncing, true);
  $http
    .post(`/api/v1/shippings/sync/${shipping.ID}`)
    .json<IShipping>()
    .then((shipping) => set(dataAtom, get(dataAtom).updateValueBy('ID', shipping)))
    .finally(() => set(isSyncing, false));
});
