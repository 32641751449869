import { Form, Modal } from 'antd';

import { ImagePicker } from '../../../../common/ImagePicker';

export const SliderImageAddForm = ({ active, onAdd, onCancel, funnelId }: any) => {
  const [form] = Form.useForm();

  const randstr = (prefix: any) => {
    return Math.random()
      .toString(36)
      .replace('0.', prefix || '');
  };

  const onFinish = ({ img }: any) => {
    onAdd({
      id: randstr('For_Now:'),
      img,
    });

    form.resetFields();
  };

  return (
    <Modal
      open={active}
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="img" label="List Image">
          <ImagePicker funnelId={funnelId} onFinish={form.submit} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
