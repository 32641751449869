import { atom } from 'jotai';

import { IProduct } from '../types';
import { IProductPayload } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<IProduct[]>([]);
const isLoading = atom<boolean>(false);

export interface ProductState {
  data: IProduct[];
  isLoading: boolean;
}

export const productsAtom = atom<ProductState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
}));

export const fetchProductsAtom = atom(
  (get) => get(productsAtom),
  async (_, set, funnelId?: number) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/funnels/${funnelId}/products`)
      .json<IProduct[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const fetchProductsByOfferAtom = atom(
  (get) => get(productsAtom),
  async (_, set, offerId: number) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/products/offer/${offerId}`)
      .json<IProduct[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const createProductAtom = atom(null, async (get, set, body?: IProductPayload) => {
  if (!body) return;
  set(isLoading, true);
  $http
    .post(`/api/v1/products`, { body })
    .json<IProduct>()
    .then((response) => set(dataAtom, [...get(dataAtom), response]))
    .finally(() => set(isLoading, false));
});

export const createProductBundleAtom = atom(null, async (get, set, body?: IProduct) => {
  if (!body) return;
  set(isLoading, true);
  $http
    .post('/api/v1/products/bundle', { body })
    .json<IProduct>()
    .then((response) => set(dataAtom, [...get(dataAtom), response]))
    .finally(() => set(isLoading, false));
});

export const updateProductAtom = atom(null, async (get, set, id: number, body: Partial<IProduct>) => {
  if (!body) return;
  const products = get(productsAtom);
  set(isLoading, true);
  $http
    .put(`/api/v1/products/${id}`, { body })
    .json<IProduct>()
    .then((product) => set(dataAtom, products.data.updateValueBy('ID', product)))
    .finally(() => set(isLoading, false));;
});

export const deleteProductAtom = atom(null, async (get, set, id?: number) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .delete(`/api/v1/products/${id}`)
    .json<IProduct>()
    .then(() =>
      set(
        dataAtom,
        get(dataAtom).filter(({ ID }) => ID !== id),
      ),
    )
    .finally(() => set(isLoading, false));
});
