import './App.css';

import { useAuth0 } from '@auth0/auth0-react';
import { FC, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import MainLayout from './components/Layout';
import Loading from './components/Loading';
import SignIn from './components/SignIn';
import { getConfig } from './config';
import { $http } from './utils/http';

const config = getConfig();

const App: FC = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, error } = useAuth0();
  const [gettingToken, gotToken] = useState(false);
  if (error) return <div>Oops... {error.message}</div>;
  if (isAuthenticated && !isLoading) {
    getAccessTokenSilently({ audience: config.audience })
      .then((token: string) => $http.setToken(token))
      .finally(() => gotToken(true));
  }
  return isLoading || (isAuthenticated && !gettingToken) ? (
    <Loading />
  ) : (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<SignIn />} />
          {isAuthenticated ? (
            <Route path="*" element={<MainLayout />} />
          ) : (
            <Route path="*" element={<Navigate replace to="/login" />} />
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
