import { CloseCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';

import { ActionType, PendingAction } from '../../../../../types';
import { AddSize } from './AddSize';

export const Size = ({ pageData, listChange }) => {
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);

  const addSizeHandler = (item) => {
    let currenctSizes = !!pageData && !!pageData.meta && !!pageData.meta.productSizes ? pageData.meta.productSizes : [];
    listChange({ productSizes: [...currenctSizes, item] });
    setPendingAction(null);
  };

  const deleteSizeHandler = (index) => {
    let currenctSizes = !!pageData && !!pageData.meta && !!pageData.meta.productSizes && pageData.meta.productSizes;
    let cloneArr = [...currenctSizes];
    cloneArr.splice(index, 1);
    listChange({ productSizes: cloneArr });
  };

  return (
    <>
      <p className="info_text">Product Size:</p>
      <Button onClick={() => setPendingAction({ type: ActionType.Add, item: null })}>Add</Button>
      <div className="sizeContainer">
        {!!pageData &&
          !!pageData.meta &&
          !!pageData.meta.productSizes &&
          pageData.meta.productSizes.map((v, i) => {
            return (
              <span key={i} className="sizeElement">
                {v}
                <CloseCircleFilled onClick={() => deleteSizeHandler(i)} className="sizeClose" />
              </span>
            );
          })}
      </div>
      {pendingAction?.type === ActionType.Add && (
        <AddSize
          onAdd={(item) => addSizeHandler(item)}
          active={pendingAction.type === ActionType.Add}
          onCancel={() => setPendingAction(null)}
          onOk={() => setPendingAction(null)}
          pageData={pageData}
        />
      )}
    </>
  );
};
