import { CopyOutlined, DeleteOutlined, EditFilled } from '@ant-design/icons';
import { Button, Space, Table, Tooltip } from 'antd';
import { useAtom } from 'jotai';
import type { AlignType } from 'rc-table/es/interface';
import { useEffect } from 'react';

import { deleteDomainAtom, fetchFunnelDomainsAtom, updateDomainAtom } from '@/atoms/domains';
import { IDomain } from '@/types';
import { columnSearch, commonFields, enableSorting, SortOrder } from '@/utils/table';

import { DomainEditForm } from '../../Forms';
import Loading from '../../Loading';
import { DeleteButton } from '../../UI/DeleteButton';
import { EditButton } from '../../UI/EditButton';

const Domains = ({ id, funnel }: any) => {
  const [domains, fetchFunnelDomains] = useAtom(fetchFunnelDomainsAtom);
  const [, deleteDomain] = useAtom(deleteDomainAtom);
  const [, updateDomain] = useAtom(updateDomainAtom);

  useEffect(() => {
    fetchFunnelDomains(id);
  }, [fetchFunnelDomains, id]);

  const confirm = (domain: IDomain) => {
    deleteDomain(domain.ID);
  };

  const columns = [
    {
      title: 'ID',
      ...commonFields('ID'),
      ...enableSorting('ID', SortOrder.DESCEND),
      width: 100,
      align: 'center' as AlignType,
    },
    {
      title: 'Domain name',
      ...commonFields('name'),
      ...columnSearch('name'),
      render: (_: any, domain: IDomain) => {
        const fullname = [domain.name, domain.domain?.name].join('.');
        return (
          <>
            <Button type="link">
              <a href={'https://' + fullname} target="blank">
                {fullname}
              </a>
            </Button>
            <Tooltip trigger={'click'} placement="topLeft" title={'copied to clipboard!'}>
              <Button
                onClick={() => {
                  if (fullname) {
                    navigator.clipboard.writeText(fullname);
                  }
                }}
                icon={<CopyOutlined />}
                shape="circle"
              ></Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Action',
      ...commonFields('action'),
      width: 100,
      align: 'center' as AlignType,
      render: (_: any, domain: IDomain) => (
        <Space>
          <EditButton
            data={domain}
            funnelId={id}
            title="Edit subdomain"
            component={DomainEditForm}
            disabled={funnel?.locked}
            shape="circle"
            icon={<EditFilled />}
            onSave={(values: IDomain) => updateDomain(domain.ID, values)}
          />
          <DeleteButton
            onConfirm={() => confirm(domain)}
            disabled={funnel?.locked}
            shape="circle"
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ];
  if (domains.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Table
        style={{ width: '100%' }}
        dataSource={domains.funnelDomains}
        rowKey="ID"
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default Domains;
