import { DiffOutlined, PieChartOutlined, PlusCircleOutlined, SnippetsOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { Route, Routes, useMatch, useNavigate, useParams } from 'react-router-dom';

import { addCampaignAtom } from '@/atoms/campaigns';
import { openModalAtom } from '@/atoms/modal';
import { fetchOfferAtom, offersAtom } from '@/atoms/offers';
import { createProductAtom } from '@/atoms/products';
import { IProductPayload, IRouteParams } from '@/types';

import { CampaignAddForm, ProductsAddForm } from '../../Forms';
import Loading from '../../Loading';
import PageHeader from '../../PageHeader';
import OfferCampaign from './OfferCampaign';
import { OfferFunnels } from './OfferFunnels';
import { OfferProducts } from './OfferProducts';

const Offer = () => {
  const { id }: IRouteParams = useParams();
  const navigate = useNavigate();
  const [, openModal] = useAtom(openModalAtom);
  const [, fetchOffer] = useAtom(fetchOfferAtom);
  const addCampaign = useSetAtom(addCampaignAtom);
  const createProduct = useSetAtom(createProductAtom);
  const offers = useAtomValue(offersAtom);
  const match = useMatch('offer/:id/:tabKey');
  const tabKey = match?.params.tabKey || 'offers';
  const [textPlatform, setTextPlatform] = useState('Sticky Offer Id');

  const categories: any = {
    funnels: {
      name: 'Funnels',
      icon: SnippetsOutlined,
      color: 'blue',
      component: OfferFunnels,
    },
    products: {
      name: 'Products',
      icon: PieChartOutlined,
      color: 'red',
      component: OfferProducts,
      action: {
        title: 'Create Products on Sticky',
        component: ProductsAddForm,
        onSave: (values: IProductPayload) =>
          createProduct({
            category_id: values.category_id,
            offer_id: offers.offer?.offer_id,
            products: values.products,
          }),
      },
    },
    campaign: {
      name: 'Campaign',
      icon: DiffOutlined,
      color: 'brown',
      component: OfferCampaign,
      action: {
        title: 'Add Campaign',
        component: CampaignAddForm,
        onSave: addCampaign,
      },
    },
  };

  useEffect(() => {
    fetchOffer(id);
  }, [id, fetchOffer]);

  useEffect(() => {
    if (offers.offer?.platform === '29next') {
      setTextPlatform('29 Next Category ID');
    }
  }, [offers.offer?.platform]);

  if (offers.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader
        title={`Offer: ${offers.offer?.name} ( ${textPlatform} : ${offers.offer?.offer_id} )`}
        subTitle={`Everflow offer id: ${offers.offer?.ef_offer_id}`}
        extra={[
          categories[tabKey]?.action && (
            <Button
              type="primary"
              onClick={() =>
                openModal({
                  title: categories[tabKey].action.title,
                  component: categories[tabKey].action.component,
                  data: {
                    offer: offers.offer,
                    offer_id: offers.offer?.ID,
                    offerID: offers.offer?.offer_id,
                  },
                  onSave: categories[tabKey].action.onSave,
                })
              }
              key="add"
            >
              <PlusCircleOutlined />
              {categories[tabKey]?.action?.title}
            </Button>
          ),
        ]}
        footer={
          <Tabs
            defaultActiveKey={tabKey}
            tabBarStyle={{ marginBottom: 0 }}
            items={Object.keys(categories).map((key) => {
              const cat = categories[key];
              return {
                key,
                label: cat.name,
                icon: <cat.icon style={{ color: cat.color }} />,
              };
            })}
            onTabClick={(key) => navigate(key)}
          />
        }
      />
      <Content>
        <Routes>
          {Object.keys(categories).map((key) => {
            const cat = categories[key];
            return <Route path={`${key}`} element={<cat.component offer={offers.offer} id={id} />} key={key} />;
          })}
        </Routes>
      </Content>
    </>
  );
};
export default Offer;
