import { InfoCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { useState } from 'react';

import { CustomTooltip } from '../../../../UI/CustomTooltip';
import { htmlPartals } from '../HelperFunctions';
import { AddWarranty } from './AddWarranty';

enum ActionType {
  Add,
  Delete,
  Edit,
}
interface PendingAction {
  type: ActionType;
  item: any;
}
interface ParentProps {
  pageData: any;
  listChange: any;
  iframeDocument: any;
  pendingSaveData: any;
}

export const Warranty = ({ pageData, listChange, pendingSaveData }: ParentProps) => {
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);

  let metaFrame = !!pageData && !!pageData.meta && !!pageData.meta.warrantyFrame && pageData.meta.warrantyFrame;

  const [frames, setFrames] = useState(
    htmlPartals
      .filter((partal) => partal.section === 'warranty')
      .map((partal) =>
        !!metaFrame && metaFrame === partal.frame ? { ...partal, checked: true } : { ...partal, checked: false },
      ),
  );

  const onFrameChange = (img) => {
    let cloneState = [...frames];
    let newState = cloneState.map((clone) =>
      clone.frame === img.frame ? { ...clone, checked: true } : { ...clone, checked: false },
    );
    setFrames(newState);
  };

  const onAddWarrantyHandler = (incomingWarranty: any) => {
    listChange(incomingWarranty);
    setPendingAction(null);
  };
  console.log(frames);

  return (
    <>
      <div className="extra_products__container">
        <p className="info_text">
          Warranty Product:{' '}
          <CustomTooltip
            icon={<InfoCircleFilled />}
            text="Warranty product is a product that present some kind of a protaction."
          />
        </p>
        {!!pageData && !!pageData.meta && (
          <PlusCircleOutlined
            onClick={() => setPendingAction({ item: null, type: ActionType.Add })}
            className={`text-center addicon`}
          />
        )}
        <AddWarranty
          active={pendingAction?.type === ActionType.Add}
          onFrameChange={(img) => onFrameChange(img)}
          frames={frames}
          onCancel={() => setPendingAction(null)}
          onAdd={(war: any) => onAddWarrantyHandler(war)}
          meta={!!pageData && !!pageData.meta && !!pageData.meta.warranty && pageData.meta.warranty}
        />
        <div className="cards">
          {!!pendingSaveData &&
            !!pendingSaveData.meta &&
            !!pendingSaveData.meta.warranty &&
            pendingSaveData.meta.warranty.map((v) => {
              return (
                <Card
                  className="card-products"
                  size="small"
                  onClick={() => {
                    setPendingAction({
                      type: ActionType.Add,
                      item: null,
                    });
                  }}
                >
                  <div>
                    ({v.warranty_id}){v.warranty_name}
                  </div>
                </Card>
              );
            })}
        </div>
      </div>
    </>
  );
};
