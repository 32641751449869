import { Form, Input, Modal } from 'antd';
import { useEffect } from 'react';

export const ShippingEditForm = ({ active, onEdit, item, onCancel }: any) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ ...item });
  }, [item, form]);

  const onFinish = (e: any) => {
    onEdit({ id: item.id, ...e });
    form.resetFields();
  };

  return (
    <Modal
      open={active}
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="country" label="Country">
          <Input />
        </Form.Item>
        <Form.Item name="shipping_id" label="Shipping ID">
          <Input />
        </Form.Item>
        <Form.Item name="price" label="Shipping Price">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
