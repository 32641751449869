import { Button, Form } from 'antd';
import { useEffect } from 'react';

import { ImagePicker } from '../../../common/ImagePicker';

export const ManualEditForm = ({ onEdit, item, funnelId }: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ ...item });
  }, [item, form]);

  const onFinish = ({ manual_img }: any) => {
    onEdit({
      manual_id: item.manual_id,
      manual_img,
    });
    form.resetFields();
  };

  return (
    <Form form={form} className="listForm manulaAddForm" layout="vertical" onFinish={onFinish}>
      <p>Image Url:</p>
      <Form.Item name="manual_img">
        <ImagePicker funnelId={+funnelId} />
      </Form.Item>
      <Button type="primary" htmlType="submit">
        Update
      </Button>
    </Form>
  );
};
