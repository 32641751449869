import { DeleteOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useCallback, useState } from 'react';

import { BoxName } from './BoxName';
import { CartName } from './CartName';
import { DefaultSelectedEdit } from './DefaultSelectedEdit';
import { DiscountEdit } from './DiscountEdit';
import { Features } from './Features';
import { FreeGift } from './FreeGift';
import FreeShippingEdit from './FreeShippingEdit';
import { ProductImageUnit } from './ProductImageUnit';
import ProductInfoEdit from './ProductInfoEdit';
import ProductSize from './ProductSize';
import { SavePrice } from './SavePrice';
import { SellOut } from './SellOut';
import { UnitPack } from './UnitPack';
import { WarrantyEdit } from './WarrantyEdit';

const EditModalIndex = ({ onFinished, active, pendingProduct, localmeta, overridePrice, pageData, onDelete }: any) => {
  const [objToSend, setObjtosend] = useState({});

  const onValuesChange = useCallback((prod: any) => {
    setObjtosend((prev: any) => {
      return {
        ...prev,
        ...prod,
      };
    });
  }, []);

  const imageTypes = [
    {
      key: 'preselected',
      value: <DefaultSelectedEdit onChange={onValuesChange} pendingProduct={pendingProduct} />,
    },
    {
      key: 'product_data_package_discount',
      value: <DiscountEdit onChange={onValuesChange} pendingProduct={pendingProduct} />,
    },
    {
      key: 'product_size_name',
      value: <ProductSize onChange={onValuesChange} pendingProduct={pendingProduct} pageData={pageData} />,
    },
    {
      key: 'product_data_warranty',
      value: <WarrantyEdit onChange={onValuesChange} pendingProduct={pendingProduct} />,
    },
    {
      key: 'product_data_box_name',
      value: <BoxName onChange={onValuesChange} pendingProduct={pendingProduct} />,
    },
    {
      key: 'sellOut',
      value: <SellOut onChange={onValuesChange} pendingProduct={pendingProduct} />,
    },
    {
      key: 'product_unit_img',
      value: <ProductImageUnit onChange={onValuesChange} pendingProduct={pendingProduct} pageData={pageData} />,
    },
    {
      key: 'product_data_package_name',
      value: <UnitPack onChange={onValuesChange} pendingProduct={pendingProduct} />,
    },
    {
      key: 'product_info',
      value: <ProductInfoEdit onChange={onValuesChange} pendingProduct={pendingProduct} />,
    },
    {
      key: 'product_data_cart_name',
      value: <CartName onChange={onValuesChange} pendingProduct={pendingProduct} />,
    },
    {
      key: 'product_data_save',
      value: <SavePrice overridePrice={overridePrice} onChange={onValuesChange} pendingProduct={pendingProduct} />,
    },
    {
      key: 'free_shipping_text',
      value: <FreeShippingEdit onChange={onValuesChange} pendingProduct={pendingProduct} />,
    },
    {
      key: 'free_gift',
      value: <FreeGift onChange={onValuesChange} pendingProduct={pendingProduct} />,
    },
    {
      key: 'features',
      value: <Features onChange={onValuesChange} pendingProduct={pendingProduct} localmeta={localmeta} />,
    },
  ];

  // let objkeys = Object.keys(pendingProduct);

  // let result = imageTypes.filter((i1) => objkeys.some((i2: any) => i1.key === i2));

  const editContent = () => (
    <>
      {imageTypes.length !== 0 ? (
        <div>
          {imageTypes.map((v, i) => {
            return <div key={i}>{v.value}</div>;
          })}
        </div>
      ) : (
        <p className="editSection__para">Nothing to edit</p>
      )}
    </>
  );

  return (
    <Modal
      open={active}
      onOk={() => {
        let data = {
          ...pendingProduct,
          ...objToSend,
        };
        onFinished(data);
      }}
      onCancel={() => {
        onFinished(null);
      }}
      className="productEditModal"
    >
      <p
        className="removeProduct"
        onClick={() =>
          Modal.confirm({
            title: `Delete product`,
            content: 'Are you sure you want to delete this product?',
            onOk: () => onDelete(pendingProduct),
          })
        }
      >
        <DeleteOutlined /> REMOVE
      </p>
      <h2>{pendingProduct.product_data_name}</h2>
      <div className="editSection">{editContent()}</div>
    </Modal>
  );
};

export default EditModalIndex;
