import { CheckOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { useAtomValue } from 'jotai';

import { pageAtom } from '../../../../../../atoms/pages';
import { ProductSelect } from './ProductSelect';
import { SkipPage } from './SkipPage';

export const CustomScripts = () => {
  const page = useAtomValue(pageAtom);
  const items = [
    {
      key: '1',
      label: 'Skip Page',
      children: <SkipPage pageData={page.data} />,
      extra: !!page.data?.meta?.skipPage && (
        <div className="infoIcon">
          <CheckOutlined /> activated
        </div>
      ),
    },
    {
      key: '2',
      label: 'Product Select',
      children: <ProductSelect isActive={!!page.data?.meta?.productSelect} />,
      extra: !!page.data?.meta?.productSelect && (
        <div className="infoIcon">
          <CheckOutlined /> activated
        </div>
      ),
    },
  ];

  return (
    <Collapse
      expandIcon={({ isActive }) => (isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />)}
      items={items}
    ></Collapse>
  );
};
