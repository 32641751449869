import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useEffect, useState } from 'react';

export const Features = ({ pendingProduct, onChange, localmeta }: any) => {
  const [form] = Form.useForm();
  const [featureInput, setfeatureInput] = useState('');
  const [features, setfeatures] = useState(
    !!pendingProduct.features && !!pendingProduct.features.length ? pendingProduct.features : localmeta,
  );

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setfeatureInput(e.target.value);
  };

  const onSubmitButton = () => {
    if (features.length > 7) {
      return;
    }
    setfeatures((prev: any) => {
      let storage = localStorage.getItem('features');
      if (storage) {
        let parsedStorage = JSON.parse(storage);
        let itemTopush = [...parsedStorage, { f: featureInput }];
        localStorage.setItem('features', JSON.stringify(itemTopush));
      } else {
        localStorage.setItem('features', JSON.stringify([{ f: featureInput }]));
      }
      return [...prev, { f: featureInput }];
    });
  };

  const deleteFeature = (index: any) => {
    setfeatures(features.filter((v: any, i: any) => i !== index));
    let newStorage = JSON.parse(localStorage.getItem('features') || '').filter((v, i) => i !== index);
    localStorage.setItem('features', JSON.stringify(newStorage));
  };

  useEffect(() => {
    onChange({ features });
    return () => {};
  }, [features, onChange]);

  return (
    <div>
      <Form form={form} layout="vertical">
        <div className="productRowFree">
          <FormItem style={{ width: '50%', margin: 0 }} name="features" label="Features:">
            <Input onChange={onInputChangeHandler} value={featureInput} />
          </FormItem>
          <Button onClick={onSubmitButton} type="primary">
            Add
          </Button>
        </div>
        <div>
          <p style={{ margin: '10px' }}>Added Features({features.length}):</p>
          {features.length === 0 ? (
            <span style={{ padding: '10px', color: 'gray' }}>No features added</span>
          ) : (
            <div className="addon_features">
              {features.map((f: any, i: any) => {
                return (
                  <div key={i}>
                    {f.f} <CloseOutlined onClick={() => deleteFeature(i)} className="removeAddon" />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};
