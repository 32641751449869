import { Button, Form } from 'antd';

import { ImagePicker } from '../../../common/ImagePicker';

export const ManualAddForm = ({ onAdd, funnelId }: any) => {
  const [form] = Form.useForm();

  const randstr = (prefix: any) => {
    return Math.random()
      .toString(36)
      .replace('0.', prefix || '');
  };

  const onFinish = (values: any) => {
    onAdd({
      manual_id: randstr('For_Now:'),
      manual_img: values.manual_img,
    });
    form.resetFields();
  };

  return (
    <Form form={form} className="listForm manulaAddForm" layout="vertical" onFinish={onFinish}>
      <Form.Item name="manual_img">
        <ImagePicker funnelId={+funnelId} />
      </Form.Item>
      <Button type="primary" htmlType="submit">
        Add
      </Button>
    </Form>
  );
};
