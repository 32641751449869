import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { Button, Popconfirm, Space, Table, Tooltip } from 'antd';
import { useAtom, useSetAtom } from 'jotai';
import { Fragment, useEffect } from 'react';

import { openModalAtom } from '@/atoms/modal';
import { deletePixelAtom, fetchPixelsAtom, updatePixelAtom } from '@/atoms/pixels';
import { IPixel, IRouteParams } from '@/types';
import { columnSearch, commonFields, enableSorting } from '@/utils/table';

import { PixelEditForm } from '../../Forms';
import Loading from '../../Loading';

const Pixels = ({ funnel }: IRouteParams) => {
  const openModal = useSetAtom(openModalAtom);
  const updatePixel = useSetAtom(updatePixelAtom);
  const deletePixel = useSetAtom(deletePixelAtom);
  const [pixels, fetchPixels] = useAtom(fetchPixelsAtom);

  useEffect(() => {
    if (!!funnel) fetchPixels(funnel.ID);
  }, [fetchPixels, funnel]);

  const columns = [
    {
      title: 'ID',
      ...commonFields('ID'),
      ...columnSearch('ID'),
      ...enableSorting('ID'),
      width: '5%',
    },
    {
      title: 'Pixel name',
      ...commonFields('name'),
      ...columnSearch('name'),
      ...enableSorting('name'),
    },
    {
      title: 'AFFID',
      ...commonFields('aff_id'),
      ...columnSearch('aff_id'),
      ...enableSorting('aff_id'),
    },
    {
      title: 'Page',
      ...commonFields('page.name'),
      ...columnSearch('page.name'),
      ...enableSorting('page.name'),
      render: (_: any, pixel: IPixel) => (!!pixel.page ? pixel.page.name : '* global'),
    },
    {
      title: 'Placement',
      ...commonFields('location'),
      ...columnSearch('location'),
      ...enableSorting('location'),
    },
    {
      title: 'Pixel',
      ...commonFields('code'),
      ...columnSearch('code'),
      ...enableSorting('code'),
      width: '45%',
      render: (code: any) => <div>{code}</div>,
    },
    {
      title: 'Action',
      align: 'center' as 'center',
      ...commonFields('action'),
      render: (_: any, pixel: IPixel) => (
        <Space key={pixel.ID}>
          <Tooltip placement="topLeft" title="Pixel Edit">
            <Button
              shape="circle"
              icon={<EditFilled />}
              disabled={funnel.disabled}
              onClick={() => {
                openModal({
                  component: PixelEditForm,
                  data: pixel,
                  funnelId: funnel.ID,
                  onSave: (values: IPixel) => {
                    return updatePixel(pixel.ID, {
                      ...values,
                      code: (values.code || '{}').replaceAll('"', "'").replace(/\r?\n|\r/g, ''),
                    });
                  },
                });
              }}
              key={pixel.ID}
            />
          </Tooltip>
          <Popconfirm
            title="Are you sure you wont to remove?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deletePixel(pixel.ID)}
          >
            <Button disabled={funnel.disabled} shape="circle" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return pixels.isLoading ? (
    <Loading />
  ) : (
    <Fragment>
      <Table
        style={{ width: '100%' }}
        className={'pixel_table'}
        dataSource={pixels.data}
        rowKey="ID"
        columns={columns}
        pagination={false}
      />
    </Fragment>
  );
};
export default Pixels;
