import { Form, InputNumber } from 'antd';
import { useEffect } from 'react';

import { ICampaign, IFormProps } from '@/types';

export const CampaignImportForm = ({ formData, onFormReady }: IFormProps<ICampaign>) => {
  const [form] = Form.useForm();
  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <Form.Item
        label="Insert Campaign Id"
        style={{ width: '100%' }}
        name="campaign_id"
        rules={[
          { required: true, message: 'Missing Campaign Id' },
          { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
        ]}
      >
        <InputNumber style={{ width: '100%' }} maxLength={5} placeholder="Campaign ID" />
      </Form.Item>
    </Form>
  );
};
