import { atom } from 'jotai';

import { IAuditLog } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<IAuditLog[]>([]);
const isLoading = atom<boolean>(false);

export interface AuditLogState {
  data: IAuditLog[];
  isLoading: boolean;
}

export const auditLogAtom = atom<AuditLogState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
}));

export const fetchAuditLogsAtom = atom(
  (get) => get(auditLogAtom),
  async (_, set, id?: number) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/funnels/${id}/audit_log`)
      .json<IAuditLog[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const addNoteAtom = atom(null, async (get, set, body?: IAuditLog) => {
  if (!body) return;
  set(isLoading, true);
  $http
    .post(`/api/v1/audit_logs`, { body })
    .json<IAuditLog>()
    .then((response) => set(dataAtom, [...get(dataAtom), response]))
    .finally(() => set(isLoading, false));
});

export const deleteAuditLogAtom = atom(null, async (get, set, id?: number) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .delete(`/api/v1/audit_logs/${id}`)
    .json<IAuditLog>()
    .then(() =>
      set(
        dataAtom,
        get(dataAtom).filter(({ ID }) => ID !== id),
      ),
    )
    .finally(() => set(isLoading, false));
});
