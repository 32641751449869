import { MoreOutlined } from '@ant-design/icons';
import { Button, Collapse, Dropdown, MenuProps, Modal, Table } from 'antd';
import { useSetAtom } from 'jotai';
import type { AlignType } from 'rc-table/es/interface';
import { useNavigate } from 'react-router-dom';

import { openModalAtom } from '@/atoms/modal';
import { createPageAtom, deletePageAtom, updatePageAtom } from '@/atoms/pages';
import { IFunnel, IPage, ITemplate } from '@/types';
import { columnSearch, commonFields, enableFilters, enableSorting } from '@/utils/table';

import { PageCloneForm, PageEditForm, RedirectEditForm } from '../../../Forms';

const PagesTable = ({ funnel, pages }: { funnel: IFunnel; pages: IPage[] }) => {
  const openModal = useSetAtom(openModalAtom);
  const createPage = useSetAtom(createPageAtom);
  const deletePage = useSetAtom(deletePageAtom);
  const updatePage = useSetAtom(updatePageAtom);
  const navigate = useNavigate();

  const groups = pages.reduce((group, page) => {
    let key = '';
    if (!page.page_path) {
      key = '-';
    } else {
      const parts = page.page_path.split('/');
      key = parts.length >= 4 ? parts.slice(0, -2).join('/') : '/';
    }
    group[key] = group[key] ?? [];
    group[key].push(page);
    return group;
  }, {});

  const menuItems = (page: IPage): MenuProps['items'] => {
    return [
      {
        label: 'Clone',
        key: 'cloneFunnel',
        onClick: () => {
          openModal({
            component: PageCloneForm,
            title: 'Clone Page',
            data: page,
            funnelId: funnel.ID,
            onSave: (values: IPage) => {
              const { ID, ...pageData } = page;
              return createPage({ ...pageData, ...values });
            },
          });
        },
      },
      {
        label: 'Redirect',
        key: 'redirect',
        onClick: () => {
          openModal({
            component: RedirectEditForm,
            title: 'Redirect page',
            data: page,
            funnelId: funnel.ID,
            onSave: (values: { path: string; ef: string }) =>
              updatePage(page.ID, {
                ...page,
                meta: {
                  ...page.meta,
                  redirect: {
                    path: values.path,
                    ef: values.ef,
                  },
                },
              }),
          });
        },
      },
      {
        label: 'Designer',
        key: 'templateEdit',
        onClick: () => {
          navigate(`/template-editor/${page.ID}`);
        },
      },
      {
        label: 'Edit',
        key: 'edit',
        onClick: () => {
          openModal({
            title: 'Edit Page',
            component: PageEditForm,
            data: page,
            funnelId: funnel.ID,
            onSave: (values: IPage) => {
              let nextPagePath = !!pages && pages.find((pg) => pg.ID === values.page_id)?.page_path;
              return updatePage(page.ID, { ...values, funnel_id: funnel.ID, meta: { nextPage: nextPagePath } });
            },
          });
        },
      },
      {
        label: 'Delete',
        key: 'delete',
        onClick: () => {
          Modal.confirm({
            title: `Delete page - ${page.name}(${page.ID})`,
            content: 'Are you sure you want to delete this page?',
            onOk: () => {
              deletePage(page.ID);
            },
          });
        },
      },
    ];
  };

  const columns = [
    {
      title: 'ID',
      ...commonFields('ID'),
      ...enableSorting('ID'),
    },
    {
      title: 'Page Type',
      ...commonFields('page_type'),
      ...enableFilters(pages, 'page_type'),
      ...columnSearch('page_type'),
    },
    { title: 'Name', ...commonFields('name'), ...columnSearch('name') },
    { title: 'Page Path', ...commonFields('page_path'), ...columnSearch('page_path') },
    {
      title: 'Next Page',
      ...commonFields('page_id'),
      render: (_: any, page: IPage) => {
        const nextPage = pages.find((p) => p.ID === page.page_id);
        return nextPage ? nextPage.name : '-';
      },
    },
    {
      title: 'Next Alt Page',
      ...commonFields('page_alt_id'),
      render: (_: any, page: IPage) => {
        const nextPage = pages.find((p) => p.ID === page.page_alt_id);
        return nextPage ? nextPage.name : '-';
      },
    },
    {
      title: 'Template',
      ...commonFields('template'),
      ...columnSearch('template'),
      render: (tpl: ITemplate) => tpl?.name,
    },
    {
      title: 'Redirected',
      render: (_: any, page: IPage) => {
        return page.meta && page.meta.redirect && page.meta.redirect !== '' ? (
          <p style={{ color: 'red', textAlign: 'center', marginBottom: 0 }}>Redirected</p>
        ) : (
          ''
        );
      },
    },
    {
      title: 'Action',
      ...commonFields('action'),
      align: 'center' as AlignType,
      render: (_: any, page: IPage) => {
        return (
          <Dropdown
            overlayClassName="funnel-pages__dropdown"
            menu={{ items: menuItems(page) }}
            disabled={funnel?.locked}
            trigger={['click']}
            key="additional"
          >
            <Button size="middle">
              Action
              <MoreOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={0}
      items={Object.keys(groups).map((label: string, index) => ({
        key: index,
        label: label,
        children: (
          <Table
            bordered
            dataSource={groups[label].sort((a, b) => b.page_path.localeCompare(a.page_path))}
            rowKey="ID"
            columns={columns}
            pagination={false}
          />
        ),
      }))}
    ></Collapse>
  );
};

export default PagesTable;
