import { Form, Input, Modal } from 'antd';

export const ShippingAddForm = ({ active, onAdd, onCancel }: any) => {
  const [form] = Form.useForm();

  const randstr = (prefix: any) => {
    return Math.random()
      .toString(36)
      .replace('0.', prefix || '');
  };

  const onFinish = ({ country, shipping_id, price }: any) => {
    onAdd({
      id: randstr('For_Now:'),
      shipping_id,
      country,
      price,
    });

    form.resetFields();
  };

  return (
    <Modal
      open={active}
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="country" label="Country">
          <Input placeholder="US,CA,etc." />
        </Form.Item>
        <Form.Item name="shipping_id" label="Shipping ID">
          <Input placeholder="237,242,etc." />
        </Form.Item>
        <Form.Item name="price" label="Shipping Price">
          <Input placeholder="9.95" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
