import { Form, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useState } from 'react';

const sellOutArray = ['Best Seller', 'Moderate', 'Popular', 'Most Popular', 'Great', 'Good'];

export const SellOut = ({ pendingProduct, onChange }: any) => {
  const [form] = Form.useForm();
  const [sellOut, setsellOut] = useState(pendingProduct.sellOut);

  const onSelloutChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setsellOut(e);
    onChange({ sellOut: e });
  };

  return (
    <div>
      <Form form={form} layout="vertical">
        <div className="productRow">
          <FormItem style={{ width: '100%' }} initialValue={sellOut} name="sellOut" label="Popularity">
            <Select onChange={onSelloutChangeHandler}>
              {sellOutArray.map((sell) => (
                <Select.Option key={sell} value={sell}>
                  {sell}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </div>
      </Form>
    </div>
  );
};
