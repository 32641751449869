import {
  AlertOutlined,
  CloudUploadOutlined,
  CopyOutlined,
  DeleteOutlined,
  DiffOutlined,
  DollarOutlined,
  ExclamationCircleOutlined,
  LayoutOutlined,
  LockOutlined,
  MoreOutlined,
  PartitionOutlined,
  PieChartOutlined,
  PlusCircleOutlined,
  SafetyCertificateOutlined,
  SaveOutlined,
  SnippetsOutlined,
  SolutionOutlined,
  SwapOutlined,
  UnlockOutlined,
  UnorderedListOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Modal, Radio, Tabs, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { Route, useParams } from 'react-router';
import { Routes, useMatch, useNavigate } from 'react-router-dom';

import { addNoteAtom } from '@/atoms/auditLog';
import { createBlockerAtom } from '@/atoms/blockers';
import { importCampaignAtom } from '@/atoms/campaigns';
import { createDomainAtom } from '@/atoms/domains';
import { buildFunnelAtom, cloneFunnelAtom, fetchFunnelAtom, funnelsAtom } from '@/atoms/funnels';
import { openModalAtom } from '@/atoms/modal';
import { createPageAtom, proofAtom } from '@/atoms/pages';
import { createPixelAtom } from '@/atoms/pixels';
import { createScrubAtom } from '@/atoms/scrub';
import { IBlockers, IDomain, IPage, IPixel, IRouteParams, IScrub } from '@/types';

import {
  BlockUserEditForm,
  CampaignImportForm,
  DomainEditForm,
  FunnelAddNoteForm,
  FunnelCloneForm,
  FunnelDetailsEditForm,
  PageEditForm,
  PixelEditForm,
  ScrubEditForm,
} from '../../Forms';
import PageHeader from '../../PageHeader';
import Blockers from './Blockers';
import Campaigns from './Campaigns';
import { Proof } from './Components/Proof';
import Details from './Details';
import Domains from './Domains';
import FunnelGallery from './Gallery';
import FunnelLogs from './Logs';
import Pages from './Pages';
import Pixels from './Pixels';
import Products from './Products';
import Scrubs from './Scrubs';

const Funnel = () => {
  const { id }: IRouteParams = useParams();
  const navigate = useNavigate();
  const openModal = useSetAtom(openModalAtom);
  const buildFunnel = useSetAtom(buildFunnelAtom);
  const fetchFunnel = useSetAtom(fetchFunnelAtom);
  const createBlocker = useSetAtom(createBlockerAtom);
  const createDomain = useSetAtom(createDomainAtom);
  const importCampaign = useSetAtom(importCampaignAtom);
  const cloneFunnel = useSetAtom(cloneFunnelAtom);
  const addNote = useSetAtom(addNoteAtom);
  const createPage = useSetAtom(createPageAtom);
  const createPixel = useSetAtom(createPixelAtom);
  const createScrub = useSetAtom(createScrubAtom);
  const [proof, setProof] = useAtom(proofAtom);
  const funnels = useAtomValue(funnelsAtom);
  const match = useMatch('funnels/:id/:tabKey');
  const [view, setView] = useState('table');
  const { confirm } = Modal;
  const tabKey = match?.params.tabKey || 'domains';

  useEffect(() => {
    if (!!id) fetchFunnel(id);
  }, [fetchFunnel, id]);

  const categories: any = {
    domains: {
      name: 'Domains',
      icon: SnippetsOutlined,
      color: 'blue',
      component: Domains,
      action: {
        title: 'Add subdomain',
        component: DomainEditForm,
        onSave: (values: IDomain) => createDomain(values),
        class: '',
      },
    },
    campaigns: {
      name: 'Campaigns',
      icon: DiffOutlined,
      color: 'brown',
      component: Campaigns,
      action: {
        title: 'Add Campaign',
        component: CampaignImportForm,
        onSave: (values: IDomain) =>
          importCampaign({ ...values, offer_id: funnels.funnel?.offer_id, funnel_id: funnels.funnel?.ID }),
        class: '',
      },
    },
    products: {
      name: 'Products',
      icon: PieChartOutlined,
      color: 'red',
      component: Products,
    },
    scrubs: {
      name: 'Scrubs',
      icon: DollarOutlined,
      color: 'green',
      component: Scrubs,
      action: {
        title: 'Add scrub',
        component: ScrubEditForm,
        onSave: (values: IScrub) => createScrub({ ...values, funnel_id: funnels.funnel?.ID }),
      },
    },
    pages: {
      name: 'Pages',
      icon: LayoutOutlined,
      color: 'brown',
      component: Pages,
      action: {
        title: 'Add page',
        component: PageEditForm,
        onSave: (values: IPage) => createPage({ ...values, funnel_id: funnels.funnel?.ID }),
      },
      extra: (
        <Radio.Group defaultValue="table" onChange={(e) => setView(e.target.value)} value={view}>
          <Radio.Button value="table">
            <UnorderedListOutlined />
          </Radio.Button>
          <Radio.Button value="flow">
            <PartitionOutlined />
          </Radio.Button>
        </Radio.Group>
      ),
    },
    pixels: {
      name: 'Pixels',
      icon: SwapOutlined,
      color: 'orange',
      component: Pixels,
      action: {
        title: 'Add Pixel',
        component: PixelEditForm,
        onSave: (values: IPixel) =>
          createPixel({
            ...values,
            code: (values.code || '{}').replaceAll('"', "'").replace(/\r?\n|\r/g, ''),
            funnel_id: funnels.funnel?.ID,
          }),
      },
    },
    gallery: {
      name: 'Gallery',
      icon: LayoutOutlined,
      color: 'brown',
      component: FunnelGallery,
    },
    blockers: {
      name: `Block User`,
      icon: UserDeleteOutlined,
      color: `red`,
      component: Blockers,
      action: {
        title: 'Block User',
        component: BlockUserEditForm,
        onSave: (values: IBlockers) => createBlocker({ funnel_id: funnels.funnel?.ID, ...values }),
      },
    },
    logs: {
      name: 'Logs',
      icon: SolutionOutlined,
      color: 'blue',
      component: FunnelLogs,
      action: {
        title: 'Add Note',
        component: FunnelAddNoteForm,
        onSave: (values: IDomain) => addNote({ ...values, type: 'note', funnel_id: funnels.funnel?.ID }),
      },
    },
    details: {
      name: 'Details',
      icon: AlertOutlined,
      color: '#7ec1ff',
      component: Details,
      action: {
        title: 'Edit Details',
        component: FunnelDetailsEditForm,
        formData: funnels.funnel,
        class: 'detail_form',
      },
    },
  };

  const menuItems: MenuProps['items'] = [
    {
      label: 'Clone',
      key: 'clone',
      icon: <CopyOutlined />,
      onClick: () => {
        openModal({
          title: 'Clone Funnel',
          component: FunnelCloneForm,
          data: !!funnels.funnel && funnels.funnel,
          funnelId: funnels.funnel?.ID,
          onSave: (values) => {
            if (!funnels.funnel?.ID) return Promise.reject('Source funnel not found');
            return cloneFunnel(funnels.funnel?.ID, values).then(({ ID }) => navigate(`/funnels/${ID}/domains`));
          },
        });
      },
    },
    {
      label: 'Archive',
      key: 'archive',
      icon: <DeleteOutlined />,
      disabled: funnels.funnel?.locked,
      onClick: () => {
        confirm({
          icon: <ExclamationCircleOutlined />,
          content: `Are you sure you want to archive funnel?`,
          onOk() {
            //dispatch(deleteFunnel(funnels.funnel));
          },
        });
      },
    },
    {
      label: funnels.funnel?.locked ? 'Unlock' : 'Lock',
      key: 'lock',
      icon: funnels.funnel?.locked ? <UnlockOutlined /> : <LockOutlined />,
      onClick: () => {
        confirm({
          icon: <ExclamationCircleOutlined />,
          content: `Are you sure you want to ${funnels.funnel?.locked ? 'unlock' : 'lock'} funnel?`,
          onOk() {
            //dispatch(lockFunnel(funnels.funnel));
          },
        });
      },
    },
  ];

  return (
    <>
      <PageHeader
        title="Funnel"
        subTitle={funnels.funnel?.name}
        locked={funnels.funnel?.locked}
        extra={[
          <Tooltip key="proof" title="last save" placement="top">
            <SaveOutlined
              style={{ display: proof ? 'block' : 'none' }}
              className={`saveIcon ${!!proof ? 'animSave' : ''} `}
              onClick={() => {
                confirm({
                  title: 'Proof of save',
                  icon: <SafetyCertificateOutlined />,
                  content: <Proof />,
                  cancelText: 'Back to page',
                  onCancel() {
                    navigate(`/template-editor/${proof?.ID}`);
                  },
                  onOk() {
                    setProof(undefined);
                  },
                });
              }}
            />
          </Tooltip>,
          categories[tabKey].action && (
            <Button
              type="primary"
              disabled={funnels.funnel?.locked}
              onClick={() =>
                openModal({
                  title: categories[tabKey].action.title,
                  component: categories[tabKey].action.component,
                  onSave: categories[tabKey].action.onSave,
                  data: categories[tabKey].formData ?? { offer_id: funnels.funnel?.offer_id },
                  funnelId: funnels.funnel?.ID,
                  class: categories[tabKey].action.class,
                })
              }
              key="add"
            >
              <PlusCircleOutlined />
              {categories[tabKey].action && categories[tabKey].action.title}
            </Button>
          ),
          
            <Button type="primary" danger disabled={funnels.funnel?.locked || funnels.isBuilding} key="publish" onClick={() => buildFunnel(id)}>
              <CloudUploadOutlined /> Publish
            </Button>,
          
          <Dropdown menu={{ items: menuItems }} trigger={['click']} key="additional">
            <Button size="middle" style={{ paddingLeft: 5, paddingRight: 5 }}>
              <MoreOutlined />
            </Button>
          </Dropdown>,
        ]}
        footer={
          <Tabs
            defaultActiveKey={tabKey}
            tabBarStyle={{ marginBottom: 0 }}
            items={Object.keys(categories).map((key) => {
              const cat = categories[key];
              return {
                key,
                label: cat.name,
                icon: <cat.icon style={{ color: cat.color }} />,
              };
            })}
            tabBarExtraContent={categories[tabKey].extra}
            onTabClick={(key) => navigate(key)}
          />
        }
      />
      <Content style={{ display: 'flex', flexDirection: 'column' }}>
        <Routes>
          {Object.keys(categories).map((key) => {
            const cat = categories[key];
            return (
              <Route
                path={`${key}`}
                key={key}
                element={<cat.component id={id} funnel={funnels.funnel} view={view} />}
              />
            );
          })}
        </Routes>
      </Content>
    </>
  );
};

export default Funnel;
