import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, message, Steps, theme } from 'antd';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { updateFunnelAtom } from '@/atoms/funnels';
import { closeModalAtom } from '@/atoms/modal';
import { error, funnel } from '@/atoms/quickBuild';

import { Build } from './Build';
import { Campaigns } from './Campaigns';
import { Design } from './Design';
import { Details } from './Details';
import { Domains } from './Domains/Index';
import { Faq, Manual } from './FaqManual';
import { Funnel } from './Funnel';
import { Offers } from './Offers';
import { Pages } from './Pages/Index';

export const FunnelQuickBuildForm = () => {
  const [errorAtom, setErrorAtom] = useAtom(error);
  const closeModal = useSetAtom(closeModalAtom);
  const updateFunnel = useSetAtom(updateFunnelAtom);
  const funnelAtom = useAtomValue(funnel);
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const steps = [
    {
      title: 'Offer',
      component: Offers,
      done: false,
    },
    {
      title: 'Funnel',
      component: Funnel,
      done: false,
    },
    {
      title: 'Domain',
      component: Domains,
      done: false,
    },
    {
      title: 'Campaigns',
      component: Campaigns,
      done: false,
    },
    {
      title: 'Pages',
      component: Pages,
      done: false,
    },
    {
      title: 'General',
      component: Details,
      done: false,
    },
    {
      title: 'Build',
      component: Build,
      done: false,
    },
    {
      title: 'Faq',
      component: Faq,
      done: false,
    },
    {
      title: 'Manual',
      component: Manual,
      done: false,
    },
    {
      title: 'Design',
      component: Design,
      done: false,
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onDone = () => {
    if (!funnelAtom?.ID) return;
    updateFunnel(funnelAtom?.ID, funnelAtom);
    navigate(`/funnels/${funnelAtom?.ID}/domains`);
    setCurrent(0);
    closeModal();
    message.success('Processing complete!');
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    margin: '50px auto',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '80%',
  };

  return (
    <div className="stepQuick">
      <Steps direction="horizontal" current={current} items={items} />
      <div style={contentStyle}>
        {steps.map((step, i) => {
          if (i === current) {
            return <step.component key={step.title} updateStep={next} onDone={onDone} />;
          }
          return null;
        })}
      </div>
      {errorAtom !== '' && (
        <div className="quickBuildError">
          <CloseOutlined className="closeError" onClick={() => setErrorAtom('')} />
          <p className="quickBuildErrorHeader">
            <WarningOutlined /> ERROR
          </p>
          <p className="quickBuildErrorInfo">{errorAtom}</p>
        </div>
      )}
      <div style={{ marginTop: 24 }}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={onDone}>
            Done
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
    </div>
  );
};
