import { Button, Col, Form, Input, Row, Select, Checkbox } from 'antd';
import { useAtom, useAtomValue } from 'jotai';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useEffect, useState } from 'react';

import { updateFunnelAtom } from '../../../atoms/funnels';
import { closeModalAtom } from '../../../atoms/modal';
import { offersAtom } from '../../../atoms/offers';
import { pagesAtom } from '../../../atoms/pages';
import { IFormProps } from '../../../types';
import { IFunnel, IOffer } from '../../../types';
import { ImagePicker } from '../../common/ImagePicker';

export interface IEditProp {
  savedFields: any;
  active: any;
  onCancel: any;
  onValuesChangeHandler: Function;
  funnelId?: any;
}

export const General = ({ formData }: IFormProps<IFunnel>) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [, updateFunnel] = useAtom(updateFunnelAtom);
  const [form] = Form.useForm();
  const offers = useAtomValue(offersAtom);
  const pages = useAtomValue(pagesAtom);
  const [checked, setChecked] = useState(true);

  const currencies = [
    { key: 'USD', name: 'USD' },
    { key: 'EUR', name: 'EUR' },
    { key: 'GBP', name: 'GBP' },
    { key: 'AUD', name: 'AUD' },
  ];

  const languages = [
    { key: 'en', name: 'English' },
    { key: 'de', name: 'German' },
    { key: 'fr', name: 'French' },
  ];

  const countries = [
    { key: 'US', name: 'USA' },
    { key: 'CA', name: 'Canada' },
    { key: 'GB', name: 'Great Britain' },
    { key: 'IE', name: 'Ireland' },
    { key: 'DE', name: 'Germany' },
    { key: 'FR', name: 'France' },
    { key: 'AU', name: 'Australia' },
  ];

  useEffect(() => {
    if (!formData) {
      form.resetFields();
    } else {
      form.setFieldsValue({ ...formData, offer: { offer_id: formData.offer?.ID } });
    }
  }, [formData, form]);

  const onFinish = (values) => {
    const data = {
      ...values,
      offer: offers.data.find((o: IOffer) => o.ID === values.offer.offer_id || o.offer_id === values.offer.offer_id),
      details: { ...values.details, isEverflow: checked },
      ID: formData?.ID,
    };
    formData?.ID && updateFunnel(formData.ID, data);
    closeModal();
  };

  const onCheck = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  return (
    <Form form={form} layout="vertical" className="row detail" onFinish={onFinish}>
      <Row gutter={12}>
        <Col span={24}>
          <Checkbox className="global_checkbox" checked={checked} onChange={onCheck}>
            Everflow
          </Checkbox>
        </Col>
        <Col span={6}>
          <Form.Item
            name="cs_number"
            label="Customer Support Number"
            rules={[{ required: true, message: 'Please input customer support number' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="cs_email"
            label="Customer Support E-mail"
            rules={[
              { required: true, message: 'Please input customer support Email' },
              {
                // eslint-disable-next-line no-useless-escape
                pattern: new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
                message: 'Email format is not valid',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="name" label="Offer Name" rules={[{ required: true, message: 'Please input Offer Name' }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            initialValue="USD"
            name={['currency']}
            label="Currency"
            rules={[{ required: true, message: 'Please select currency' }]}
          >
            <Select placeholder="Select Currency">
              {currencies.map(({ key, name }) => (
                <Select.Option key={key} value={key}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            initialValue="US"
            name={['language']}
            label="Language"
            rules={[{ required: true, message: 'Please select language' }]}
          >
            <Select placeholder="Select Language">
              {languages.map(({ key, name }) => (
                <Select.Option key={key} value={key}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            initialValue={['US', 'CA']}
            name={['countries']}
            label="Countries"
            rules={[{ required: true, message: 'Please select at least on country' }]}
          >
            <Select placeholder="Select country" mode="multiple">
              {countries.map(({ key, name }) => (
                <Select.Option key={key} value={key}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {formData?.ID && (
          <>
            <Col span={12}>
              <Form.Item name={['details', 'favicon']} label="Favicon">
                <ImagePicker funnelId={formData.ID} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['details', 'logo']} label="Logo">
                <ImagePicker funnelId={formData.ID} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['details', 'logo_white']} label="Logo White">
                <ImagePicker funnelId={formData.ID} />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={6}>
          <Form.Item
            name={['details', 'GTM']}
            label="GTM"
            rules={[{ pattern: new RegExp(/^[a-zA-Z0-9]*$/), message: 'Special characters not allowed -/@`!?' }]}
          >
            <Input addonBefore="GTM-" maxLength={8} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={['details', 'OPT']}
            label="OPT"
            rules={[{ pattern: new RegExp(/^[a-zA-Z0-9]*$/), message: 'Special characters not allowed -/@`!?' }]}
          >
            <Input addonBefore="OPT-" maxLength={7} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={['details', 'UA']}
            label="UA"
            // eslint-disable-next-line no-useless-escape
            rules={[{ pattern: new RegExp(/^[0-9\.\-\/]*$/), message: 'Only numbers and "-" is allowed' }]}
          >
            <Input addonBefore="UA-" maxLength={14} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={['details', 'dl_offer_name']}
            label="DataLayer Offer Name"
            rules={[{ required: true, message: 'Please input DataLayer Offer Name' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={['details', 'guarantee']} label="Guarantee">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={['details', 'cf_site_key']} label="Cloudflare Site Key">
            <Input maxLength={35} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={['details', 'cf_secret_key']} label="Cloudflare Secret Key">
            <Input maxLength={35} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={['offer', 'offer_id']}
            label="Offer ID"
            rules={[
              { required: true, message: 'Please input Offer ID(for products)' },
              { pattern: new RegExp(/^[0-9]*$/), message: 'Input only numbers' },
            ]}
          >
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
              options={offers.data.map((v) => {
                return { value: v.ID, label: `${v.name}(${v.name})` };
              })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            style={{ width: '100%' }}
            name="page_id"
            label="Index page"
            rules={[{ required: true, message: 'Please input root page' }]}
          >
            <Select placeholder="Select index page">
              {pages.data
                .filter(({ ID }) => formData?.ID !== ID)
                .map(({ ID, name }) => (
                  <Select.Option key={ID} value={ID}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};
