import { Form, Input } from 'antd';
import { useEffect } from 'react';

import { IFormProps, IFunnel } from '@/types';

export const FunnelCloneForm = ({ formData, onFormReady }: IFormProps<IFunnel>) => {
  const [form] = Form.useForm();

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  useEffect(() => {
    if (!!formData) {
      form.setFieldsValue({ name: formData.name + ' - Clone' });
    }
    return () => {};
  }, [formData, form]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label="Insert new Funnel Name"
        rules={[{ required: true, message: 'Please input Funnel Name' }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};
