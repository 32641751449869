import { FormInstance } from 'antd';

import { IOffer } from './funnelBaseTypes';

export enum ActionType {
  Add,
  Delete,
  Edit,
}

export interface PendingAction {
  type: ActionType;
  item: any;
}

export interface IPagination<T> {
  limit?: number;
  page?: number;
  sort: string;
  total_rows: number;
  total_pages: number;
  rows: T[];
}

export enum InternalActionKind {
  BLACK = 'BLACK',
  BLUE = 'BLUE',
  ORANGE = 'ORANGE',
  GREEN = 'GREEN',
  SHIPPING = 'SHIPPING',
  FULLPRICE = 'FULLPRICE',
  STOCK = 'STOCK',
}

export enum InternalActionKindFrame {
  FRAME1 = 'addonFrame1',
  FRAME2 = 'addonFrame2',
}
export interface InternalActionType {
  type: InternalActionKind;
  payload?: any;
}
export interface InternalState {
  checkBlue?: boolean;
  checkGreen?: boolean;
  checkBlack?: boolean;
  checkOrange?: boolean;
  withShipping?: boolean;
  withFullPrice?: boolean;
  outOfStock?: boolean;
  color?: string;
  main_color?: string;
}

export interface IRouteParams {
  id?: any;
  funnel?: any;
  offer?: IOffer;
}

export interface IFormProps<T> {
  onFormReady: (form: FormInstance) => void;
  funnelId?: number;
  formData?: T;
  id?: number;
  isQuick?: boolean;
}

export interface IFrame {
  img: string;
  type: string;
  html: string;
  section: string;
  checked?: boolean;
  frame?: string;
  kind?: string;
  styles?: Object;
}
