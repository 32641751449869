import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useEffect, useState } from 'react';

export const FreeGift = ({ pendingProduct, onChange }: any) => {
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(pendingProduct.free_gift === '' ? false : true);
  const [freeGift, setfreeGift] = useState(pendingProduct.free_gift);

  const onDiscountChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setfreeGift(e.target.value);
  };

  const onCheck = (e: any) => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (!checked) {
      setfreeGift('');
      form.resetFields();
    }
    onChange({ free_gift: freeGift });
  }, [checked, form, freeGift, onChange]);

  return (
    <div className="productFree">
      <Form form={form} layout="vertical">
        <div style={{ padding: '0px 10px' }}>
          {checked ? (
            <div className="freeshipping_disable" onClick={onCheck}>
              <MinusOutlined /> Disable free gift
            </div>
          ) : (
            <div className="freeshipping_disable" onClick={onCheck}>
              <PlusOutlined /> Enable free gift
            </div>
          )}
          <Checkbox style={{ visibility: 'hidden' }} checked={checked} onChange={onChange}>
            Free Gift
          </Checkbox>
          {checked && (
            <div className="productRowFree">
              <FormItem style={{ width: '50%' }} initialValue={freeGift} name="free_gift" label="Free Gift">
                <Input disabled={!checked} onChange={onDiscountChangeHandler} value={freeGift} />
              </FormItem>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};
