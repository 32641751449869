import { CheckOutlined, EditFilled } from '@ant-design/icons';
import { Alert, Button, Card, Checkbox, Form, Image, Input, Modal, Select } from 'antd';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import { productsAtom } from '../../../../../atoms/products';
import { ActionType, PendingAction } from '../../../../../types';

interface ParentProps {
  frames?: any;
  onFrameChange?: any;
  active?: any;
  onCancel?: any;
  meta?: any;
  onAdd?: any;
}

export const AddWarranty = ({ active, onCancel, frames, onFrameChange, meta, onAdd }: ParentProps) => {
  const [inputValues, setInputValues] = useState([]);
  const [form] = Form.useForm();
  const products = useAtomValue(productsAtom);
  const [addedWarranty, setAddedWarranty] = useState(!!meta && meta) as any;
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);
  const [frameValid, setFrameValid] = useState(true);

  useEffect(() => {
    form.setFieldsValue({ ...pendingAction?.item });
  }, [form, pendingAction?.item]);

  const onSearch = () => {
    let prods = products.data.filter((p: any) => inputValues.some((i: any) => +i === p.ID));

    let productToAdd = prods.map((prod) => {
      return {
        warranty_id: prod.product_id,
        warranty_name: prod.name,
      };
    });

    setAddedWarranty(productToAdd);
  };

  const updateAddedWarranty = (item) => {
    if (item) {
      let newAdded = [...addedWarranty];
      let findIndex = newAdded.findIndex((add) => add.warranty_id === item.warranty_id);
      newAdded[findIndex] = item;
      setAddedWarranty(newAdded);
    }
    setPendingAction(null);
  };

  const onFinish = (e) => {
    let warrantyFrames = frames.find((frame) => frame.checked);
    if (!warrantyFrames) {
      setFrameValid(false);
      return;
    }
    let dataTosend = {
      warranty: addedWarranty,
      warrantyFrame: warrantyFrames.frame,
    };

    setFrameValid(true);
    onAdd(dataTosend);
    setPendingAction(null);
    form.resetFields();
  };

  return (
    <Modal
      open={active}
      title="Warranty"
      onOk={() => {
        setPendingAction(null);
        form.submit();
      }}
      onCancel={onCancel}
    >
      <p className="info_text">Choose frame:</p>
      <div className="addonFrames">
        {!!frames &&
          frames.map((img, i) => {
            return (
              <div key={i} style={{ display: 'flex', flexDirection: 'column' }}>
                <Checkbox checked={img.checked} onChange={() => onFrameChange(img)}>
                  Click
                </Checkbox>
                <Image width={150} src={img.img} />
              </div>
            );
          })}
      </div>
      {!frameValid && (
        <Alert
          showIcon={true}
          style={{ textAlign: 'center', marginTop: 10 }}
          message="Please select warranty frame"
          type="error"
        />
      )}
      <p className="info_text">Products:</p>
      <Form form={form} onFinish={onFinish}>
        <Select
          showSearch
          style={{ width: '100%' }}
          placeholder="Select product"
          onChange={setInputValues}
          value={inputValues}
          mode="multiple"
        >
          {Array.from(new Set(products.data.map((a: any) => a.product_id)))
            .map((id: any) => {
              return products.data.find((a: any) => a.product_id === id);
            })
            .map((prod) => {
              if (!prod) return null;
              return (
                <Select.Option key={prod.ID}>
                  ({prod.product_id}){prod.name}
                </Select.Option>
              );
            })}
        </Select>
        {inputValues.length > 0 && (
          <Button className="addButton" onClick={onSearch}>
            Add All
          </Button>
        )}
        {!!addedWarranty && !!addedWarranty.length && !pendingAction?.item ? (
          <>
            <p className="alreadyAdd">Already Added:</p>
            {addedWarranty.map((add, i) => {
              return (
                <Card key={i} className="item-container card-warranty">
                  ({add.warranty_id}) {add.warranty_name}
                  <EditFilled
                    onClick={() => {
                      setPendingAction({ item: add, type: ActionType.Edit });
                      form.setFieldsValue({ warranty_name: add.warranty_name });
                    }}
                    className="editWarranty"
                  />
                </Card>
              );
            })}
          </>
        ) : (
          <p>No added Products</p>
        )}
      </Form>
      {pendingAction?.item && (
        <Form form={form} className="flexBetween">
          <Form.Item name="warranty_name" className="warranty_name">
            <Input />
          </Form.Item>
          <Button
            style={{ border: 'none' }}
            onClick={() =>
              updateAddedWarranty({
                warranty_id: pendingAction.item.warranty_id,
                warranty_name: form.getFieldValue('warranty_name'),
              })
            }
            htmlType="submit"
          >
            <CheckOutlined className="editWarranty" />
          </Button>
        </Form>
      )}
    </Modal>
  );
};
