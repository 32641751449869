import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Statistic } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';

import { fetchDashBoardAtom } from '../../atoms/dashboard';
import PageHeader from '../PageHeader';

const Dashboard = () => {
  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();
  const [dashboard, fetchDashBoard] = useAtom(fetchDashBoardAtom);
  const [dataColumns, setDataColumns] = useState(dashboard.data);
  useEffect(() => {
    fetchDashBoard();
  }, [fetchDashBoard]);

  useEffect(() => {
    setDataColumns(dashboard.data);
  }, [dashboard]);

  const dragStart = (_: any, position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (_: any, position: any) => {
    dragOverItem.current = position;
  };

  const dropEnd = (_: any) => {
    const copyListItems = [...dataColumns];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setDataColumns(copyListItems);
  };
  return (
    <>
      <PageHeader title="Dashboard" />
      <Content style={{ padding: '1em', overflow: 'auto' }}>
        <Row gutter={[16, 16]}>
          {dataColumns.map((item: any, index: number) => {
            const fontColors = index % 2 === 1 ? { color: 'green' } : { color: 'red' };
            const icon = index % 2 === 1 ? <ArrowDownOutlined /> : <ArrowUpOutlined />;
            return (
              <Col span={12} key={index}>
                <Card
                  onDragStart={(e) => dragStart(e, index)}
                  onDragEnter={(e) => dragEnter(e, index)}
                  onDragEnd={dropEnd}
                  key={index}
                  draggable
                >
                  <Statistic
                    title={`Active ${item.name}`}
                    value={item.value}
                    valueStyle={fontColors}
                    prefix={icon}
                    suffix="%"
                  />
                  <Button style={{ marginTop: 16 }} type="primary">
                    New {item.name}
                  </Button>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Content>
    </>
  );
};

export default Dashboard;
