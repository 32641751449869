import { atom } from 'jotai';

import { ICategory } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<ICategory[]>([]);
const isLoading = atom<boolean>(false);

export interface CategoriesState {
  data: ICategory[];
  isLoading: boolean;
}

export const categoriesAtom = atom<CategoriesState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
}));

export const fetchCategoriesAtom = atom(
  (get) => get(categoriesAtom),
  async (_, set, id?: number) => {
    set(isLoading, true);
    $http
      .get('/api/v1/categories')
      .json<ICategory[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const fetchCategories29NextAtom = atom(
  (get) => get(categoriesAtom),
  async (_, set, id?: number) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/categories/29next/${id}`)
      .json<ICategory[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const createCategoryAtom = atom(null, async (get, set, body?: ICategory) => {
  if (!body) return;
  set(isLoading, true);
  $http
    .post(`/api/v1/categories/create`, { body })
    .json<ICategory>()
    .then((response) => set(dataAtom, [...get(dataAtom), response]))
    .finally(() => set(isLoading, false));
});

export const updateCategoryAtom = atom(null, async (get, set, funnelId: number, category: ICategory[]) => {
  if (!funnelId) return;
  const categories = get(categoriesAtom);
  set(isLoading, true);
  $http
    .put(`/api/v1/categories/update/${funnelId}`, { body: category })
    .json<ICategory>()
    .then((category) => set(dataAtom, categories.data.updateValueBy('ID', category)))
    .finally(() => set(isLoading, false));
});

export const deleteCategoryAtom = atom(null, async (get, set, id?: number) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .delete(`/api/v1/categories/${id}`)
    .json<ICategory>()
    .then(() =>
      set(
        dataAtom,
        get(dataAtom).filter(({ ID }) => ID !== id),
      ),
    )
    .finally(() => set(isLoading, false));
});

export const importCategoryStickyAtom = atom(null, async (get, set, body?: ICategory[]) => {
  if (!body) return;
  set(isLoading, true);
  $http
    .post(`/api/v1/categories/import`, { body })
    .json<ICategory[]>()
    .then((response) => set(dataAtom, [...get(dataAtom), response[0]]))
    .finally(() => set(isLoading, false));
});
