import { Form, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useState } from 'react';

export const BoxName = ({ pendingProduct, onChange }: any) => {
  const [form] = Form.useForm();
  const [productNameBox, setproductNameBox] = useState(pendingProduct.product_data_box_name);

  const onBoxNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setproductNameBox(e.target.value);
    onChange({ product_data_box_name: e.target.value });
  };

  return (
    <div>
      <Form form={form} layout="vertical">
        <div className="productRow">
          <FormItem
            style={{ width: '100%' }}
            initialValue={productNameBox}
            name="product_data_box_name"
            label="Product Name in Product Select"
          >
            <Input onChange={onBoxNameChangeHandler} value={productNameBox} />
          </FormItem>
        </div>
      </Form>
    </div>
  );
};
