import 'react-quill/dist/quill.bubble.css';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { getDefaultStore, Provider, useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { createRoot } from 'react-dom/client';

import { pageAtom, updateMetaAtom } from '../../../../atoms/pages';
import { ActionType, PendingAction } from '../../../../types';
import { IProduct } from '../../../../types';
import { ImageGallery } from '../../../common/ImageGallery';
import { DragAndDrop } from '../../../UI/DragAndDrop';
import { ListAddForm } from '../CheckoutListMenu/EditLists/ListAddForm';
import { ListEditForm } from '../CheckoutListMenu/EditLists/ListEditForm';
import { htmlPartals } from '../CheckoutListMenu/HelperFunctions';
import { AddAddons } from '../CheckoutListMenu/ProductAddons/AddAddons';
import { EditModalAddons } from '../CheckoutListMenu/ProductAddons/EditModalAddons';
import { AddModal } from '../CheckoutListMenu/Products/AddModal';
import EditModalIndex from '../CheckoutListMenu/Products/EditProducts';
import { AddSize } from '../CheckoutListMenu/Size/AddSize';
import { AddWarranty } from '../CheckoutListMenu/Warranty/AddWarranty';
import { AddonElement, JourneyElement, ListElement, ProductElement, WarrantyElement } from '../hmlTemplates';

interface EditableTextProps {
  doc: Document;
  text: string;
  dataKey: string;
  containerProps: any;
  tagName?: string;
  elementAttr: any;
  onChange: Function;
}

interface EditableImageProps {
  funnelId: number;
  dataKey: string;
  elementAttr: any;
  onChange: Function;
  className: string;
}

const defaultStore = getDefaultStore();

const EditableText = ({ doc, text, dataKey, containerProps, elementAttr, tagName, onChange }: EditableTextProps) => {
  const page = useAtomValue(pageAtom);
  const html = (!!page.data?.meta ? page.data.meta[dataKey] : page.data?.template?.meta[dataKey]) ?? text;
  const isHtml = elementAttr['data-html'] !== undefined;

  return (
    <>
      <ContentEditable
        {...containerProps}
        html={html}
        tagName={tagName}
        onBlur={(event: any) => {
          if (!event.target) return;
          let value = event.target.innerHTML
            // Remove scripts
            .replaceAll(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
            .replaceAll('&nbsp;', ' ')
            .replaceAll('&amp;', ' ')
            .replaceAll(/\s+/g, ' ')
            .trim();
          if (!isHtml) {
            value = value.replace(/(<([^>]+)>)/gi, '');
          }
          onChange({ key: dataKey, value });
        }}
      />
    </>
  );
};
const EditableProducts = ({ elementAttr, onChange, className }: any) => {
  const page = useAtomValue(pageAtom);
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);
  const [, updateMeta] = useAtom(updateMetaAtom);

  const onAdd = (incomingProduct: IProduct[]) => {
    onChange({ key: 'products', value: incomingProduct });
  };

  const onEdit = (item) => {
    if (item && !!page.data && !!page.data.meta) {
      let newList = [...page.data.meta.products];
      const index = newList.findIndex((v: any) => v.product_id === item.product_id);
      newList[index] = item;
      onChange({ key: 'products', value: newList });
    }
    setPendingAction(null);
  };

  const onDelete = (itemDeleted: any) => {
    if (itemDeleted && !!page.data && !!page.data.meta) {
      let productFiltered = page.data.meta.products.filter((v) => v.product_id !== itemDeleted.product_id);
      onChange({ key: 'products', value: productFiltered });
    }
    setPendingAction(null);
  };

  return (
    <>
      <div className="product-main">
        {!!page.data && !!page.data.meta && !!page.data.meta.products && !!page.data.meta.products.length ? (
          page.data.meta.products.map((product) => {
            return (
              <div
                key={product.product_id}
                data-product-id={product.product_id}
                className="kform_productBox buyopt packageclassName cb-package-container package-container variant-silver-standard2"
                onClick={() => {
                  setPendingAction({ item: product, type: ActionType.Edit });
                }}
              >
                <ProductElement product={product} pageData={page.data} />
              </div>
            );
          })
        ) : (
          <p style={{ textAlign: 'center' }}>No Products added</p>
        )}
      </div>
      <PlusCircleOutlined
        onClick={() => setPendingAction({ item: null, type: ActionType.Add })}
        className={`text-center addicon ${className}`}
      />
      {pendingAction?.item && (
        <EditModalIndex
          onFinished={(item) => onEdit(item)}
          localmeta={!!localStorage.getItem('features') ? JSON.parse(localStorage.getItem('features') as any) : []}
          pendingProduct={pendingAction?.item}
          active={pendingAction?.type === ActionType.Edit}
          pageData={page.data}
          onDelete={(itemDeleted: any) => onDelete(itemDeleted)}
          overridePrice={
            !!page.data &&
            !!page.data.meta &&
            !!page.data.meta.product_override_price &&
            page.data.meta.product_override_price
          }
        />
      )}
      <Modal
        open={pendingAction?.type === ActionType.Add}
        title="Add Products"
        onOk={() => setPendingAction(null)}
        onCancel={() => setPendingAction(null)}
      >
        <AddModal pageData={page.data} onAdd={(product: any) => onAdd(product)} />
        <p className="alreadyAdd">Already Added:</p>
        {!!page.data && !!page.data.meta && !!page.data.meta.products && !!page.data.meta.products.length ? (
          <>
            <DragAndDrop
              listChange={(item) => updateMeta(item)}
              meta={!!page.data && !!page.data.meta && !!page.data.meta.products && page.data.meta.products}
              type="products"
            />
          </>
        ) : (
          <p className="noAdded">No added Products</p>
        )}
      </Modal>
    </>
  );
};

const EditableAddons = ({ elementAttr, onChange, className }: any) => {
  const page = useAtomValue(pageAtom);
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);
  const [show, setShow] = useState(false);
  const [frames, setFrames] = useState(
    htmlPartals
      .filter((partal) => partal.section === 'checkout')
      .map((partal) => (partal.type === 'normal' ? { ...partal, hidden: false } : { ...partal, hidden: true })) ?? null,
  );

  useEffect(() => {
    setShow(
      !!page.data &&
        !!page.data.meta &&
        !!page.data.meta.productAddonsActive &&
        page.data.meta.productAddonsActive === 'true'
        ? true
        : false,
    );
  }, [page.data]);

  const onFrameChange = (img) => {
    let cloneState = [...frames];
    let newState = cloneState.map((clone) =>
      clone.frame === img.frame ? { ...clone, checked: true } : { ...clone, checked: false },
    );
    setFrames(newState);
  };

  const onAddProductHandler = (incomingProduct) => {
    let data = !!page.data?.meta && !!page.data.meta.productAddons ? page.data.meta.productAddons : [];
    if (!!incomingProduct) {
      onChange({
        key: 'productAddons',
        value: [...data, incomingProduct],
      });
    }
    setFrames(
      frames.map((v) => {
        return { ...v, checked: false };
      }),
    );
    setPendingAction(null);
  };

  const onEditProductHandler = (item: any) => {
    let newList = [...page.data?.meta.productAddons];
    const index = newList.findIndex((v: any, i) => v.id === item.id);
    newList[index] = item;
    onChange({ key: 'productAddons', value: newList });
    setPendingAction(null);
  };

  const onDeleteAddonHandler = (itemDeleted: any) => {
    if (itemDeleted) {
      let productFiltered = page.data?.meta.productAddons.filter((v) => v.id !== itemDeleted.id);
      onChange({ key: 'productAddons', value: productFiltered });
    }
    setPendingAction(null);
  };

  const onDeleteProductHelperFunc = (id: any, setState: any) => {
    setState((prev: any) => prev.filter((prod: any) => prod.id !== id));
  };

  const onAddonTypeChange = (type) => {
    let cloneState = [...frames];
    let newState = cloneState.map((clone) =>
      clone.type === type ? { ...clone, hidden: false } : { ...clone, hidden: true },
    );
    setFrames(newState);
  };

  return (
    <div hidden={!show}>
      {!!page.data && !!page.data.meta && !!page.data.meta.productAddons && !!page.data.meta.productAddons.length ? (
        page.data.meta.productAddons.map((addon, index) => {
          if (addon.type !== 'journey' && className !== 'journey_addon') {
            return (
              <div
                key={index}
                onClick={() => setPendingAction({ type: ActionType.Edit, item: addon })}
                className="addonEl"
              >
                <AddonElement addon={addon} />
              </div>
            );
          } else if (addon.type === 'journey' && className === 'journey_addon') {
            return (
              <div
                key={index}
                onClick={() => setPendingAction({ type: ActionType.Edit, item: addon })}
                className="journey_protection"
              >
                <JourneyElement addon={addon} />
              </div>
            );
          }
          return null;
        })
      ) : (
        <p hidden={!show} style={{ margin: 10, textAlign: 'center' }}>
          No extra products added
        </p>
      )}
      <PlusCircleOutlined
        hidden={!show}
        onClick={() => setPendingAction({ item: null, type: ActionType.Add })}
        className={`text-center addicon ${className}`}
      />
      <AddAddons
        onAdd={(productsAddon: any) => onAddProductHandler(productsAddon)}
        active={pendingAction?.type === ActionType.Add}
        onCancel={() => setPendingAction(null)}
        onDeleteProduct={onDeleteProductHelperFunc}
        onFrameChange={(img) => onFrameChange(img)}
        frames={frames}
        pageData={page.data}
        onAddonTypeChange={(type) => onAddonTypeChange(type)}
      />
      {pendingAction?.item && (
        <EditModalAddons
          onEdit={(productsAddon: any) => onEditProductHandler(productsAddon)}
          active={pendingAction?.type === ActionType.Edit}
          pendingItem={pendingAction?.item}
          onCancel={() => setPendingAction(null)}
          onDeleteProduct={onDeleteProductHelperFunc}
          onFrameChange={(img) => onFrameChange(img)}
          frames={frames}
          onDeleteAddon={(addon: any) => onDeleteAddonHandler(addon)}
        />
      )}
    </div>
  );
};

const EditableLists = ({ elementAttr, onChange, className }: any) => {
  const page = useAtomValue(pageAtom);
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);

  const onAdd = (incomingList: any) => {
    let data = !!page.data?.meta && !!page.data.meta.list ? page.data.meta.list : [];
    let d = [...data, incomingList];
    onChange({ key: 'list', value: d });
  };
  const onEdit = (item) => {
    let newList = [...(!!page.data?.meta && !!page.data.meta.list && page.data.meta.list)];
    const index = newList.findIndex((v: any) => v.id === item.id);
    newList[index] = item;
    onChange({ key: 'list', value: newList });
    setPendingAction(null);
  };
  const onDelete = (itemDeleted: any) => {
    if (itemDeleted) {
      let listsFiltered = page.data?.meta.list.filter((v) => v.id !== itemDeleted.id);
      onChange({ key: 'list', value: listsFiltered });
    }
    setPendingAction(null);
  };

  return (
    <ul className={`ups1-list s1-list`}>
      {!!page.data && !!page.data.meta && !!page.data.meta.list && !!page.data.meta.list.length ? (
        page.data.meta.list.map((lis) => {
          return (
            <li
              key={lis.id}
              onClick={() => {
                setPendingAction({ item: lis, type: ActionType.Edit });
              }}
            >
              <ListElement list={lis} />
            </li>
          );
        })
      ) : (
        <p style={{ marginRight: 5 }}>No list added</p>
      )}
      <PlusCircleOutlined
        onClick={() => setPendingAction({ item: null, type: ActionType.Add })}
        className={`text-center addListIcon`}
      />
      <ListAddForm
        onCancel={() => setPendingAction(null)}
        onOk={() => setPendingAction(null)}
        active={pendingAction?.type === ActionType.Add}
        pageData={!!page && !!page.data && page.data}
        onAdd={(list: any) => onAdd(list)}
      />
      <ListEditForm
        active={pendingAction?.type === ActionType.Edit}
        onEdit={(item: any) => onEdit(item)}
        onCancel={() => setPendingAction(null)}
        item={pendingAction?.item}
        pageData={!!page && !!page.data && page.data}
        onDeleteItem={(list: any) => onDelete(list)}
      />
    </ul>
  );
};

const EditableSizes = ({ elementAttr, onChange, className }: any) => {
  const page = useAtomValue(pageAtom);
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(
      !!page.data &&
        !!page.data.meta &&
        !!page.data.meta.productSizeActive &&
        page.data.meta.productSizeActive === 'true'
        ? true
        : false,
    );
  }, [page.data]);

  const onAdd = (incomingSize) => {
    let data = !!page.data?.meta && !!page.data.meta.productSizes ? page.data.meta.productSizes : [];
    let d = [...data, incomingSize];
    onChange({ key: 'productSizes', value: d });
  };

  return (
    <div hidden={!show}>
      <p className="pkg-hdng">Choose your size</p>
      <div className="colourSection">
        <ul>
          {!!page.data && !!page.data.meta && !!page.data.meta.productSizes && !!page.data.meta.productSizes.length ? (
            page.data.meta.productSizes.map((size, index) => {
              return (
                <li key={index}>
                  <label htmlFor={size}>
                    <div>
                      <small>{size}</small>
                    </div>
                    <input type="radio" className="productsL" id={size} value={size} name="sizes" /> <span></span>{' '}
                    <span></span>
                  </label>
                </li>
              );
            })
          ) : (
            <p style={{ marginRight: 5 }}>No sizes added</p>
          )}
          <PlusCircleOutlined
            onClick={() => setPendingAction({ item: null, type: ActionType.Add })}
            className={`text-center addListIcon`}
          />
          <AddSize
            active={pendingAction?.type === ActionType.Add}
            onAdd={(size: any) => onAdd(size)}
            onCancel={() => setPendingAction(null)}
            onOk={() => setPendingAction(null)}
            pageData={page.data}
          />
        </ul>
      </div>
    </div>
  );
};

const EditableImage = ({ funnelId, dataKey, elementAttr, onChange, className }: EditableImageProps) => {
  const page = useAtomValue(pageAtom);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const imgUrl =
    !!page.data?.meta && page.data?.meta[dataKey]
      ? `${window.location.origin}${page.data?.meta[dataKey]}`
      : elementAttr.src ?? elementAttr['data-src'];

  useEffect(() => {
    !!page.data && !!page.data.meta && setSelected(page.data.meta[dataKey]);
  }, [page.data, dataKey]);

  return (
    <>
      <img
        className={`${elementAttr.class.replace('lazyload', '').replace('blur-up', '')}`}
        alt={elementAttr.alt}
        src={imgUrl}
        onClick={() => setOpen(true)}
      />
      <>
        {page.data && (
          <Modal
            open={open}
            title="Select image from gallery"
            onOk={() => {
              onChange({ key: dataKey, value: selected });
              setOpen(false);
            }}
            onCancel={() => {
              setSelected(page.data?.meta[dataKey]);
              setOpen(false);
            }}
          >
            <ImageGallery funnelId={funnelId} value={selected} onSelected={setSelected} />
          </Modal>
        )}
      </>
    </>
  );
};

const EditableWarranty = ({ elementAttr, onChange, className }: any) => {
  const [, updateMeta] = useAtom(updateMetaAtom);
  const page = useAtomValue(pageAtom);
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);
  let metaFrame = !!page.data && !!page.data.meta && !!page.data.meta.warrantyFrame && page.data.meta.warrantyFrame;

  const [frames, setFrames] = useState(
    htmlPartals
      .filter((partal) => partal.section === 'warranty')
      .map((partal) =>
        !!metaFrame && metaFrame === partal.frame ? { ...partal, checked: true } : { ...partal, checked: false },
      ),
  );

  const onFrameChange = (img) => {
    let cloneState = [...frames];
    let newState = cloneState.map((clone) =>
      clone.frame === img.frame ? { ...clone, checked: true } : { ...clone, checked: false },
    );
    setFrames(newState);
  };

  const onAddWarrantyHandler = (incomingWarranty: any) => {
    if (!!incomingWarranty) {
      updateMeta(incomingWarranty);
    }
    setPendingAction(null);
  };

  return (
    <>
      <div
        onClick={() =>
          setPendingAction({
            type: ActionType.Add,
            item: null,
          })
        }
        className="selectr-grpBox warrantyClass"
      >
        <WarrantyElement meta={!!page.data && !!page.data.meta && page.data.meta} />
      </div>
      <AddWarranty
        active={pendingAction?.type === ActionType.Add}
        onFrameChange={(img) => onFrameChange(img)}
        frames={frames}
        onCancel={() => setPendingAction(null)}
        onAdd={(war: any) => onAddWarrantyHandler(war)}
        meta={!!page.data && !!page.data.meta && !!page.data.meta.warranty && page.data.meta.warranty}
      />
    </>
  );
};

export const MakeEditable = ({
  element,
  funnelId,
  props,
}: {
  element: HTMLElement;
  funnelId: number;
  props: any;
}): HTMLElement => {
  const elementAttr = element.getAttributeNames().reduce((acc, name) => {
    return { ...acc, [name]: element.getAttribute(name) };
  }, {});
  var editableElement;
  const tagName = element.tagName.toLowerCase();
  const classEl = element.className;
  const elId = element.id;

  if (tagName === 'img') {
    editableElement = (
      <Provider store={defaultStore}>
        <EditableImage {...props} funnelId={funnelId} elementAttr={elementAttr} />
      </Provider>
    );
  } else if (elId === 'checkout-product-container') {
    editableElement = (
      <Provider store={defaultStore}>
        <EditableProducts {...props} funnelId={funnelId} elementAttr={elementAttr} />
      </Provider>
    );
  } else if (classEl.includes('s1-list')) {
    editableElement = (
      <Provider store={defaultStore}>
        <EditableLists {...props} funnelId={funnelId} elementAttr={elementAttr} />
      </Provider>
    );
  } else if (classEl.includes('sizeSelection')) {
    editableElement = (
      <Provider store={defaultStore}>
        <EditableSizes {...props} funnelId={funnelId} elementAttr={elementAttr} />
      </Provider>
    );
  } else if (classEl.includes('addon-container') || classEl.includes('journey_protection')) {
    editableElement = (
      <Provider store={defaultStore}>
        <EditableAddons {...props} funnelId={funnelId} elementAttr={elementAttr} />
      </Provider>
    );
  } else if (classEl.includes('warrantyClass')) {
    editableElement = (
      <Provider store={defaultStore}>
        <EditableWarranty {...props} funnelId={funnelId} elementAttr={elementAttr} />
      </Provider>
    );
  } else {
    editableElement = (
      <Provider store={defaultStore}>
        <EditableText {...props} funnelId={funnelId} elementAttr={elementAttr} tagName={tagName} />
      </Provider>
    );
  }
  const container = element.ownerDocument.createElement('editable');
  const root = createRoot(container);
  root.render(editableElement);
  element.replaceWith(container);
  return container;
};
