import { atom } from 'jotai';

import { IPixel } from '../types';
import { IPixelPayload } from '../types';
import { $http } from '../utils/http';

const dataAtom = atom<IPixel[]>([]);
const isLoading = atom<boolean>(false);

export interface PixelState {
  data: IPixel[];
  isLoading: boolean;
}

export const pixelsAtom = atom<PixelState>((get) => ({
  data: get(dataAtom),
  isLoading: get(isLoading),
}));

export const fetchPixelsAtom = atom(
  (get) => get(pixelsAtom),
  async (_, set, funnelId?: number) => {
    set(isLoading, true);
    $http
      .get(`/api/v1/funnels/${funnelId}/pixels`)
      .json<IPixel[]>()
      .then((response) => set(dataAtom, response))
      .finally(() => set(isLoading, false));
  },
);

export const createPixelAtom = atom(null, async (get, set, body?: IPixelPayload): Promise<any> => {
  if (!body) return Promise.reject('No body provided');
  let pixels: IPixel[] = [];
  if (!!body?.pageIds && body?.pageIds.length) {
    const { pageIds, ...rest } = body;
    pixels = body?.pageIds.reduce((acc, pageId) => {
      acc.push({ ...rest, page_id: pageId });
      return acc;
    }, pixels);
  } else {
    pixels.push(body);
  }
  const promises = pixels.map((body) => {
    return $http.post(`/api/v1/pixels`, { body }).json<IPixel>();
  });
  return Promise.all(promises).then((response) => set(dataAtom, [...get(dataAtom), ...response]));
});

export const updatePixelAtom = atom(null, async (get, set, id: number, body: Partial<IPixel>) => {
  if (!id) return;
  const pixels = get(pixelsAtom);
  set(isLoading, true);
  $http
    .put(`/api/v1/pixels/${id}`, { body })
    .json<IPixel>()
    .then((pixel) => set(dataAtom, pixels.data.updateValueBy('ID', pixel)))
    .finally(() => set(isLoading, false));
});

export const deletePixelAtom = atom(null, async (get, set, id?: number) => {
  if (!id) return;
  set(isLoading, true);
  $http
    .delete(`/api/v1/pixels/${id}`)
    .json<IPixel>()
    .then(() =>
      set(
        dataAtom,
        get(dataAtom).filter(({ ID }) => ID !== id),
      ),
    )
    .finally(() => set(isLoading, false));
});
