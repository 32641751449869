import { PlusCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { useState } from 'react';

import { htmlPartals } from '../HelperFunctions';
import { AddAddons } from './AddAddons';
import { EditModalAddons } from './EditModalAddons';

enum ActionType {
  Add,
  Delete,
  Edit,
}

interface PendingAction {
  type: ActionType;
  item?: any;
}
interface ParentProps {
  pageData: any;
  listChange: any;
  iframeDocument: any;
  pendingSaveData: any;
  childern?: any;
}
export interface ParentAddonsProps {
  onEdit: Function;
  active: any;
  onCancel: any;
  onDeleteProduct: any;
  pendingItem: any;
  onFrameChange?: any;
  frames?: any;
  onDeleteAddon?: any;
}
export interface AddAddonsProps {
  onAdd: Function;
  pageData?: any;
  active: any;
  onCancel: any;
  onDeleteProduct: any;
  onFrameChange?: any;
  frames?: any;
  onAddonTypeChange: Function;
}
export interface IAddon {
  id: string;
  data_name: string;
  data_price: string;
  data_org: string;
}
export enum ClassPrice {
  each = 'remote-each-price',
  full = 'remote-full-price',
}

export const ProductAddons = ({ pageData, listChange, iframeDocument, pendingSaveData, childern }: ParentProps) => {
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);
  const [frames, setFrames] = useState(
    htmlPartals
      .filter((partal) => partal.section === 'checkout')
      .map((partal) => (partal.type === 'normal' ? { ...partal, hidden: false } : { ...partal, hidden: true })),
  );

  const onFrameChange = (img) => {
    let cloneState = [...frames];
    let newState = cloneState.map((clone) =>
      clone.frame === img.frame ? { ...clone, checked: true } : { ...clone, checked: false },
    );
    setFrames(newState);
  };

  const onAddProductHandler = (incomingProduct) => {
    let data = !!pageData.meta && !!pageData.meta.productAddons ? pageData.meta.productAddons : [];
    if (!!incomingProduct) {
      listChange({
        productAddons: [...data, incomingProduct],
      });
    }
    setFrames(
      frames.map((v) => {
        return { ...v, checked: false };
      }),
    );
    setPendingAction(null);
  };

  const onEditProductHandler = (item: any) => {
    if (item && !!pendingSaveData && !!pendingSaveData.meta) {
      let newList = [...pendingSaveData.meta.productAddons];
      const index = newList.findIndex((v: any, i) => v.id === item.id);
      newList[index] = item;
      listChange({ productAddons: newList });
    }
    setPendingAction(null);
  };

  const onDeleteProductHandler = (itemDeleted: any) => {
    if (itemDeleted && !!pendingSaveData && !!pendingSaveData.meta) {
      let productFiltered = pendingSaveData.meta.productAddons.filter((v) => v.id !== itemDeleted.id);
      listChange({ productAddons: productFiltered });
    }
    setPendingAction(null);
  };

  const onDeleteProductHelperFunc = (id: any, setState: any) => {
    setState((prev: any) => prev.filter((prod: any) => prod.id !== id));
  };

  const onAddonTypeChange = (type) => {
    let cloneState = [...frames];
    let newState = cloneState.map((clone) =>
      clone.type === type ? { ...clone, hidden: false } : { ...clone, hidden: true },
    );
    setFrames(newState);
  };

  return (
    <>
      <p className="info_text">Extra Products:</p>
      {!!pendingSaveData &&
      !!pendingSaveData.meta &&
      !!pendingSaveData.meta.productAddonsActive &&
      pendingSaveData.meta.productAddonsActive === 'true' ? (
        <>
          {!!pendingSaveData &&
          !!pendingSaveData.meta &&
          !!pendingSaveData.meta.productAddons &&
          !!pendingSaveData.meta.productAddons.length ? (
            pendingSaveData.meta.productAddons.map((addon, index) => {
              return (
                <Card
                  onClick={() => setPendingAction({ type: ActionType.Edit, item: addon })}
                  className="card-products-addons"
                  size="small"
                  key={addon.id}
                >
                  <div>"{addon.name}"</div>
                  {!!addon.products.length &&
                    addon.products.map((product) => <div key={product.id}>ID: {product.id}</div>)}
                </Card>
              );
            })
          ) : (
            <p style={{ margin: 10, textAlign: 'center' }}>No extra products added</p>
          )}
          <PlusCircleOutlined
            onClick={() => setPendingAction({ item: null, type: ActionType.Add })}
            className={`text-center addicon`}
          />
          {pendingAction?.type === ActionType.Add && (
            <AddAddons
              onAdd={(productsAddon: any) => onAddProductHandler(productsAddon)}
              active={pendingAction?.type === ActionType.Add}
              onCancel={() => setPendingAction(null)}
              onDeleteProduct={onDeleteProductHelperFunc}
              onFrameChange={(img) => onFrameChange(img)}
              frames={frames}
              pageData={pageData}
              onAddonTypeChange={(type) => onAddonTypeChange(type)}
            />
          )}

          {pendingAction?.item && (
            <EditModalAddons
              onEdit={(productsAddon: any) => onEditProductHandler(productsAddon)}
              active={pendingAction?.type === ActionType.Edit}
              pendingItem={pendingAction?.item}
              onCancel={() => setPendingAction(null)}
              onDeleteProduct={onDeleteProductHelperFunc}
              onFrameChange={(img) => onFrameChange(img)}
              frames={frames}
              onDeleteAddon={(addon: any) => onDeleteProductHandler(addon)}
            />
          )}
        </>
      ) : (
        <p style={{ margin: 0 }}>Enable Extra Products in "Extras" tab</p>
      )}
    </>
  );
};
