import { Form, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useState } from 'react';

export const UnitPack = ({ pendingProduct, onChange }: any) => {
  const [form] = Form.useForm();
  const [product_data_package_name, setunit_pack] = useState(pendingProduct.product_data_package_name);

  const onDiscountChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setunit_pack(e.target.value);
    onChange({ product_data_package_name: e.target.value });
  };

  return (
    <div>
      <Form form={form} layout="vertical">
        <div className="productRow">
          <FormItem
            style={{ width: '100%' }}
            initialValue={product_data_package_name}
            name="product_data_package_name"
            label="Package Name"
          >
            <Input onChange={onDiscountChangeHandler} value={product_data_package_name} />
          </FormItem>
        </div>
      </Form>
    </div>
  );
};
